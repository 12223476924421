import React from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { generateKey } from "./functions";
import { formatTime } from "../../../utils/dateTime";
import {
  intervalSize,
  sourceMASO,
  sourcePIZZA,
  sourceROZVOZ,
} from "../../DateTimePicker/logicConstants";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_TYPE_CHANGE } from "./reducers/capacityReducer";
import { Directions, DriveEta, Fastfood, LocalPizza } from "@material-ui/icons";

const CapacityHeaderItem = (props) => {
  const { source, hours, minutes } = props;

  const capacityState = useSelector((state) => state.capacity);
  const dispatch = useDispatch();

  const dayOfWeek = capacityState.dayOfWeek;

  const key = generateKey(hours, minutes);
  const checked = capacityState.checked.includes(key);

  const getLabel = () => {
    if (source === null && hours === null && minutes === null) {
      return "Vybrat vše";
    }
    if (source) {
      return source;
    }
    return formatTime(hours, minutes, true, intervalSize);
  };

  const getIcon = () => {
    if (source === sourcePIZZA) {
      return <LocalPizza />;
    }
    if (source === sourceMASO) {
      return <Fastfood />;
    }
    if (source === sourceROZVOZ) {
      return <DriveEta />;
    }
    return <></>;
  };

  const handleClick = () => {
    let affectedKeys = [key]; //click on HOUR_MINUTE

    if (
      source === null &&
      dayOfWeek !== null &&
      hours === null &&
      minutes === null
    ) {
      //click on SELECT ALL
      for (let hours of capacityState.config.hours) {
        for (let minutes of capacityState.config.minutes) {
          affectedKeys.push(generateKey(hours, minutes));
        }
      }
    }

    let copy = [...capacityState.checked];
    if (checked) {
      copy = copy
        .filter((c) => !affectedKeys.includes(c))
        .filter((v, i, a) => a.indexOf(v) === i); //zajistí unikátnost v poli
    } else {
      copy = copy.concat(affectedKeys).filter((v, i, a) => a.indexOf(v) === i); //přidá nové položky a zajistí unikátnost
    }
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "checked",
        value: copy,
      },
    });
  };

  if (!!source) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        {getIcon()}&nbsp;{getLabel()}
      </Box>
    );
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          color={"primary"}
          checked={checked}
          name={key}
          onChange={handleClick}
        />
      }
      label={<strong>{getLabel()}</strong>}
    />
  );
};

export default CapacityHeaderItem;

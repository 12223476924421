import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import PlaceAutoComplete from "../PlaceAutoComplete/PlaceAutoComplete";
import React from "react";
import { colors } from "../../theme/theme";
import { setPointOfSale } from "../../actions/cartActions";
import { addressXNA } from "../../constants/constants";
import { formatPrice } from "../../utils/price";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  checkDelivery,
  getClosedDayForPointOfSaleAndDate,
  servicePrice,
} from "../../utils/delivery";
import { timeToHHMM } from "../../utils/dateTime";
import { grey } from "@material-ui/core/colors";
import {
  meatSectionProductionInfo_isVisibleForPosId,
  meatSectionProductionInfo_text2,
} from "../MeatSectionProductionInfo/MeatSectionProductionInfo";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionButton: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  text: {
    paddingTop: theme.spacing(2),
  },
  list: {
    backgroundColor: colors.WHITE.main,
    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.14)",
  },
  item: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    boxSizing: "border-box",
    textAlign: "center",
    cursor: "pointer",
    borderBottomStyle: "solid",
    borderBottomWidth: 4,
    borderBottomColor: colors.WHITE.main,
    "&:hover": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "&.active": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "& > div": {
      display: "block",
      "& > img": {
        height: 48,
      },
    },
  },
  itemLb: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxSizing: "border-box",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    background: grey[200],
    color: grey[800],
    "&:hover": {
      boxShadow: theme.shadows[3],
      color: colors.RED.text,
      background: colors.RED.main,
    },
    "&.active": {
      boxShadow: theme.shadows[3],
      color: colors.RED.text,
      background: colors.RED.main,
    },
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
  deliveryInfo: {
    "& .zone": {
      marginBottom: theme.spacing(3),
    },
    "& .title": {
      marginBottom: theme.spacing(0.5),
      fontSize: "100%",
      fontWeight: 600,
    },
    "& .info": {
      marginBottom: theme.spacing(0.5),
      fontSize: "90%",
      fontWeight: 300,
    },
    "& .towns": {
      fontSize: "85%",
      fontWeight: 300,
    },
  },
  deliveryInfoButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    fontSize: "85%",
    fontWeight: 300,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const localBusiness = (settings, pos) => {
  return settings.localBusinesses.find(
    (lb) => lb.title === pos.localBusinessTitle
  );
};

const isFree = (pos) => {
  for (let i = 0; i <= 23; i++) {
    if (pos["servicePrice_" + i] * 1 === 0) {
      return true;
    }
  }
  return false;
};

const DeliveryWindowTextInfo = (props) => {
  const { settings, deliveryType, pos, times } = props;

  const classes = useStyles();

  if (times[0].type === "closed_all_day") {
    return <></>;
  }

  if (deliveryType.isDelivery && pos) {
    return (
      <Typography variant="body2" className={classes.text}>
        Na tuto adresu rozvážíme. Minimální hodnota objednávky je{" "}
        <strong>{formatPrice(pos.minimalPrice)}</strong> s cenou doručení{" "}
        <strong>{formatPrice(servicePrice(pos))}</strong>.{" "}
        {isFree(pos) && (
          <>
            Ve vybraných časech (mimo špičku) je doručení{" "}
            <strong>zdarma</strong>.
          </>
        )}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" className={classes.text}>
      {localBusiness(settings, pos).textPlace}
    </Typography>
  );
};

const DeliveryWindowTextOpeningHours = (props) => {
  const { settings, deliveryType, pos, times } = props;

  const classes = useStyles();

  if (times[0].type === "closed_all_day") {
    return (
      <Typography variant="body2" className={classes.text}>
        <strong>{times[0].text}</strong>
      </Typography>
    );
  }

  if (deliveryType.isDelivery && pos) {
    return (
      <Typography variant="body2" className={classes.text}>
        Dnes rozvážíme od{" "}
        <strong>{timeToHHMM(times[0].timeFrom.h, times[0].timeFrom.m)}</strong>{" "}
        do <strong>{timeToHHMM(times[0].timeTo.h, times[0].timeTo.m)}</strong> a
        zítra{" "}
        {times[1].type === "closed_all_day" ? (
          <>
            je <strong>zavřeno</strong> celý den
          </>
        ) : (
          <>
            od{" "}
            <strong>
              {timeToHHMM(times[1].timeFrom.h, times[1].timeFrom.m)}
            </strong>{" "}
            do{" "}
            <strong>{timeToHHMM(times[1].timeTo.h, times[1].timeTo.m)}</strong>
          </>
        )}
        .
      </Typography>
    );
  }

  return (
    <Typography variant="body2" className={classes.text}>
      Dnes máme otevřeno od{" "}
      <strong>{timeToHHMM(times[0].timeFrom.h, times[0].timeFrom.m)}</strong> do{" "}
      <strong>{timeToHHMM(times[0].timeTo.h, times[0].timeTo.m)}</strong> a
      zítra{" "}
      {times[1].type === "closed_all_day" ? (
        <>
          máme <strong>zavřeno</strong> celý den
        </>
      ) : (
        <>
          od{" "}
          <strong>
            {timeToHHMM(times[1].timeFrom.h, times[1].timeFrom.m)}
          </strong>{" "}
          do <strong>{timeToHHMM(times[1].timeTo.h, times[1].timeTo.m)}</strong>
        </>
      )}
      .
    </Typography>
  );
};

export const DeliveryWindowText = (props) => {
  const { settings, deliveryType, pos, showInfo } = props;

  const classes = useStyles();

  const getTime = (addDays) => {
    let d = new Date();
    d.setDate(d.getDate() + addDays);
    let i = d.getDay();
    i = i === 0 ? 7 : i;

    let [timeFrom, timeTo] = pos["hours_" + i].split("-").map((t) => {
      const [hour, min] = t.trim().split(":");
      return {
        h: hour.trim() * 1,
        m: min.trim() * 1,
        hm: hour.trim() * 60 + min.trim() * 1,
      };
    });

    const closedDay = getClosedDayForPointOfSaleAndDate(settings, pos.id, d);

    if (closedDay) {
      //je zavřeno po celý den
      if (
        timeFrom.hm >= closedDay.timeFrom.hm &&
        timeTo.hm <= closedDay.timeTo.hm
      ) {
        return {
          type: "closed_all_day",
          text: closedDay.description,
        };
      }

      //je otevřeno částečně
      const x =
        timeFrom.hm > closedDay.timeFrom.hm && timeFrom.hm < closedDay.timeTo.hm
          ? closedDay.timeTo.hm
          : timeFrom.hm;
      const y =
        timeTo.hm > closedDay.timeFrom.hm && timeTo.hm < closedDay.timeTo.hm
          ? closedDay.timeFrom.hm
          : timeTo.hm;
      return {
        type: "open_part_day",
        timeFrom: {
          hm: x,
          h: Math.floor(x / 60),
          m: x - Math.floor(x / 60) * 60,
        },
        timeTo: {
          hm: y,
          h: Math.floor(y / 60),
          m: y - Math.floor(y / 60) * 60,
        },
      };
    }

    //je otevřeno celý den
    return {
      type: "open_all_day",
      timeFrom,
      timeTo,
    };
  };

  if (deliveryType.isDelivery && !pos) {
    return (
      <Typography variant="body2" className={classes.text}>
        Na tuto adresu bohužel nerozvážíme.
      </Typography>
    );
  }

  const times = [getTime(0), getTime(1)];

  return (
    <>
      {showInfo && (
        <DeliveryWindowTextInfo
          deliveryType={deliveryType}
          pos={pos}
          settings={settings}
          times={times}
        />
      )}
      <DeliveryWindowTextOpeningHours
        deliveryType={deliveryType}
        pos={pos}
        settings={settings}
        times={times}
      />
      {meatSectionProductionInfo_isVisibleForPosId(pos.id) && (
        <Typography variant="body1" className={classes.text}>
          {meatSectionProductionInfo_text2()}
        </Typography>
      )}
    </>
  );
};

const DeliveryInfo = (props) => {
  const { settings } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.deliveryInfoButton} onClick={handleOpen}>
        Kde rozvážíme?
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box style={{ display: "flex" }}>
            <Box style={{ flexGrow: 1 }}>Kde rozvážíme?</Box>
            <Box>
              <IconButton onClick={handleClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.deliveryInfo}>
            {settings.deliveryTypes
              .filter((dt) => {
                return dt.isDelivery;
              })
              .map((dt) => {
                return settings.pointOfSales
                  .filter((pos) => pos.deliveryTypeTitle === dt.title)
                  .map((pos) => {
                    return (
                      <div className={"zone"}>
                        <div className={"title"}>{pos.zoneTitle}</div>
                        <div className={"info"}>
                          Minimální hodnota objednávky je{" "}
                          <strong>{formatPrice(pos.minimalPrice)}</strong>. Cena
                          doručení je{" "}
                          <strong>{formatPrice(servicePrice(pos))}</strong>.
                          {isFree(pos) && (
                            <>
                              Ve vybraných časech (mimo špičku) je doručení{" "}
                              <strong>zdarma</strong>.
                            </>
                          )}
                        </div>
                        <div className={"towns"}>
                          {settings.deliveryZonesTowns
                            .filter((dzt) => dzt.zoneTitle === pos.zoneTitle)
                            .filter(
                              (dzt) =>
                                !(
                                  dzt.zoneTitle === "Vyškov: III. zóna" &&
                                  dzt.title === "Vyškov"
                                )
                            )
                            .map((dzt) => {
                              return dzt.title;
                            })
                            .join(", ")}
                        </div>
                      </div>
                    );
                  });
              })}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

const VyskovClosureInfo = (props) => {
  const { address } = props;

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (
      address.includes("Vyškov") &&
      ["Sochorova", "Krátká", "Průmyslová", "Tovární", "Pustiměřská"].some(
        (street) => address.includes(street)
      )
    ) {
      setOpen(true);
    }
  }, [address]);

  return (
    <Dialog
      open={open && !!address}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogContent>
        Kvůli uzavření části silnice v ulici Olomoucká jsme byli nuceni Vaši
        adresu <strong>{address}</strong> přesunout dočasně do{" "}
        <strong>3.&nbsp;rozvozové&nbsp;zóny</strong>. <br />
        <br /> Děkujeme za pochopení.
      </DialogContent>
      <DialogActions>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            setOpen(false);
          }}
        >
          Rozumím
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Delivery = (props) => {
  const { settings, onAcceptanceDelivery } = props;

  const classes = useStyles();

  const cart = useSelector((state) => state.cart);
  const pointOfSale = useSelector((state) =>
    state.settings.pointOfSales.find(
      (pos) => pos.id === state.cart.pointOfSaleId
    )
  );
  const dispatch = useDispatch();

  const [deliveryType, setDeliveryType] = React.useState(
    pointOfSale
      ? settings.deliveryTypes.find(
          (dt) => dt.title === pointOfSale.deliveryTypeTitle
        )
      : settings.deliveryTypes[0]
  );
  const [localBusiness, setLocalBusiness] = React.useState(
    pointOfSale
      ? settings.localBusinesses.find(
          (lb) => lb.title === pointOfSale.localBusinessTitle
        )
      : null
  );
  const [address, setAddress] = React.useState(addressXNA);
  const [showText, setShowText] = React.useState(false);
  const [pos, setPos] = React.useState(null);

  const changeDeliveryType = (deliveryType) => {
    setDeliveryType(deliveryType);
    setLocalBusiness(null);
  };

  const changeLocalBusiness = (localBusiness) => {
    setLocalBusiness(localBusiness);
  };

  const handleChangeStatus = (status) => {
    setShowText(false);
    if (deliveryType.isDelivery && status.type === "valid") {
      const pos = checkDelivery(status.address, settings);
      if (pos) {
        setAddress(status.address);
        setShowText(true);
      } else {
        //na adresu nevozíme
        setAddress(addressXNA);
        setShowText(true);
      }
      setPos(pos);
    } else {
      //adresa nemá správný formát
      setAddress(addressXNA); //adresa není úplná
      setShowText(false);
    }
  };

  const submit = () => {
    dispatch(setPointOfSale(pos, address, servicePrice(pos)));
    onAcceptanceDelivery(deliveryType, pos);
  };

  React.useEffect(() => {
    if (!deliveryType.isDelivery && localBusiness) {
      const pos = settings.pointOfSales.find(
        (pos) =>
          pos.localBusinessTitle === localBusiness.title &&
          pos.deliveryTypeTitle === deliveryType.title
      );
      setAddress(addressXNA);
      setPos(pos);
    } else {
      setPos(null);
    }
  }, [localBusiness, deliveryType]);

  return (
    <React.Fragment>
      {/*<VyskovClosureInfo address={address} />*/}
      <Grid container className={classes.list}>
        {settings.deliveryTypes.map((dt, index) => {
          return (
            <Grid
              key={index}
              item
              xs={6}
              className={
                classes.item +
                (dt.title === deliveryType.title ? " active" : "")
              }
              onClick={() => {
                changeDeliveryType(dt);
              }}
            >
              <Box>
                <img src={`assets/deliveries/${dt.imgSrc}`} alt={dt.title} />
              </Box>
              <Box>{dt.title}</Box>
            </Grid>
          );
        })}
      </Grid>
      {deliveryType.isDelivery && (
        <Box className={classes.section}>
          <Typography className={classes.text} variant="body2">
            Zadejte adresu, kam objednávku doručíme (
            <strong>ulice, číslo popisné, město</strong>):
          </Typography>
          <Box className={classes.text}>
            <PlaceAutoComplete handleChangeStatus={handleChangeStatus} />
          </Box>
          {showText && (
            <DeliveryWindowText
              settings={settings}
              pos={pos}
              deliveryType={deliveryType}
              showInfo={true}
            />
          )}
          <DeliveryInfo settings={settings} />
        </Box>
      )}
      {!deliveryType.isDelivery && (
        <Box className={classes.section}>
          <Typography className={classes.text} variant="body2">
            Vyberte provozovnu, kde si objednávku vyzvednete:
          </Typography>
          <Box className={classes.text}>
            <Grid container spacing={2}>
              {settings.localBusinesses.map((lb, index) => {
                return (
                  <Grid key={index} item xs={6}>
                    <Box
                      className={
                        classes.itemLb +
                        (lb.title === localBusiness?.title ? " active" : "")
                      }
                      onClick={() => {
                        changeLocalBusiness(lb);
                      }}
                    >
                      {lb.title}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {pos && (
            <DeliveryWindowText
              settings={settings}
              pos={pos}
              deliveryType={deliveryType}
              showInfo={true}
            />
          )}
        </Box>
      )}
      {!!pos && (
        <Box className={classes.sectionButton}>
          <Button
            fullWidth
            size={"large"}
            variant="contained"
            color="primary"
            disabled={!pos}
            onClick={submit}
          >
            {cart.items.length > 0
              ? "Změnit způsob doručení"
              : "Začít objednávat"}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Delivery;

import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& .title": {
      marginBottom: theme.spacing(3),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
  },
  columnTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    textDecoration: "none",
  },
}));

const CheckoutDoneFailure = (props) => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container spacing={10} style={{ marginBottom: 16 }}>
        <Grid item xs={12} className={classes.pageTitle}>
          <Typography variant={"h3"} className={"title"}>
            Objednávka nebyla vytvořena!
          </Typography>
          <Typography>
            Bohužel objednávka nebyla vytvořena. Zkuste prosím objednávku
            odeslat znovu.
          </Typography>
          <Box style={{ textAlign: "center" }} mt={2}>
            <Link to={"/checkout"} className={classes.button}>
              <Button variant={"contained"} color={"primary"}>
                Zpět k pokladně
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutDoneFailure;

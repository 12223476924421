import {
  FETCH_SETTINGS_INIT,
  FETCH_SETTINGS_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  localBusinesses: [],
  deliveryTypes: [],
  deliveryZones: [],
  deliveryZonesTowns: [],
  pointOfSales: [],
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_INIT: {
      return state;
    }
    case FETCH_SETTINGS_SUCCESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default settingsReducer;

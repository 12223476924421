import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  addressXNA,
  commissionOriginCounter,
  commissionOriginTelephone,
} from "../../../constants/constants";
import {
  emptyCart,
  setPointOfSale,
  setTakeOverDateTime,
  updateCartItem,
  updateCommission,
} from "../../../actions/cartActions";
import { servicePrice } from "../../../utils/delivery";

import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import { getTotalQuantity, prepareCart } from "../../../utils/cart";
import Cart from "../../Cart/Cart";
import axios from "axios";
import { apiPostCommission } from "../../../api/endpoints";
import {
  getAllPointOfSalesOnlyDelivery,
  pointOfSaleTitleForNewCommission,
  posVyskovKiosek,
} from "../../../constants/pointOfSales";
import { contextHome } from "../../../constants/contexts";
import { csobPaymentTitle } from "../../../utils/csob";
import ProductItemDialog from "../../ProductList/ProductItemDialog";
import { SET_ADMIN_COMMISSION_CREATED_AT } from "../../../constants/actionTypes";
import { availableJobPositions, jobPositionObsluha } from "../adminEnum";
import { Restaurant } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "110%",
    fontWeight: 700,
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
}));

const CommissionNew2 = (props) => {
  const { localBusiness } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const cart = useSelector((state) => state.cart);
  const commissionListOrNewView = useSelector((state) => state.admin.adminView);


  const [pointOfSaleId, setPointOfSaleId] = React.useState(posVyskovKiosek);
  const [jobPosition, setJobPosition] = React.useState(jobPositionObsluha);
  const [commissionInited, setCommissionInited] = React.useState(true);

  const [editedCartItem, setEditedCartItem] = React.useState(null);
  const [editedCartItemIndex, setEditedCartItemIndex] = React.useState(null);

  // const [openDialogJobPosition, setOpenDialogJobPosition] =
  //   React.useState(false);
  // const chooseJobPosition = (jb) => {
  //   dispatch(updateCommission("createdByJobPosition", jb));
  //   dispatch({
  //     type: SET_ADMIN_COMMISSION_CREATED_AT,
  //     payload: new Date(),
  //   });
  //   setOpenDialogJobPosition(false);
  // };

  const pointOfSales = settings.pointOfSales.filter(
    (pos) => pos.localBusinessTitle === localBusiness.title
  );

  const paymentMethods = settings.paymentMethods.filter((pm) => {
    return (
      pm.pointOfSales.includes(cart.pointOfSaleId) &&
      pm.title !== csobPaymentTitle
    );
  });

  const resetCart = () => {
    dispatch(emptyCart());
    const newPointOfSale = pointOfSales[0];
    const newAddress = getAllPointOfSalesOnlyDelivery().includes(
      newPointOfSale.id
    )
      ? ""
      : addressXNA;
    dispatch(
      setPointOfSale(newPointOfSale, newAddress, servicePrice(newPointOfSale))
    );
    setPointOfSaleId(posVyskovKiosek);
    setJobPosition(jobPositionObsluha);
  };
  const startCommission = () => {
    resetCart();
    setCommissionInited(false);
    // setOpenDialogJobPosition(true);
  };

  React.useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      dispatch(
        updateCommission("commissionPaymentMethod", paymentMethods[0].title)
      );
    }
  }, [cart.pointOfSaleId]);

  React.useEffect(() => {
    if(commissionListOrNewView === 'CREATE'){
      startCommission();
    }
    if(commissionListOrNewView === "CREATE_FROM_TEMPLATE"){
      //setCommissionInited(false);
    }
  }, [commissionListOrNewView]);

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = false;

  const handleChangePos = (event) => {
    const newPointOfSale = pointOfSales.find(
      (pos) => pos.id === event.target.value
    );
    const newAddress = getAllPointOfSalesOnlyDelivery().includes(
      newPointOfSale.id
    )
      ? cart.customerAddress === addressXNA
        ? ""
        : cart.customerAddress
      : addressXNA;
    dispatch(
      setPointOfSale(newPointOfSale, newAddress, servicePrice(newPointOfSale))
    );
  };

  const isCounterSale = cart?.pointOfSaleId?.includes("kiosek");

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      dispatch(updateCommission(event.target.name, event.target.checked));
    } else {
      dispatch(updateCommission(event.target.name, event.target.value));
    }
  };

  const handleChangeTakeoverDateTime = (datetime, servicePrice) => {
    dispatch(setTakeOverDateTime(datetime, servicePrice));
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    resetCart();
    setDialogOpen(false);
    setDialogContent(null);
  };

  const getErrors = () => {
    const errors = [];
    if (
      cart.pointOfSaleId !== posVyskovKiosek &&
      cart.customerTelephone === ""
    ) {
      errors.push("Není uvedeno telefonní číslo.");
    }
    if (
      cart.pointOfSaleId !== posVyskovKiosek &&
      cart.customerTelephone !== "" &&
      !/^(\+)?[- 0-9]{9,}$/.test(cart.customerTelephone)
    ) {
      errors.push("Telefonní číslo nemá správný formát.");
    }
    if (!cart.commissionPaymentMethod) {
      errors.push("Není vybrán způsob platby.");
    }
    if (!cart.commissionTakeOverDateTime) {
      errors.push("Není vybrán datum a čas převzetí.");
    }
    if (
      getAllPointOfSalesOnlyDelivery().includes(cart.pointOfSaleId) &&
      ["", addressXNA].includes(cart.customerAddress.trim())
    ) {
      errors.push("Není zadána adresa.");
    }
    return errors;
  };

  const handleEditCartItem = (cartItem, cartItemIndex) => {
    setEditedCartItem(cartItem);
    setEditedCartItemIndex(cartItemIndex);
  };

  const handleToggleTakeAway = (cartItem, cartItemIndex) => {
    const prev = cartItem.hasOwnProperty("takeAway") ? cartItem.takeAway : true;
    dispatch(
      updateCartItem({
        cartItem: { ...cartItem, takeAway: !prev },
        index: cartItemIndex,
      })
    );
  };

  const handleAllCommissionTakeAway = () => {
    const prev = cart.hasOwnProperty("takeAway") ? cart.takeAway : true;
    dispatch(updateCommission("takeAway", !prev));
    if (prev) {
      cart.items.forEach((cartItem, index) => {
        dispatch(
          updateCartItem({
            cartItem: { ...cartItem, takeAway: false },
            index: index,
          })
        );
      });
    }
  };

  const handleSubmitDialog = () => {
    dispatch(updateCommission("createdByJobPosition", jobPosition));
    dispatch({
      type: SET_ADMIN_COMMISSION_CREATED_AT,
      payload: new Date(),
    });
    handleChangePos({
      target: {
        value: pointOfSaleId,
      },
    });
    if (pointOfSaleId === posVyskovKiosek) {
      dispatch(
        updateCommission("commissionPaymentMethod", paymentMethods[0].title)
      );
    }
    dispatch(
      updateCommission(
        "commissionOrigin",
        pointOfSaleId === posVyskovKiosek
          ? commissionOriginCounter
          : commissionOriginTelephone
      )
    );
    setCommissionInited(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    const returnUrl = window.location.href;
    //objednávka se odesílá do na server
    const data = {
      returnUrl,
      content: prepareCart(
        cart,
        null,
        pointOfSales.find((pos) => pos.id === cart.pointOfSaleId)
      ),
    };
    axios
      .post(apiPostCommission(), JSON.stringify(data))
      .then((response) => {
        if (response.status === 201) {
          //objednávka založena
          setDialogContent({
            status: "successful",
            title: "Objednávka byla založena.",
            commission: response.data.commission,
          });

          openDialog();

          setLoading(false);
        } else {
          //objednávka nebyla založena
          setDialogContent({
            status: "failure",
            title: "Objednávka nebyla založena.",
          });
          openDialog();
          setLoading(false);
        }
      })
      .catch((err) => {
        //objednávka nebyla založena
        setDialogContent({
          status: "failure",
          title: "Objednávka nebyla založena.",
        });
        openDialog();
        setLoading(false);
      });
  };

  return (
    <>
      {!!editedCartItem && (
        <ProductItemDialog
          open={!!editedCartItem}
          product={editedCartItem?.product || null}
          initialCartItem={editedCartItem}
          handleDialogClose={() => {
            setEditedCartItem(null);
            setEditedCartItemIndex(null);
          }}
          submit={(cartItem) => {
            dispatch(
              updateCartItem({
                cartItem,
                index: editedCartItemIndex,
              })
            );
            setEditedCartItem(null);
            setEditedCartItemIndex(null);
          }}
        />
      )}
      <Dialog open={!commissionInited} maxWidth={false}>
        <DialogContent>
          <Typography variant={"h6"}>Obchodní místo</Typography>
          <Box style={{ display: "flex", marginTop: 16, marginBottom: 32 }}>
            {pointOfSales
              .sort((a, b) => {
                const tA = pointOfSaleTitleForNewCommission[a.id];
                const tB = pointOfSaleTitleForNewCommission[b.id];
                return tA.localeCompare(tB);
              })
              .map((pos) => {
                const title = pointOfSaleTitleForNewCommission[pos.id];
                return (
                  <Button
                    style={{ marginLeft: 8, marginRight: 8 }}
                    size={"large"}
                    variant={"contained"}
                    key={pos.id}
                    color={pos.id === pointOfSaleId ? "primary" : "secondary"}
                    onClick={() => {
                      setPointOfSaleId(pos.id);
                      if (pos.id === posVyskovKiosek) {
                        setJobPosition(jobPositionObsluha);
                      } else {
                        setJobPosition(null);
                      }
                    }}
                  >
                    {title}
                  </Button>
                );
              })}
          </Box>
          <Collapse in={pointOfSaleId !== posVyskovKiosek}>
            <Typography variant={"h6"}>Kdo objednávku přijímá?</Typography>
            <Box style={{ display: "flex", marginTop: 16, marginBottom: 32 }}>
              {availableJobPositions.map((jb) => {
                return (
                  <Button
                    style={{ marginLeft: 8, marginRight: 8 }}
                    size={"large"}
                    variant={"contained"}
                    key={jb}
                    color={jb === jobPosition ? "primary" : "secondary"}
                    onClick={() => {
                      setJobPosition(jb);
                    }}
                  >
                    {jb}
                  </Button>
                );
              })}
            </Box>
          </Collapse>
          <Divider />
          <Box style={{ display: "flex", marginTop: 16, marginBottom: 16 }}>
            <Button
              disabled={!jobPosition || !pointOfSaleId}
              onClick={handleSubmitDialog}
              style={{ marginLeft: 8, marginRight: 8 }}
              size={"large"}
              variant={"contained"}
              color={"primary"}
            >
              Nová objednávka
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/*<Dialog open={openDialogJobPosition}>*/}
      {/*  <DialogTitle>Kdo přijímá objednávku?</DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    <Box pb={2}>*/}
      {/*      <Grid container spacing={4}>*/}
      {/*        {availableJobPositions.map((jp) => {*/}
      {/*          return (*/}
      {/*            <Grid key={jp} item xs={4}>*/}
      {/*              <Button*/}
      {/*                onClick={() => {*/}
      {/*                  chooseJobPosition(jp);*/}
      {/*                }}*/}
      {/*                fullWidth*/}
      {/*                variant={"contained"}*/}
      {/*                color={"secondary"}*/}
      {/*                size={"large"}*/}
      {/*              >*/}
      {/*                {jp}*/}
      {/*              </Button>*/}
      {/*            </Grid>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </Grid>*/}
      {/*    </Box>*/}
      {/*  </DialogContent>*/}
      {/*</Dialog>*/}
      <Box mb={3}>
        <Button
          size={"large"}
          fullWidth
          variant={"contained"}
          color={"secondary"}
          onClick={() => {
            startCommission();
          }}
        >
          Nová objednávka
        </Button>
      </Box>
      {cart.createdByJobPosition && (
        <>
          <Box hidden={cart.pointOfSaleId === posVyskovKiosek}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant={"outlined"} size={"small"} fullWidth>
                  <InputLabel shrink={true}>Obchodní místo</InputLabel>
                  <Select
                    value={cart.pointOfSaleId}
                    onChange={handleChangePos}
                    label="Obchodní místo"
                  >
                    {pointOfSales.map((pos) => {
                      let title = pointOfSaleTitleForNewCommission[pos.id];
                      return (
                        <MenuItem key={pos.id} value={pos.id}>
                          {title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {!isCounterSale && (
                <>
                  {getAllPointOfSalesOnlyDelivery().includes(
                    cart.pointOfSaleId
                  ) && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Adresa doručení"
                        size={"small"}
                        variant={"outlined"}
                        name={"customerAddress"}
                        value={cart.customerAddress}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Jméno a příjmení"
                      size={"small"}
                      variant={"outlined"}
                      value={cart.customerFullName}
                      name={"customerFullName"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="E-mail"
                      size={"small"}
                      variant={"outlined"}
                      value={cart.customerEmail}
                      name={"customerEmail"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Telefon"
                      size={"small"}
                      variant={"outlined"}
                      value={cart.customerTelephone}
                      name={"customerTelephone"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label="Poznámka"
                      size={"small"}
                      variant={"outlined"}
                      value={cart.commissionComment}
                      name={"commissionComment"}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" size={"small"}>
                  <InputLabel shrink={true}>Způsob platby</InputLabel>
                  <Select
                    value={cart.commissionPaymentMethod}
                    onChange={handleChange}
                    name={"commissionPaymentMethod"}
                    label={"Způsob platby"}
                  >
                    {paymentMethods.map((pm) => {
                      return (
                        <MenuItem
                          key={pm.title}
                          style={{ whiteSpace: "normal !important" }}
                          value={pm.title}
                        >
                          {pm.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <Box*/}
              {/*    display={"flex"}*/}
              {/*    alignItems={"center"}*/}
              {/*    justifyContent={"space-between"}*/}
              {/*  >*/}
              {/*    <Box>Datum a čas převzetí:</Box>*/}
              {/*    <Box>*/}
              {/*      {cart.pointOfSaleId && (*/}
              {/*        <DateTimePicker onSubmit={handleChangeTakeoverDateTime} />*/}
              {/*      )}*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
            </Grid>
          </Box>
          <Box>
            <Cart
              cart={cart}
              editable={true}
              handleEditCartItem={handleEditCartItem}
              handleToggleTakeAway={handleToggleTakeAway}
              context={contextHome}
              defaultErrors={getErrors()}
              handleSubmit={handleSubmit}
              isButtonDisabled={loading}
              dateTimePickerComponent={
                <Box>
                  <Box
                    hidden={
                      !(
                        getTotalQuantity(cart) > 0 &&
                        cart.commissionOrigin === commissionOriginCounter
                      )
                    }
                  >
                    <Button
                      onClick={handleAllCommissionTakeAway}
                      startIcon={<Restaurant />}
                      color={"secondary"}
                      variant={
                        cart.hasOwnProperty("takeAway") && !cart.takeAway
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Celá objednávka tady
                    </Button>
                  </Box>
                  <Box
                    display={getTotalQuantity(cart) === 0 ? "none" : "flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    style={{ marginTop: 16, marginBottom: 16 }}
                  >
                    <Box>Datum a čas převzetí:</Box>
                    <Box>
                      {cart.pointOfSaleId && (
                        <DateTimePicker
                          onSubmit={handleChangeTakeoverDateTime}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Box>
        </>
      )}
      <Dialog open={dialogOpen} onClose={closeDialog} fullScreen={fullScreen}>
        {dialogContent && (
          <>
            <DialogTitle>{dialogContent.title}</DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography>{dialogContent.title}</Typography>
              {dialogContent.commission &&
                dialogContent.status === "successful" && (
                  <Typography>
                    Objednávka má číslo:{" "}
                    <strong>
                      {dialogContent.commission.content.numberPosDay}
                    </strong>
                  </Typography>
                )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeDialog}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommissionNew2;

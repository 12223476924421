import React from "react";
import {getAdditionItemsOfAdditionGroup} from "../../utils/data";
import {Box, Grid, makeStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {formatSurchargePrice} from "../../utils/price";
import {colors} from "../../theme/theme";
import Checkbox from "@material-ui/core/Checkbox";
import {getImgSrcForAdditionItem} from "../../utils/imgSrc";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import {useSelector} from "react-redux";
import {
    additionGroupDrinkInMenu,
    additionGroupIcecreamInMenu, additionGroupsWithMultipleQuantity,
    additionItemNoSauce,
} from "../../constants/constants";
import {getCartItemAdditionItemQuantity, getCartItemAdditionItemQuantityKey} from "../../utils/cart";

const useStyles = makeStyles((theme) => ({
    accordion: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: "8px !important",
        marginTop: "0 !important",
        borderRadius: "0px !important",
        borderStyle: "none !important",
        overflow: "hidden",
        "&:before": {
            opacity: "0 !important",
        },
        "& .MuiAccordionSummary-root": {
            minHeight: "48px !important",
            margin: "0 !important",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        "& .MuiAccordionSummary-content": {
            margin: "8px 0 !important",
        },
        "& .MuiAccordionDetails-root": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    additionGroupTitle: {
        fontWeight: 300,
        fontSize: "100%",
        "& span": {
            fontSize: "100%",
            fontWeight: 400,
        },
    },
    additionGroupSubtitle: {
        fontSize: "90%",
        "&.warning": {
            fontWeight: 600,
            color: colors.RED.main,
        },
    },
    additionItem: {
        height: 42,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: "rgba(0, 0, 0, 0.75)",
        backgroundColor: "rgba(0, 0, 0, 0.00)",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .check": {},
        "& .img": {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            height: "100%",
            "& img": {
                height: "100%",
            },
        },
        "& .label": {
            flexGrow: 1,
            marginLeft: theme.spacing(1),
            fontSize: "100%",
            textTransform: "none",
        },
        "& .price": {
            textAlign: "right",
            fontSize: "100%",
        },
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
        "&.selected": {
            color: theme.palette.primary.main,
        },
        "&.disabled": {
            opacity: 0.4,
            cursor: "default",
            "& input": {
                cursor: "default",
            },
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0)",
            },
        },
    },
}));

const getAdditionGroupTitle = (additionGroup) => {
    return additionGroup.title;
};

const getAdditionGroupTitleSelection = (
    additionGroup,
    selectedAdditionItems, cartItem
) => {
    return selectedAdditionItems
        .filter((a) => a.groupTitle === additionGroup.fullTitle)
        .map((a) => {
            const key = getCartItemAdditionItemQuantityKey(a);
            const qty = getCartItemAdditionItemQuantity(cartItem, a, -1);
            if (qty === -1) {
                return a.title;
            } else {
                return qty + 'x ' + a.title;
            }
        });
};

const getAdditionItemTitle = (additionItem) => {
    return additionItem.title;
};

const ProductItemDialogAdditionItem = (props) => {
    const {
        additionGroup,
        additionItem,
        selected,
        onAdditionItemChange,
        disabled,
        cartItem
    } = props;

    const classes = useStyles();

    const multipleQuantity = additionGroupsWithMultipleQuantity.includes(additionGroup.fullTitle);
    const key = getCartItemAdditionItemQuantityKey(additionItem);
    const qty = getCartItemAdditionItemQuantity(cartItem, additionItem, 0);

    const handleOnAdditionItemChange = () => {
        if (multipleQuantity) {
            onAdditionItemChange({multipleQuantity: true, direction: 'add'});
        } else {
            onAdditionItemChange({multipleQuantity: false});
        }
    }

    return (
        <Box
            className={
                classes.additionItem +
                (selected ? " selected" : "") +
                (disabled ? " disabled" : "")
            }
        >
            <Box className={"check"}>
                {multipleQuantity && <Box style={{display: "flex"}}>
                    <Box style={{marginRight: 6, color: 'red', fontWeight: 'bold'}} onClick={() => {
                        onAdditionItemChange({multipleQuantity: true, direction: 'sub'});
                    }}>-</Box>
                    <Box style={{marginRight: 6, fontWeight: 'bold'}}>{qty}</Box>
                    <Box style={{marginRight: 6, color: 'red', fontWeight: 'bold'}} onClick={() => {
                        onAdditionItemChange({multipleQuantity: true, direction: 'add'});
                    }}>+</Box>
                </Box>}
                {!multipleQuantity && <Checkbox
                    checked={selected}
                    onClick={() => {
                        handleOnAdditionItemChange()
                    }}
                    color={"primary"}
                    icon={<CheckBoxOutlineBlankOutlinedIcon/>}
                    checkedIcon={<CheckBoxOutlinedIcon/>}
                />}
            </Box>
            {additionItem.imgSrc && (
                <Box className={"img"} onClick={() => {
                    handleOnAdditionItemChange()
                }}>
                    <img
                        src={getImgSrcForAdditionItem(additionItem)}
                        alt={getAdditionItemTitle(additionItem)}
                    />
                </Box>
            )}
            <Box className={"label"} onClick={() => {
                handleOnAdditionItemChange()
            }}>
                <div>
                    {getAdditionItemTitle(additionItem)}{" "}
                    <span style={{fontSize: "80%", fontWeight: 300}}>
            {additionItem.allergens && <>({additionItem.allergens})</>}
          </span>
                </div>
            </Box>
            <Box className={"price"} onClick={() => {
                handleOnAdditionItemChange()
            }}>
                {formatSurchargePrice(additionItem.surcharge)}
            </Box>
        </Box>
    );
};

const ProductItemDialogAdditionGroup = (props) => {
    const {additionGroup, selectedAdditionItems, onAdditionItemChange, cartItem} = props;

    const classes = useStyles();

    const rangeOfGoods = useSelector((state) => state.rangeOfGoods);

    const isDisabled = (additionItem) => {
        return false;
        // return (
        //   !isMandatory() &&
        //   !selectedAdditionItems.includes(additionItem) &&
        //   additionGroup.max * 1 ===
        //     selectedAdditionItems.filter(
        //       (ai) => ai.groupTitle === additionGroup.fullTitle
        //     ).length
        // );
    };

    const isMandatory = () => {
        return additionGroup.min * 1 === 1;
    };

    const alwaysExpanded = () => {
        return (
            isMandatory() ||
            additionGroup.fullTitle === additionGroupDrinkInMenu ||
            additionGroup.fullTitle === additionGroupIcecreamInMenu
        );
    };

    const [expanded, setExpanded] = React.useState(alwaysExpanded());
    const [warning, setWarning] = React.useState(false);

    const changeExpanded = () => {
        if (!alwaysExpanded()) {
            setExpanded(!expanded);
        }
    };

    const changeAdditionItem = (additionItem, qObject) => {
        if (additionItem.title === additionItemNoSauce) {
            //pokud je kliknuto na "Bez omáčky" -> rovnou informaci posíláme výše
            setWarning(false);
            onAdditionItemChange(additionItem, qObject);
        }

        if (isDisabled(additionItem)) {
            return false;
        }

        if (
            !isMandatory() &&
            additionGroup.max * 1 !== 1 &&
            !selectedAdditionItems.includes(additionItem) &&
            selectedAdditionItems.filter(
                (ai) => ai.groupTitle === additionGroup.fullTitle
            ).length +
            1 >
            additionGroup.max
        ) {
            setWarning(true);
        } else {
            setWarning(false);
            onAdditionItemChange(additionItem, qObject);
        }
    };

    return (
        <Accordion
            variant={"elevation"}
            elevation={0}
            className={classes.accordion}
            expanded={expanded}
            onChange={changeExpanded}
        >
            <AccordionSummary
                expandIcon={!alwaysExpanded() ? <ExpandMoreIcon/> : ""}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.additionGroupTitle}>
                    {getAdditionGroupTitle(additionGroup)}
                    {getAdditionGroupTitleSelection(additionGroup, selectedAdditionItems, cartItem)
                        .length > 0 && (
                        <React.Fragment>
                            :{" "}
                            <span>
                {getAdditionGroupTitleSelection(
                    additionGroup,
                    selectedAdditionItems, cartItem
                ).join(", ")}
              </span>
                        </React.Fragment>
                    )}
                    {warning && (
                        <Box className={classes.additionGroupSubtitle + " warning"}>
                            {additionGroup.maxMsg}
                        </Box>
                    )}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    {getAdditionItemsOfAdditionGroup(rangeOfGoods, additionGroup)
                        .filter((additionItem) => {
                            return additionItem.invisible === false;
                        })
                        .map((additionItem, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <ProductItemDialogAdditionItem
                                        additionGroup={additionGroup}
                                        additionItem={additionItem}
                                        selected={selectedAdditionItems.includes(additionItem)}
                                        disabled={isDisabled(additionItem)}
                                        onAdditionItemChange={(qObject) => {
                                            changeAdditionItem(additionItem, qObject);
                                        }}
                                        cartItem={cartItem}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ProductItemDialogAdditionGroup;

import { baseUrlFiles, baseUrlFilesThumbnails } from "../constants/constants";

export const getBigImgSrcForProduct = (product) => {
  return baseUrlFiles + product.imgSrc;
};

export const getBigImgSrcForSlider = (slider) => {
  return baseUrlFiles + slider.imgSrc;
};

export const getImgSrcForProduct = (product) => {
  return baseUrlFilesThumbnails + product.imgSrc;
};

export const getImgSrcForAdditionItemPizzaQuarterEmpty = () => {
  return baseUrlFilesThumbnails + "pc_empty.png";
};

export const getImgSrcForAdditionItemPizzaHalfEmpty = () => {
  return baseUrlFilesThumbnails + "ph_empty.png";
};

export const getImgSrcForAdditionItem = (additionItem) => {
  return baseUrlFilesThumbnails + additionItem.imgSrc;
};

export const getImgSrcForCategory = (category) => {
  return baseUrlFilesThumbnails + category.imgSrc;
};

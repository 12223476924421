export const KIOSK_ENABLED = process.env.REACT_APP_KIOSK_ENABLED === "true";
export const KIOSK_POINT_OF_SALE = process.env.REACT_APP_KIOSK_POINT_OF_SALE;

// export const KIOSK_ENABLED = true;
// export const KIOSK_POINT_OF_SALE = "Vyškov - kiosek: Vyškov: žádná zóna";

export const baseUrlFilesThumbnails =
  process.env.REACT_APP_API_ENDPOINT_FILES_THUMBNAILS;
export const baseUrlFiles = process.env.REACT_APP_API_ENDPOINT_FILES;

export const baseUrl = process.env.REACT_APP_API_ENDPOINT_SERVER;

export const addressXNA = "X/NA";

export const additionItemNoSauce = "Bez omáčky";

export const additionGroupDrinkInMenu = "Nápoj v menu";
export const additionGroupIcecreamInMenu = "Zmrzlina Burger menu";
export const additionGroupsWithMultipleQuantity = ['Přísada navíc na pizzu 30', 'Přísada navíc na pizzu 50'];

export const productPizzaQuarter = "Rodinná pizza na čtvrtky!";
export const productPizzaHalf = "Třicítka na půlky!";
export const additionGroupPizzaQuarter = "Pizza 50 na čtvrtiny";
export const additionGroupPizzaHalf = "Pizza 30 na půlky";

export const product2Plus1Gratis = "Akce 2 + 1";
export const product2Plus1GratisAdditionGroupTitle = "Třicítka zdarma";
export const product2Plus1GratisAdditionGroupTitles = [
  "Třicítka zdarma",
  "Třicítka zdarma - bezlepková varianta",
  "Speciální přání 30 + 50",
  "Potěrka 30 + 50",
];

export const pizza30s = [
  "třicítka",
  "třicítka bezlepková",
  "bezlepková třicítka",
];

export const categoryDrinksTitle = "Nápoje";

export const vosSectionPizza = "Pizza";
export const vosSectionMeat = "Maso";
export const vosSections = [
  { code: "pizza", title: vosSectionPizza, sizeXs: 5 },
  { code: "meat", title: vosSectionMeat, sizeXs: 5 },
  { code: "other", title: "Ostatní", sizeXs: 2 },
];

export const commissionOriginInternet = "internet";
export const commissionOriginTelephone = "telephone";
export const commissionOriginCounter = "counter";
export const commissionOriginKiosk = "kiosek";

export const getAllCommissionOrigins = [
  { code: commissionOriginInternet, title: "Web" },
  { code: commissionOriginTelephone, title: "Telefon" },
  { code: commissionOriginCounter, title: "Pult" },
  { code: commissionOriginKiosk, title: "Kiosek" },
];

export const notificationTimeout = 30 * 1000;

export const days = [
  { code: "monday", title: "Pondělí", index: 1 },
  { code: "tuesday", title: "Úterý", index: 2 },
  { code: "wednesday", title: "Středa", index: 3 },
  { code: "thursday", title: "Čtvrtek", index: 4 },
  { code: "friday", title: "Pátek", index: 5 },
  { code: "saturday", title: "Sobota", index: 6 },
  { code: "sunday", title: "Neděle", index: 0 },
];

export const headerHeight = 88;

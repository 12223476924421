import {
  APP_LOADING,
  DELIVERY_DIALOG,
  FETCH_ADMIN_CAPACITY_INIT,
  FETCH_ADMIN_CAPACITY_SUCCESS,
  SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE,
  SET_ADMIN_COMMISSION_CREATED_AT,
  SET_ADMIN_LOCAL_BUSINESS,
  SET_ADMIN_SELECTED_STATUSES,
} from "../constants/actionTypes";
import { getCommissionStatusesAll } from "../components/Admin/adminEnum";

const initialState = {
  isAppLoading: true,
  isDeliveryDialogOpen: false,
  isSale2Plus1GratisDialogOpen: false,
  adminLocalBusiness: null,
  adminSelectedStatuses: getCommissionStatusesAll()
    .filter((cs) => {
      return cs.code !== "closed";
    })
    .map((cs) => {
      return cs.code;
    }),
  adminCapacity: [],
  adminCommissionCreatedAt: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING: {
      const stateCopy = { ...state };
      stateCopy.isAppLoading = action.payload;

      return stateCopy;
    }
    case DELIVERY_DIALOG: {
      const stateCopy = { ...state };
      stateCopy.isDeliveryDialogOpen = action.payload;

      return stateCopy;
    }
    case SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE: {
      const stateCopy = { ...state };
      stateCopy.isSale2Plus1GratisDialogOpen = action.payload;

      return stateCopy;
    }
    case SET_ADMIN_LOCAL_BUSINESS: {
      const stateCopy = { ...state };
      stateCopy.adminLocalBusiness = action.payload;

      return stateCopy;
    }
    case SET_ADMIN_SELECTED_STATUSES: {
      const stateCopy = { ...state };
      stateCopy.adminSelectedStatuses = action.payload;

      return stateCopy;
    }
    case FETCH_ADMIN_CAPACITY_INIT: {
      return state;
    }
    case FETCH_ADMIN_CAPACITY_SUCCESS: {
      const stateCopy = { ...state };
      stateCopy.adminCapacity = action.payload;

      return stateCopy;
    }
    case SET_ADMIN_COMMISSION_CREATED_AT: {
      const stateCopy = { ...state };
      stateCopy.adminCommissionCreatedAt = action.payload;

      return stateCopy;
    }
    default: {
      return state;
    }
  }
};

export default appReducer;

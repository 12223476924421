import React from "react";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  pageTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& a": {
      color: theme.palette.primary.contrastText,
      // fontSize: "250%",
      textDecoration: "none",
    },
  },
  button: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: "150%",
    textDecoration: "none",
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant={"h3"} className={classes.pageTitle}>
        404! Ouha - na této stránce nic není.
      </Typography>

      <Box className={classes.buttonContainer}>
        <Link to={"/"}>
          <Button
            className={classes.button}
            color={"primary"}
            variant={"contained"}
            size={"large"}
          >
            Zpět na hlavní stránku
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default NotFoundView;

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
}));

const CommissionInfoCommissionCommentInternal = (props) => {
  const { commission, handleChangeCommission } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(
    commission.commissionCommentInternal
      ? commission.commissionCommentInternal
      : ""
  );

  const handleOpen = () => {
    setOpen(true);
    setComment(
      commission.commissionCommentInternal
        ? commission.commissionCommentInternal
        : ""
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleChangeCommission({ commissionCommentInternal: comment });
    handleClose();
  };

  return (
    <>
      <Box>
        <div>Interní poznámka</div>{" "}
        <div className={"text"} style={{ color: "red" }}>
          {commission.commissionCommentInternal ? (
            <>
              {commission.commissionCommentInternal}
              <Button
                style={{ marginLeft: 8 }}
                onClick={handleOpen}
                color={"primary"}
                variant={"outlined"}
                size={"small"}
              >
                změnit
              </Button>
            </>
          ) : (
            <Button
              onClick={handleOpen}
              color={"primary"}
              variant={"outlined"}
              size={"small"}
            >
              vložit
            </Button>
          )}
        </div>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box style={{ display: "flex" }}>
            <Box style={{ flexGrow: 1 }}>Interní poznámka</Box>
            <Box>
              <IconButton onClick={handleClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus={true}
            variant={"outlined"}
            label={"Interní poznáma"}
            multiline={true}
            fullWidth={true}
            rows={2}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color={"primary"}
            variant={"contained"}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommissionInfoCommissionCommentInternal;

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { SOURCE_MASO, SOURCE_PIZZA, SOURCE_ROZVOZ } from "../utils";
import { Close, DriveEta, Fastfood, LocalPizza } from "@material-ui/icons";
import {
  firebaseDb,
  firebaseDbPathInternalCommission,
} from "../../../../service/firebaseConfig";
import axios from "axios";
import {
  apiDeleteInternalCommission,
  apiGetInternalCommissionGenerate,
} from "../../../../api/endpoints";

const useStyles = makeStyles((theme) => {
  return {
    commission: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      fontSize: 12,
      padding: theme.spacing(0.5, 1, 0.5, 1),
      borderRadius: 16,
      background: "#fff",
      color: "#000",
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  };
});

const time = (unix) => {
  const dt = new Date(unix);
  return [dt.getHours(), dt.getMinutes()]
    .map((t) => {
      return t < 10 ? "0" + t : t;
    })
    .join(":");
};

const TimeBoxCommission = (props) => {
  const { commission, onClick } = props;

  const [hidden, setHidden] = React.useState(
    !!commission.hasOwnProperty("deletedAt")
  );
  React.useEffect(() => {
    setHidden(!!commission.hasOwnProperty("deletedAt"));
  }, [commission]);

  const classes = useStyles();

  const checkHidden = () => {
    return false;
    // const dt = new Date();
    // dt.setHours(dt.getHours() + 2, 0, 0, 0); //2 hodiny od začátku
    // const x = commission.startAt;
    // setHidden(dt.getTime() > x);
  };

  React.useEffect(() => {
    checkHidden();
    const interval = setInterval(() => {
      checkHidden();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (
    commission.hasOwnProperty("type") &&
    ["pauza", "pult"].includes(commission.type)
  ) {
    return (
      <Box
        style={{ display: hidden ? "none" : "flex", cursor: "pointer" }}
        className={[classes.commission].join(" ")}
        onClick={onClick}
      >
        <Box
          style={{
            marginRight: 4,
          }}
        >
          {commission.source === SOURCE_PIZZA && (
            <LocalPizza fontSize={"small"} />
          )}
          {commission.source === SOURCE_MASO && <Fastfood fontSize={"small"} />}
          {commission.source === SOURCE_ROZVOZ && (
            <DriveEta fontSize={"small"} />
          )}
        </Box>
        <Box>
          <Box style={{ textAlign: "left" }}>
            {commission.type === "pauza" ? "Přestávka" : "Výroba na pult"}{" "}
            <span style={{ textTransform: "lowercase" }}>
              {commission.jobPosition}
            </span>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                marginRight: 4,
              }}
            >
              <strong>{time(commission.startAt)}</strong>
            </Box>
            <Box>
              <strong>
                {commission.type === "pauza"
                  ? commission.value + " minut"
                  : commission.value + "x padesátka"}
              </strong>
            </Box>
          </Box>
        </Box>
        <Box style={{ marginLeft: 4 }}>
          <IconButton style={{ padding: 0 }}>
            <Close fontSize={"small"} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return <></>;
};

const getStartAt = () => {
  let startAt = new Date();
  startAt.setHours(0, 0, 0, 0);
  startAt = startAt.getTime();

  return startAt;
};

const getEndAt = (days = 2) => {
  let endAt = new Date();
  endAt.setDate(endAt.getDate() + days);
  endAt.setHours(23, 59, 59, 0);
  endAt = endAt.getTime();

  return endAt;
};

const InternalCommissionTimelineV2 = () => {
  const internalCommissions = useSelector(
    (state) => state.admin.internalCommissions
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCommission, setSelectedCommission] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const initLoad = async () => {
    setLoading(true);
    const internalCommissions = [];
    await firebaseDb
      .ref(firebaseDbPathInternalCommission)
      .orderByChild("startAt")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          const firebaseKey = snap.key;
          const internalCommission = snap.val();
          internalCommissions.push({ ...internalCommission, firebaseKey });
        });
      });
    dispatch({
      type: "INTERNAL_COMMISSION_SET",
      payload: {
        internalCommissions,
      },
    });
    setLoading(false);
  };

  const initObserver = async () => {
    firebaseDb
      .ref(firebaseDbPathInternalCommission)
      .orderByChild("startAt")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .on("child_added", (snapshot) => {
        const firebaseKey = snapshot.key;
        const internalCommission = snapshot.val();
        dispatch({
          type: "INTERNAL_COMMISSION_ADD",
          payload: {
            firebaseKey,
            internalCommission: { ...internalCommission, firebaseKey },
          },
        });
      });
  };

  const initRemove = async () => {
    firebaseDb
      .ref(firebaseDbPathInternalCommission)
      .orderByChild("startAt")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .on("child_removed", (snapshot) => {
        const firebaseKey = snapshot.key;
        const internalCommission = snapshot.val();
        dispatch({
          type: "INTERNAL_COMMISSION_REMOVE",
          payload: {
            internalCommission: internalCommission,
          },
        });
      });
  };

  const handleClose = () => {
    setSelectedCommission(null);
  };
  const handleOpen = (commission) => {
    setSelectedCommission(commission);
  };

  const handleSubmit = () => {
    axios
      .delete(apiDeleteInternalCommission(selectedCommission.uid))
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(
            selectedCommission.type === "pauza"
              ? "Přestávka byla odstraněna."
              : "Výroba na pult byla odstraněna.",
            {
              variant: "success",
            }
          );
          handleClose();
        }
      });
  };

  React.useEffect(() => {
    initLoad();
  }, []);

  React.useEffect(() => {
    if (loading === false) {
      initObserver();
      initRemove();
    }
  }, [loading]);

  const generateInternalCommissions = () => {
    axios.get(apiGetInternalCommissionGenerate()).then((response) => {});
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      generateInternalCommissions();
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Dialog open={Boolean(selectedCommission)} onClose={handleClose}>
        <DialogTitle>Odstranit?</DialogTitle>
        {!!selectedCommission && (
          <DialogContent>
            Opravdu chcete odstranit{" "}
            <strong>
              {selectedCommission.type === "pauza"
                ? "přestávku"
                : "výrobu na pult"}
            </strong>{" "}
            od <strong>{time(selectedCommission.startAt)}</strong>?
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant={"text"}
            size={"small"}
            color={"secondary"}
            onClick={handleClose}
          >
            Ne
          </Button>
          <Button
            variant={"contained"}
            size={"small"}
            color={"secondary"}
            onClick={handleSubmit}
          >
            Ano, odstranit
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {[...internalCommissions]
          .sort((a, b) => {
            return a.startAt - b.startAt;
          })
          .map((commission) => {
            return (
              <TimeBoxCommission
                commission={commission}
                onClick={() => {
                  handleOpen(commission);
                }}
              />
            );
          })}
      </Box>
    </>
  );
};

export default InternalCommissionTimelineV2;

import {
  ADD_ITEM_TO_CART,
  CHANGE_CART_ITEM_QUANTITY_ADD,
  CHANGE_CART_ITEM_QUANTITY_SUB,
  DELIVERY_DIALOG,
  EMPTY_CART,
  SET_POINT_OF_SALE,
  SET_TAKEOVER_DATETIME,
  UPDATE_CART_ITEM,
  UPDATE_COMMISSION,
} from "../constants/actionTypes";

export const emptyCart = () => {
  return { type: EMPTY_CART };
};

export const setPointOfSale = (pointOfSale, address, deliveryPrice) => {
  return (dispatch) => {
    const payload = {
      pointOfSale,
      address,
      deliveryPrice,
    };
    dispatch({ type: SET_POINT_OF_SALE, payload });
    dispatch({ type: DELIVERY_DIALOG, payload: false });
  };
};

export const addItemToCart = (payload) => {
  const cartItem = !payload.hasOwnProperty("takeAway")
    ? { ...payload, takeAway: true }
    : payload;
  return { type: ADD_ITEM_TO_CART, payload: cartItem };
};

export const updateCartItem = (payload) => {
  return { type: UPDATE_CART_ITEM, payload };
};

export const changeCartItemQuantityAdd = (payload) => {
  return { type: CHANGE_CART_ITEM_QUANTITY_ADD, payload };
};

export const changeCartItemQuantitySub = (payload) => {
  return { type: CHANGE_CART_ITEM_QUANTITY_SUB, payload };
};

export const updateCommission = (property, value) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COMMISSION, payload: { property, value } });
  };
};

export const setTakeOverDateTime = (datetime, servicePrice) => {
  return (dispatch) => {
    dispatch({
      type: SET_TAKEOVER_DATETIME,
      payload: { commissionTakeOverDateTime: datetime, servicePrice },
    });
  };
};

import React from "react";
import { useSelector } from "react-redux";
import CommissionListArchive from "../../components/Admin/CommissionListArchive/CommissionListArchive";

const CommissionListArchiveAdminView = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);

  return <CommissionListArchive localBusiness={localBusiness} />;
};

export default CommissionListArchiveAdminView;

import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Divider} from "@material-ui/core";
import {
    cancelledStatusCode,
    getCommissionStatusesForPointOfSale, initialCommissionStatus, jobPositionObsluha,
} from "../adminEnum";
import ForwardIcon from "@material-ui/icons/Forward";
import DeleteIcon from "@material-ui/icons/Delete";
import RepeatIcon from "@material-ui/icons/Repeat";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import axios from "axios";
import {apiPutCommission} from "../../../api/endpoints";
import {DOTYKACKA_STATUS_CREATED} from "./CommissionButtonsDotykacka";
import {useSnackbar} from "notistack";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import {useDispatch} from "react-redux";
import {SET_CART} from "../../../constants/actionTypes";
import {addressXNA, commissionOriginInternet, commissionOriginTelephone} from "../../../constants/constants";
import {initialState} from "../../../reducers/cartReducer";


const ThemedButtonMenu = (props) => {
    const {onClick, disabled, pointOfSale, onRefresh, commission, handleChangeCommission} = props;

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (status) => {
        onClick(status);
        handleClose();
    };

    const refresh = () => {
        onRefresh();
        handleClose();
    };

    const capacityCheck = commission.hasOwnProperty('capacityCheck') ? commission.capacityCheck : true;

    const handleToggleCapacityCheck = () => {
        const newValue = !capacityCheck;
        handleChangeCommission({
            capacityCheck: newValue,
        });
        handleClose();
    }

    const handleCreateInDotykacka = () => {
        const copy = {...commission};
        copy.dotykackaStatusCode = DOTYKACKA_STATUS_CREATED;
        axios
            .put(apiPutCommission(copy.uid), copy)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar("Založeno v Dotykačce", {
                        variant: "success",
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(
                    "Chyba při zakládání v Dotykačce: " + error.response.data.error,
                    {
                        variant: "error",
                    }
                );
            });
    };

    const handleCreateCart = () => {
        const cart = {...initialState};
        cart.pointOfSaleId = commission.pointOfSaleId;
        cart.items = JSON.parse(JSON.stringify(commission?.items || []));
        cart.saleActions = JSON.parse(JSON.stringify(commission?.saleActions || []));
        cart.servicePrice = commission.servicePrice;
        cart.customerFullName = commission.customerFullName;
        cart.customerEmail = commission.customerEmail;
        cart.customerTelephone = commission.customerTelephone;
        cart.customerAddress = commission.customerAddress;
        cart.commissionComment = commission.commissionComment;
        cart.commissionTextOnTheBox = commission.commissionTextOnTheBox;
        cart.createdByJobPosition = jobPositionObsluha;

        dispatch({type: SET_CART, payload: {cart: cart}});
        dispatch({
            type: 'ADMIN_VIEW_SET',
            payload: {
                adminView: 'CREATE_FROM_TEMPLATE'
            }
        })
        handleClose();
    };

    return (
        <>
            <Button
                style={{borderRadius: 4, height: 50}}
                variant={"outlined"}
                size={"large"}
                onClick={handleOpen}
                disabled={disabled}
            >
                <MoreVertIcon/>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {getCommissionStatusesForPointOfSale(pointOfSale)
                    .filter((cs) => cs.code === cancelledStatusCode)
                    .map((cs) => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    handleClick(cs);
                                }}
                                key={cs.code}
                            >
                                <DeleteIcon fontSize={"small"} style={{marginRight: 8}}/>{" "}
                                {cs.btnTitle}
                            </MenuItem>
                        );
                    })}
                <Divider/>
                {getCommissionStatusesForPointOfSale(pointOfSale)
                    .filter((cs) => cs.code !== cancelledStatusCode)
                    .map((cs) => {
                        return (
                            <MenuItem
                                key={cs.code}
                                onClick={() => {
                                    handleClick(cs);
                                }}
                            >
                                <ForwardIcon fontSize={"small"} style={{marginRight: 8}}/>{" "}
                                {cs.btnTitle}
                            </MenuItem>
                        );
                    })}
                <Divider/>
                <MenuItem
                    onClick={() => {
                        refresh();
                    }}
                >
                    <RepeatIcon fontSize={"small"} style={{marginRight: 8}}/> Ověřit
                    platbu
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleCreateCart();
                    }}
                >
                    <RepeatIcon fontSize={"small"} style={{marginRight: 8}}/> Vytvořit novou objednávku
                </MenuItem>
                {/*<MenuItem*/}
                {/*    onClick={() => {*/}
                {/*      handleCreateInDotykacka();*/}
                {/*    }}*/}
                {/*>*/}
                {/*  <DesktopMacIcon fontSize={"small"} style={{ marginRight: 8 }} /> Založit v Dotykačce*/}
                {/*</MenuItem>*/}
                <Divider/>
                <MenuItem
                    onClick={() => {
                        handleToggleCapacityCheck();
                    }}
                >
                    <ViewComfyIcon fontSize={"small"}
                                   style={{marginRight: 8}}/> {capacityCheck ? 'Nepoužít kapacity' : 'Použít kapacity'}
                </MenuItem>
            </Menu>
        </>
    );
};

export default ThemedButtonMenu;

import produce from "immer";

const initialState = {
  commissions: {},
  commissionsKeys: [],
  internalCommissions: [],
  adminView: 'LIST'
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADMIN_VIEW_SET': {
      return produce(state, (draft) => {
          draft.adminView = action.payload.adminView;
      });
    }
    case "ADMIN_COMMISSIONS_SET": {
      return produce(state, (draft) => {
        // const commissions = action.payload.commissions;
        // const commissionsKeys = Object.keys(action.payload.commissions);
        // action.payload.commissions.forEach((c) => {
        //   commissions[c.key] = c.commission;
        //   commissionsKeys.push(c.key);
        // });
        draft.commissions = action.payload.commissions;
        draft.commissionsKeys = Object.keys(action.payload.commissions);
      });
    }
    case "ADMIN_COMMISSIONS_ADD": {
      return produce(state, (draft) => {
        draft.commissions[action.payload.firebaseKey] =
          action.payload.commission;
        draft.commissionsKeys.push(action.payload.firebaseKey);
      });
    }
    case "COMMISSIONS_VALUE": {
      return produce(state, (draft) => {
        draft.commissions[action.payload.firebaseKey] =
          action.payload.commission;
        // draft.commissionsIds.push(action.payload);
      });
    }
    case "INTERNAL_COMMISSION_SET": {
      return produce(state, (draft) => {
        draft.internalCommissions = action.payload.internalCommissions;
        // draft.internalCommissionsKeys = Object.keys(
        //   action.payload.internalCommissions
        // );
      });
    }
    case "INTERNAL_COMMISSION_ADD": {
      return produce(state, (draft) => {
        if (
          !draft.internalCommissions
            .map((ic) => {
              return ic.firebaseKey;
            })
            .includes(action.payload.internalCommission.firebaseKey)
        ) {
          draft.internalCommissions.push(action.payload.internalCommission);
        }
        // draft.internalCommissionsKeys.push(action.payload.firebaseKey);
      });
    }
    case "INTERNAL_COMMISSION_REMOVE": {
      return produce(state, (draft) => {
        const index = draft.internalCommissions.findIndex(
          (ic) => ic.uid === action.payload.internalCommission.uid
        );
        if (index !== -1) draft.internalCommissions.splice(index, 1);
      });
    }
    default: {
      return state;
    }
  }
};

export default adminReducer;

import React from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

const initialForm = {
  loginName: "",
  password: "",
};

const LogIn = (props) => {
  const { onSubmit, error } = props;
  const [form, setForm] = React.useState(initialForm);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(form);
  };

  return (
    <>
      <Container
        style={{
          marginTop: 10 * 8,
          borderRadius: 10,
        }}
      >
        <Typography variant={"h3"} style={{ textAlign: "center" }}>
          Administrace
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: 5 * 8 }}
          >
            <Grid item>
              <FormControl>
                <TextField
                  label={"Jméno"}
                  variant={"outlined"}
                  name={"loginName"}
                  value={form.loginName}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  type={"password"}
                  label={"Heslo"}
                  variant={"outlined"}
                  name={"password"}
                  value={form.password}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleSubmit}
              >
                Přihlásit se
              </Button>
            </Grid>
            <Grid item>
              {error && <Box style={{ color: "red" }}>{error}</Box>}
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </>
  );
};

export default LogIn;

import { BrowserRouter, Route, Switch } from "react-router-dom";

// views
import Home from "../views/Home";
import CheckoutView from "../views/CheckoutView";

//layouts
import Layout from "../layouts/Layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../actions/settingsActions";
import LoaderPizza from "../components/LoaderPizza/LoaderPizza";
import LayoutAdmin from "../layouts/LayoutAdmin";
import CommissionListAdminView from "../views/admin/CommissionListAdminView";
import PageEditorView from "../views/admin/PageEditorView";
import PageView from "../views/PageView";
import { staticPages } from "../constants/staticPages";
import CheckoutDoneView from "../views/CheckoutDoneView";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import CommissionNewView from "../views/admin/CommissionNewView";
import CheckoutDoneFailureView from "../views/CheckoutDoneFailureView";
import RatingView from "../views/RatingView";
import NotFoundView from "../views/NotFoundView";
import CapacityAdminView from "../views/admin/CapacityAdminView";
import CommissionListArchiveAdminView from "../views/admin/CommissionListArchiveAdminView";
import ProductListAdminView from "../views/admin/ProductListAdminView";
import LayoutCustomer from "../layouts/LayoutCustomer";
import CommissionReportAdminView from "../views/admin/CommissionReportAdminView";
import ProductAdditionListAdminView from "../views/admin/ProductAdditionListAdminView";
import InternalCommissionPrescriptionView from "../views/admin/InternalCommissionPrescriptionView";
import CommissionCustomer from "../components/CommissionCustomer/CommissionCustomer";
import CommissionListOrNewView from "../views/admin/CommissionListOrNewView";

const RouteWithLayout = ({
  layout,
  component,
  title,
  pageSlug,
  path,
  ...rest
}) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return React.createElement(
          layout,
          { title },
          React.createElement(component, { ...props, pageSlug: pageSlug })
        );
      }}
    />
  );
};

const Router = () => {
  const dispatch = useDispatch();
  const isAppLoading = useSelector((state) => state.app.isAppLoading);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  if (isAppLoading || settings.localBusinesses.length === 0) {
    return <LoaderPizza />;
  }

  const routes = [
    { path: "/", component: Home, layout: Layout },
    { path: "/checkout", component: CheckoutView, layout: Layout },
    { path: "/pizza", component: RatingView, layout: Layout },
    { path: "/rating", component: RatingView, layout: Layout },
    {
      path: "/checkout/failure",
      component: CheckoutDoneFailureView,
      layout: Layout,
    },
    {
      path: "/checkout/:commissionUid",
      component: CheckoutDoneView,
      layout: Layout,
    },
    // {
    //   path: "/admin/create",
    //   component: CommissionNewView,
    //   layout: LayoutAdmin,
    //   title: "Nová objednávka",
    // },
    {
      path: "/admin",
      component: CommissionListOrNewView,
      layout: LayoutAdmin,
      title: "Objednávky",
    },
    {
      path: "/admin/archive",
      component: CommissionListArchiveAdminView,
      layout: LayoutAdmin,
      title: "Archiv objednávek",
    },
    {
      path: "/admin/report",
      component: CommissionReportAdminView,
      layout: LayoutAdmin,
      title: "Report objednávek",
    },
    {
      path: "/admin/capacity",
      component: CapacityAdminView,
      layout: LayoutAdmin,
      title: "Kapacity",
    },
    {
      path: "/admin/internal-commission-prescription",
      component: InternalCommissionPrescriptionView,
      layout: LayoutAdmin,
      title: "Pravidelné přestávky a výroby na pult",
    },
    {
      path: "/admin/products",
      component: ProductListAdminView,
      layout: LayoutAdmin,
      title: "Produkty",
    },
    {
      path: "/admin/product-addition-items",
      component: ProductAdditionListAdminView,
      layout: LayoutAdmin,
      title: "Přísady",
    },
    {
      path: "/zakaznicky-displej",
      component: CommissionCustomer,
      layout: LayoutCustomer,
      title: "Zákaznický display",
    },
    // {
    //   path: "/admin/reservations",
    //   component: CommissionReservationAdminView,
    //   layout: LayoutAdmin,
    //   title: "Kapacity a časová okna",
    // },
  ];

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {routes.map((route, index) => {
          return (
            <RouteWithLayout
              key={index}
              layout={route.layout}
              path={route.path}
              exact
              component={route.component}
              title={route.title}
              pageSlug={route.pageSlug}
            />
          );
        })}
        {staticPages.map((page, index) => {
          return (
            <RouteWithLayout
              key={index}
              layout={LayoutAdmin}
              path={"/admin/pages/" + page.pageSlug}
              exact
              component={PageEditorView}
              title={page.title}
              pageSlug={page.pageSlug}
            />
          );
        })}
        {staticPages.map((page, index) => {
          return (
            <RouteWithLayout
              key={index}
              layout={Layout}
              path={"/" + page.pageSlug}
              exact
              component={PageView}
              pageSlug={page.pageSlug}
            />
          );
        })}
        <RouteWithLayout
          layout={Layout}
          path={"*"}
          exact
          component={NotFoundView}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { product2Plus1Gratis } from "../../constants/constants";
import ProductItemDialog from "../ProductList/ProductItemDialog";
import { addItemToCart } from "../../actions/cartActions";
import { useSnackbar } from "notistack";
import { SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE } from "../../constants/actionTypes";

const Sale2Plus1 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.app.isSale2Plus1GratisDialogOpen);
  const product = useSelector((state) =>
    state.rangeOfGoods.products.find((p) => p.fullTitle === product2Plus1Gratis)
  );

  const handleClose = () => {
    dispatch({ type: SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE, payload: false });
  };

  const handleAddCartItemToCart = (cartItem) => {
    dispatch(addItemToCart(cartItem));
    handleClose();
    enqueueSnackbar("Položka byla přidána do objednávky.", {
      variant: "success",
    });
  };

  if (!product) {
    return <></>;
  }

  return (
    <ProductItemDialog
      open={open}
      product={product}
      handleDialogClose={handleClose}
      submit={handleAddCartItemToCart}
    />
  );
};

export default Sale2Plus1;

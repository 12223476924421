import React from "react";
import { Grid } from "@material-ui/core";
import CapacityGridItemCapacityDefinition from "./CapacityGridItemCapacityDefinition";
import CapacityHeaderItem from "./CapacityHeaderItem";
import { generateClassesForItem, useStyles } from "./functions";
import { useSelector } from "react-redux";

const CapacityGrid = (props) => {
  const classes = useStyles();

  const capacityState = useSelector((state) => state.capacity);
  const state = capacityState;
  const config = capacityState.config;

  return (
    <>
      {config.hours.map((hours) => {
        return (
          <React.Fragment key={hours}>
            {config.minutes.map((minutes) => {
              return (
                <React.Fragment key={hours + "_" + minutes}>
                  <Grid container className={classes.row}>
                    <Grid
                      item
                      style={{ width: "25%" }}
                      className={generateClassesForItem(
                        config,
                        state,
                        null,
                        hours,
                        minutes,
                        ["header"]
                      )}
                    >
                      <CapacityHeaderItem
                        hours={hours}
                        minutes={minutes}
                        config={config}
                      />
                    </Grid>
                    <Grid
                      key={["PIZZA", hours, minutes].join("_")}
                      item
                      style={{
                        width: "25%",
                      }}
                      className={generateClassesForItem(
                        config,
                        state,
                        "PIZZA",
                        hours,
                        minutes
                      )}
                    >
                      <CapacityGridItemCapacityDefinition
                        source={"PIZZA"}
                        hours={hours}
                        minutes={minutes}
                        config={config}
                      />
                    </Grid>
                    <Grid
                      key={["MASO", hours, minutes].join("_")}
                      item
                      style={{
                        width: "25%",
                      }}
                      className={generateClassesForItem(
                        config,
                        state,
                        "MASO",
                        hours,
                        minutes
                      )}
                    >
                      <CapacityGridItemCapacityDefinition
                        source={"MASO"}
                        hours={hours}
                        minutes={minutes}
                        config={config}
                      />
                    </Grid>
                    <Grid
                      key={["ROZVOZ", hours, minutes].join("_")}
                      item
                      style={{
                        width: "25%",
                      }}
                      className={generateClassesForItem(
                        config,
                        state,
                        "ROZVOZ",
                        hours,
                        minutes
                      )}
                    >
                      <CapacityGridItemCapacityDefinition
                        source={"ROZVOZ"}
                        hours={hours}
                        minutes={minutes}
                        config={config}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CapacityGrid;

export const sourcePIZZA = "PIZZA";
export const sourceMASO = "MASO";
export const sourceROZVOZ = "ROZVOZ";

// export const intervalSize = 5; //minutes
// export const defaultCapacityDefinitionPIZZA = 250;
// export const defaultCapacityDefinitionMASO = 170;
// export const defaultCapacityDefinitionROZVOZ = 2;

export const intervalSize = 15; //minutes
export const defaultCapacityDefinitionPIZZA = 750;
export const defaultCapacityDefinitionMASO = 500;
export const defaultCapacityDefinitionROZVOZ = 2;
export const defaultPricePizzaRodinna = 290;

import Grid from "@material-ui/core/Grid";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getAdditionItemsForPizzaHalf
} from "../../utils/data";
import {
  getImgSrcForAdditionItem, getImgSrcForAdditionItemPizzaHalfEmpty,
  getImgSrcForAdditionItemPizzaQuarterEmpty,
} from "../../utils/imgSrc";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  halfContainer: {
    position: "relative",
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  halfButton: {
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: 0,
    width: "100%",
    height: "100%",
    fontSize: "80%",
    [theme.breakpoints.up("sm")]: {
      fontSize: "100%",
    },
    fontWeight: 500,
    lineHeight: "1.25",
    letterSpacing: 0,
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    padding: theme.spacing(2.5),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    "&.notSelected": {
      //fontSize: "110%",
    },
    "& .MuiButton-label": {
      padding: theme.spacing(1),
      background: "rgba(255, 255, 255, 0.9)",
      borderRadius: 10,
      justifyContent: "center",
    },
  },
}));

const getDegForHalf = (half) => {
  switch (half) {
    case 0:
      return 0;
    case 1:
      return 180;
    default:
      return 0;
  }
};

const justify = [
  { justifyContent: "center", textAlign: "center" },
  { justifyContent: "center", textAlign: "center" },
];
const getJustifyForHalf = (half) => {
  return justify[half];
};

const ProductItemDialogAdditionGroupPizzaHalfs = (props) => {
  const { onCompleted } = props;

  const classes = useStyles();

  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [halfs, setHalfs] = React.useState([false, false]);
  const [halfIndex, setHalfIndex] = React.useState(0);

  const openMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setHalfIndex(index);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setHalfIndex(0);
  };

  const selectItemForHalf = (additionItem) => {
    const copy = [...halfs];
    copy[halfIndex] = additionItem;
    setHalfs(copy);
    closeMenu();
  };

  React.useEffect(() => {
    if (halfs.filter((q) => !!q).length === 2) {
      onCompleted(halfs);
    }
  }, [halfs]); //Note: V dependency array nemá být onCompleted - jinak nelze vybrat potěrka po poskládanou pizzu

  return (
    <Box className={classes.container}>
      <Grid container spacing={1}>
        {halfs.map((half, index) => {
          return (
            <Grid item xs={6} key={index}>
              <Box className={classes.halfContainer}>
                <img
                  style={{
                    transform: `rotate(${getDegForHalf(index)}deg)`,
                  }}
                  src={
                    half.imgSrc
                      ? getImgSrcForAdditionItem(half)
                      : getImgSrcForAdditionItemPizzaHalfEmpty()
                  }
                  alt={!half ? "Kliknutím vyberte" : half.title}
                />
                <Button
                  className={
                    classes.halfButton + (!half ? " notSelected" : "")
                  }
                  style={getJustifyForHalf(index)}
                  onClick={(event) => {
                    openMenu(event, index);
                  }}
                >
                  {!half ? "Kliknutím vyberte" : half.title}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {getAdditionItemsForPizzaHalf(rangeOfGoods)
          .filter((additionItem) => {
            return additionItem.invisible === false;
          })
          .map((additionItem, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  selectItemForHalf(additionItem);
                }}
              >
                {additionItem.title}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};

export default ProductItemDialogAdditionGroupPizzaHalfs;

import React from "react";
import { Button, MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import ForwardIcon from "@material-ui/icons/Forward";
import CheckIcon from "@material-ui/icons/Check";
import {
  finishedStatusCode,
  getCommissionStatusesAll,
  inProductionStatusCode,
} from "../adminEnum";
import { hasItemsInSection } from "../../../utils/cart";
import { vosSectionPizza } from "../../../constants/constants";

const isPizzaBaked = (cart) => {
  if (!cart.hasOwnProperty("isSectionPizzaBaked")) {
    return false;
  }
  return cart.isSectionPizzaBaked;
};

const BakedButton = (props) => {
  const { cart, section, handleChangeCommission = null } = props;

  return (
    <>
      {section.title === vosSectionPizza && !!handleChangeCommission && (
        <Button
          style={{ marginRight: 8 }}
          startIcon={isPizzaBaked(cart) ? <CheckIcon /> : <ForwardIcon />}
          color={"primary"}
          size={"small"}
          variant={isPizzaBaked(cart) ? "contained" : "outlined"}
          disabled={isPizzaBaked(cart)}
          onClick={() => {
            handleChangeCommission({
              isSectionPizzaBaked: true,
            });
          }}
        >
          Upečeno
        </Button>
      )}
    </>
  );
};

const CommissionCartSectionButton = (props) => {
  const {
    cart,
    section,
    markSectionDone,
    handleChangeCommission = null,
  } = props;

  const theme = createMuiTheme({
    palette: {
      primary: getCommissionStatusesAll().find(
        (cs) => cs.code === finishedStatusCode
      ).color,
    },
  });

  if (hasItemsInSection(cart, section)) {
    //objednávka má položku v dané sekci a sekce je připravená
    if (cart.sections[section.code] === true) {
      //sekce je připravená
      return (
        <MuiThemeProvider theme={theme}>
          <BakedButton
            cart={cart}
            section={section}
            theme={theme}
            handleChangeCommission={handleChangeCommission}
          />
          <Button
            startIcon={<CheckIcon />}
            color={"primary"}
            size={"small"}
            variant={"contained"}
            disabled={true}
            onClick={() => {
              markSectionDone(section);
            }}
          >
            Připraveno
          </Button>
        </MuiThemeProvider>
      );
    } else {
      if (cart.activeStatusCode === inProductionStatusCode) {
        //objednávka je ve stavu "připravuje se"
        return (
          <MuiThemeProvider theme={theme}>
            <BakedButton
              cart={cart}
              section={section}
              theme={theme}
              handleChangeCommission={handleChangeCommission}
            />
            {(isPizzaBaked(cart) || section.code !== "pizza") && (
              <Button
                startIcon={<ForwardIcon />}
                color={"primary"}
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  markSectionDone(section);
                }}
              >
                Připraveno
              </Button>
            )}
          </MuiThemeProvider>
        );
      } else {
        //objednávka není ve stavu "připravuje se"
        return <></>;
      }
    }
  } else {
    //objednávka nemá žádnou položku v dané sekci
    return <></>;
  }
};

export default CommissionCartSectionButton;

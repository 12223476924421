import { combineReducers } from "redux";
import rangeOfGoodsReducer from "./rangeOfGoodsReducer";
import settingsReducer from "./settingsReducer";
import cartReducer from "./cartReducer";
import appReducer from "./appReducer";
import capacityReducer from "../components/Admin/Capacity/reducers/capacityReducer";
import adminReducer from "./adminReducer";

export default combineReducers({
  app: appReducer,
  cart: cartReducer,
  rangeOfGoods: rangeOfGoodsReducer,
  settings: settingsReducer,
  capacity: capacityReducer,
  admin: adminReducer,
});

import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelledStatusCode,
  getCommissionStatusesAll,
  initialCommissionStatus,
  newStatusCode,
} from "../adminEnum";
import {
  firebaseDb,
  firebaseDbPathCommission,
} from "../../../service/firebaseConfig";
import axios from "axios";
import { apiGetCommission, apiPutCommission } from "../../../api/endpoints";
import CommissionDetail from "./CommissionDetail";
import {
  canCommissionBeShownInAdmin,
  isPaymentMethodPaymentOnline,
  isPaymentMethodPaymentOnlinePaid,
} from "../../../utils/payment";
import {
  DOTYKACKA_STATUS_CLOSED,
  DOTYKACKA_STATUS_CREATED,
} from "./CommissionButtonsDotykacka";
import { useSnackbar } from "notistack";

export const commissionVariantHorizontal = window.screen.width < 576;

const CommissionDetailInFirebase = (props) => {
  const { firebaseKey } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commission = useSelector(
    (state) => state.admin.commissions[firebaseKey]
  );
  const selectedStatuses = useSelector((state) =>
    state.app.adminSelectedStatuses.length === 0
      ? getCommissionStatusesAll().map((cs) => cs.code)
      : state.app.adminSelectedStatuses
  );
  const dispatch = useDispatch();

  const loadCommission = async (firebaseKey) => {
    // setLoading(true);
    firebaseDb
      .ref(firebaseDbPathCommission)
      .child(firebaseKey)
      .on("value", (snapshot) => {
        dispatch({
          type: "COMMISSIONS_VALUE",
          payload: {
            firebaseKey,
            commission: snapshot.val(),
          },
        });
        // setLoading(false);
      });
  };

  const handleChangeCommission = (payload) => {
    const commissionCopy = { ...commission, ...payload };

    dispatch({
      //aktualizuje data v DB (a následně DB -> FireBase)
      type: "COMMISSIONS_VALUE",
      payload: {
        firebaseKey,
        commission: commissionCopy,
      },
    });
    axios
      .put(apiPutCommission(commissionCopy.uid), commissionCopy)
      .then((response) => {
        if (response.status === 200) {
          //pokud je aktualizace úspěšná
          //a pokud je řečeno, že objednávka je přijatá
          if (payload.activeStatusCode === "accepted") {
            if (
              // a pokud nebylo ještě odesláno do dotykačky
              !commission.hasOwnProperty("dotykackaStatusCode") ||
              (commission.dotykackaStatusCode !== DOTYKACKA_STATUS_CREATED &&
                commission.dotykackaStatusCode !== DOTYKACKA_STATUS_CLOSED)
            ) {
              const copy = { ...commissionCopy };
              copy.dotykackaStatusCode = DOTYKACKA_STATUS_CREATED;
              axios
                .put(apiPutCommission(copy.uid), copy) //tak ještě pošlu do Dotykačky
                .then((response) => {
                  if (response.status === 200) {
                    enqueueSnackbar("Založeno v Dotykačce", {
                      variant: "success",
                    });
                  }
                })
                .catch((error) => {
                  enqueueSnackbar(
                    "Chyba při zakládání v Dotykačce: " +
                      error.response.data.error,
                    {
                      variant: "error",
                    }
                  );
                });
            }
          }
        }
      });
  };

  React.useEffect(() => {
    loadCommission(firebaseKey);
  }, [firebaseKey]);

  const tryResolveUnpaidCommission = () => {
    if (
      //pokud je objednávka nová, ale není zaplacena
      commission.activeStatusCode === newStatusCode &&
      isPaymentMethodPaymentOnline(commission) &&
      !isPaymentMethodPaymentOnlinePaid(commission)
    ) {
      const dt = new Date().getTime();
      axios.get(apiGetCommission(commission.uid)).then((response) => {
        if (response.status === 200) {
          const resCommission = response.data.commission.content;
          if (
            dt - resCommission.createdAtTimeStamp * 1000 > 10 * 60 * 1000 &&
            resCommission.activeStatusCode === newStatusCode &&
            isPaymentMethodPaymentOnline(resCommission) &&
            !isPaymentMethodPaymentOnlinePaid(resCommission)
          ) {
            //pokud objednávka po 10 minutách stále nezaplacena, tak je stornována
            handleChangeCommission({ activeStatusCode: cancelledStatusCode });
          }
        }
      });
    }
  };

  React.useEffect(() => {
    tryResolveUnpaidCommission();
    const interval = setInterval(() => {
      tryResolveUnpaidCommission();
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      style={{
        display:
          !commission ||
          !selectedStatuses.includes(commission.activeStatusCode) ||
          !canCommissionBeShownInAdmin(commission)
            ? "none"
            : "block",
      }}
    >
      <CommissionDetail
        commission={commission}
        handleChangeCommission={handleChangeCommission}
      />
    </Box>
  );
};

export default CommissionDetailInFirebase;

import {
  amber,
  green,
  grey,
  lightBlue,
  orange,
  purple,
  red,
} from "@material-ui/core/colors";
import { SOURCE_MASO, SOURCE_PIZZA, SOURCE_ROZVOZ } from "./Capacity/utils";

export const initialCommissionStatus = "new";
export const initialCommissionStatusForTelephoneOrigin = "accepted";

export const newStatusCode = "new";
export const acceptedStatusCode = "accepted";
export const inProductionStatusCode = "in_production";
export const finishedStatusCode = "finished";
export const onWayStatusCode = "on_way";
export const cancelledStatusCode = "cancelled";
export const closedStatusCode = "closed";

const commissionStatuses = [
  {
    code: "new",
    title: "Nová",
    btnTitle: "Nová",
    color: lightBlue,
    position: 1,
    nextStatus: "accepted",
  },
  {
    code: "accepted",
    title: "Přijatá",
    btnTitle: "Přijmout",
    color: purple,
    position: 2,
    nextStatus: "in_production",
  },
  {
    code: "in_production",
    title: "Vyrábí se",
    btnTitle: "Začít vyrábět",
    color: red,
    position: 3,
    nextStatus: "finished",
  },
  {
    code: "finished",
    title: "Připravená",
    btnTitle: "Připraveno",
    color: orange,
    position: 3,
    nextStatus: "on_way",
  },
  {
    code: "on_way",
    title: "Na cestě",
    btnTitle: "Na cestě",
    color: amber,
    position: 4,
    nextStatus: "closed",
  },
  {
    code: "closed",
    title: "Předaná",
    btnTitle: "Předáno",
    color: green,
    position: 5,
  },
  {
    code: "cancelled",
    title: "Stornovaná",
    btnTitle: "Stornovat",
    color: grey,
    position: 999,
  },
];

export const jobPositionObsluha = "Obsluha";
export const jobPositionPekar = "Pekař";
export const jobPositionBurgerar = "Burgerař";
export const jobPositionRozvozak = "Rozvozák";
export const jobPositionPikr = "Pikr";

export const availableJobPositions = [
  jobPositionObsluha,
  jobPositionPekar,
  jobPositionBurgerar,
  jobPositionRozvozak,
  jobPositionPikr,
];

export const availableJobPositionsForPauza = [
  jobPositionObsluha,
  jobPositionPekar,
  jobPositionBurgerar,
];

export const jobPosition2Source = {
  [jobPositionObsluha]: SOURCE_MASO,
  [jobPositionPekar]: SOURCE_PIZZA,
  [jobPositionBurgerar]: SOURCE_MASO,
  [jobPositionRozvozak]: SOURCE_ROZVOZ,
  [jobPositionPikr]: SOURCE_MASO,
};

const compareFn = (a, b) => {
  if (a.position === b.position) return 0;
  if (a.position < b.position) return -1;
  if (a.position > b.position) return 1;
};
export const getCommissionStatusesAll = () => {
  return commissionStatuses.sort(compareFn);
};
export const getCommissionStatusesForPointOfSale = (pointOfSale) => {
  const allowedStatuses = pointOfSale.commissionStatuses.split(",");
  return commissionStatuses
    .filter((cs) => allowedStatuses.includes(cs.code))
    .sort(compareFn);
};

export const csobPaymentTitle = "Platba kartou on-line";
export const csobPaymentStatuses = [
  { csobTitle: "Založeno", title: "Nezaplaceno", paid: false },
  { csobTitle: "Probíhá", title: "Nezaplaceno", paid: false },
  { csobTitle: "Zrušeno", title: "Nezaplaceno", paid: false },
  { csobTitle: "OK (Potvrzeno)", title: "Zaplaceno", paid: true },
  { csobTitle: "Odvolána", title: "Nezaplaceno", paid: false },
  { csobTitle: "Zamítnuta", title: "Nezaplaceno", paid: false },
  { csobTitle: "OK (Čeká na zaúčtování)", title: "Zaplaceno", paid: true },
  { csobTitle: "OK (Zúčtována)", title: "Zaplaceno", paid: true },
  { csobTitle: "Čeká na vrácení", title: "Nezaplaceno", paid: false },
  { csobTitle: "Vráceno", title: "Nezaplaceno", paid: false },
];

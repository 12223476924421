import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminCapacity,
  saveAdminCapacity,
} from "../../actions/adminCapacityActions";
import { useSnackbar } from "notistack";
import Capacity from "../../components/Admin/Capacity/Capacity";
import axios from "axios";
import { apiGetCommissionCapacity } from "../../api/endpoints";

const CapacityAdminView = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const capacities = useSelector((state) => state.app.adminCapacity);
  const settings = useSelector((state) => state.settings);

  React.useEffect(() => {
    dispatch(fetchAdminCapacity());
  }, []);

  const [commissions, setCommissions] = React.useState([]);
  React.useEffect(() => {
    const load = async () => {
      axios.get(apiGetCommissionCapacity()).then((response) => {
        setCommissions(response.data); //aktuální vytíženost
      });
    };
    load();
  }, []);

  const submitAdminCapacity = (capacities) => {
    dispatch(saveAdminCapacity(capacities));
    enqueueSnackbar("Kapacity byly změněny.", {
      variant: "success",
    });
  };

  return (
    <Capacity
      capacities={capacities}
      onSubmit={submitAdminCapacity}
      commissions={commissions}
      pointOfSales={settings.pointOfSales.filter(
        (pos) => pos.localBusinessTitle === localBusiness.title
      )}
    />
  );
};

export default CapacityAdminView;

import React from "react";
import { getCommissionStatusesForPointOfSale } from "../adminEnum";
import { Box, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  commissionVariantHorizontal,
  viewMobileBreakpoint,
} from "./CommissionDetail";
import { getAllPointOfSalesOnlyDelivery } from "../../../constants/pointOfSales";
import {
  commissionOriginCounter,
  commissionOriginInternet,
  commissionOriginKiosk,
  commissionOriginTelephone,
} from "../../../constants/constants";
import {getCommissionOrigin} from "./commissionOrigin";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: commissionVariantHorizontal ? "row" : "column",
    alignItems: commissionVariantHorizontal ? "center" : "top",
    justifyContent: commissionVariantHorizontal ? "space-between" : "normal",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    height: "100%",
    boxSizing: "border-box",
    borderBottom: commissionVariantHorizontal
      ? "solid 1px rgba(0, 0, 0, 0.12)"
      : "none",
    borderRight: !commissionVariantHorizontal
      ? "solid 1px rgba(0, 0, 0, 0.12)"
      : "none",
  },
  number: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: "180%",
    marginBottom: theme.spacing(commissionVariantHorizontal ? 0 : 0),
    marginRight: theme.spacing(commissionVariantHorizontal ? 1 : 0),
  },
  deliveryImg: {
    width: commissionVariantHorizontal ? 45 : 120,
    marginBottom: theme.spacing(commissionVariantHorizontal ? 0 : 1),
  },
  status: {
    marginBottom: theme.spacing(commissionVariantHorizontal ? 0 : 1),
    marginRight: theme.spacing(commissionVariantHorizontal ? 1 : 0),
    "&:last-child": {
      marginBottom: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
    paddingTop: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
    textAlign: "center",
    display: commissionVariantHorizontal ? "none" : "flex",
    flexDirection: "column",
    fontSize: "80%",
    "&.active": {
      display: commissionVariantHorizontal ? "flex" : "flex",
    },
  },
  title: {
    textTransform: "uppercase",
    fontFamily: "Roboto !important",
    fontWeight: 500,
    letterSpacing: 1,
  },
  time: {
    fontFamily: "Roboto !important",
    fontWeight: 400,
  },
}));

const CommissionStatus = (props) => {
  const { status, active } = props;

  const classes = useStyles();

  return (
    <Box
      className={[classes.status, active ? "active" : ""].join(" ")}
      style={{
        backgroundColor: active ? status.color[800] : grey[300],
        color: active ? "#ffffff" : grey[700],
      }}
    >
      <Box className={classes.title}>{status.title}</Box>
    </Box>
  );
};

const CommissionStatusBar = (props) => {
  const { commission, pointOfSale } = props;

  const activeStatus = getCommissionStatusesForPointOfSale(pointOfSale).find(
    (cs) => cs.code === commission.activeStatusCode
  );

  const classes = useStyles();

  const commissionOriginMap = {
    [commissionOriginInternet]: "E",
    [commissionOriginTelephone]: "T",
    [commissionOriginCounter]: "P",
    [commissionOriginKiosk]: "K",
  };

  const commissionNumber =
    (commission.commissionOrigin &&
    getCommissionOrigin(commission) in commissionOriginMap
      ? commissionOriginMap[getCommissionOrigin(commission)]
      : "") + commission.numberPosDay;

  return (
    <Box
      className={classes.root}
      style={{ backgroundColor: activeStatus.color[400] }}
    >
      <Box className={classes.number}>{commissionNumber}</Box>
      <Box style={{ textAlign: "center" }}>
        <img
          className={classes.deliveryImg}
          src={
            getAllPointOfSalesOnlyDelivery().includes(commission.pointOfSaleId)
              ? "/assets/deliveries/courier.png"
              : "/assets/deliveries/pickup.png"
          }
        />
      </Box>
      {getCommissionStatusesForPointOfSale(pointOfSale).map((cs) => {
        return (
          <CommissionStatus
            key={cs.code}
            status={cs}
            active={activeStatus.code === cs.code}
          />
        );
      })}
    </Box>
  );
};

export default CommissionStatusBar;

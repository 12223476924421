import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import axios from "axios";
import { apiGetCommissions } from "../../../api/endpoints";
import { DatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import CommissionReportFilter from "./CommissionReportFilter";
import { pointOfSaleTitle } from "../../../constants/pointOfSales";
import { getAllCommissionOrigins } from "../../../constants/constants";
import { getCommissionStatusesAll } from "../adminEnum";
import {
  DOTYKACKA_STATUS_CLOSED,
  DOTYKACKA_STATUS_CREATED,
  DOTYKACKA_STATUS_NONE,
  DOTYKACKA_STATUS_READY_TO_CREATE,
} from "../CommissionDetail/CommissionButtonsDotykacka";

const headerHeight = 0;

const useStyles = makeStyles((theme) => ({
  loading: {
    background: "#ffffff",
    height: "100vh",
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    paddingTop: headerHeight + theme.spacing(1),
    boxSizing: "border-box",
    backgroundColor: "#000000",
  },
}));

const initEnums = (settings, localBusiness) => {
  const pointOfSaleItems = {};
  const originItems = {};
  const statusItems = {};
  const paymentMethodItems = {};

  settings.pointOfSales
    .filter((pos) => {
      return pos.localBusinessTitle === localBusiness.title;
    })
    .forEach((pos) => {
      pointOfSaleItems[pos.id] = pointOfSaleTitle[pos.id];
    });

  settings.paymentMethods.forEach((pm) => {
    paymentMethodItems[pm.title] = pm.title;
  });

  getAllCommissionOrigins.forEach((c) => {
    originItems[c.code] = c.title;
  });

  getCommissionStatusesAll().forEach((s) => {
    statusItems[s.code] = s.title;
  });

  return {
    pointOfSaleId: {
      title: "Obchodní místo",
      items: pointOfSaleItems,
    },
    commissionOrigin: {
      title: "Původ objednávky",
      items: originItems,
    },
    activeStatusCode: {
      title: "Stav objednávky",
      items: statusItems,
    },
    commissionPaymentMethod: {
      title: "Typ platby",
      items: paymentMethodItems,
    },
    dotykackaStatusCode: {
      title: "Stav v Dotykačce",
      items: {
        [DOTYKACKA_STATUS_READY_TO_CREATE]: "Připraveno na založení",
        [DOTYKACKA_STATUS_CREATED]: "Založeno v Dotykačce",
        [DOTYKACKA_STATUS_CLOSED]: "Uzavřeno v Dotykačce",
        [DOTYKACKA_STATUS_NONE]: "Neznámý stav",
        "-1": "Neuvedeno",
      },
    },
  };
};

const CommissionReport = (props) => {
  const { localBusiness } = props;

  const classes = useStyles();

  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const [commissions, setCommissions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const settings = useSelector((state) => state.settings);

  const init = () => {
    setCommissions([]);
    setLoading(true);

    dateFrom.setHours(0, 0, 0, 0);
    const df = dateFrom.getTime();

    dateTo.setHours(23, 59, 59, 0);
    const dt = dateTo.getTime();

    const config = {
      params: {
        dateFrom: df,
        dateTo: dt,
        localBusiness: localBusiness.title,
      },
    };

    axios
      .get(apiGetCommissions(), config)
      .then((response) => {
        setCommissions(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    init();
  }, [localBusiness, dateFrom, dateTo]);

  const handleDateFrom = (date) => {
    setDateFrom(date);
    // setDateTo(date);
  };

  const handleDateTo = (date) => {
    setDateTo(date);
  };

  const handleSubmit = () => {
    init();
  };

  return (
    <>
      <Box mt={2} pl={2} pr={2} display={"flex"}>
        <Box mr={4} display={"flex"} alignItems={"center"}>
          <Box mr={1}>
            <strong>Den převzetí OD</strong>
          </Box>
          <Box>
            <DatePicker
              value={dateFrom}
              onChange={handleDateFrom}
              format={"d. M. yyyy"}
              autoOk={true}
              disableToolbar={true}
              showTodayButton={true}
              cancelLabel={"Zrušit"}
              clearLabel={"Zrušit"}
              okLabel={"OK"}
              todayLabel={"dnes"}
            />
          </Box>
          <Box mr={10} />
          <Box mr={1}>
            <strong>Den převzetí DO</strong>
          </Box>
          <Box>
            <DatePicker
              value={dateTo}
              onChange={handleDateTo}
              format={"d. M. yyyy"}
              autoOk={true}
              disableToolbar={true}
              showTodayButton={true}
              cancelLabel={"Zrušit"}
              clearLabel={"Zrušit"}
              okLabel={"OK"}
              todayLabel={"dnes"}
            />
          </Box>
        </Box>
        {/*<Box flexGrow={1} style={{ textAlign: "left" }}>*/}
        {/*  <Button*/}
        {/*    onClick={handleSubmit}*/}
        {/*    color={"primary"}*/}
        {/*    variant={"contained"}*/}
        {/*    size={"small"}*/}
        {/*  >*/}
        {/*    Vypsat*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
      <CommissionReportFilter
        loading={loading}
        commissions={commissions}
        enums={initEnums(settings, localBusiness)}
      />
    </>
  );
};

export default CommissionReport;

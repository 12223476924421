import { Badge, IconButton } from "@material-ui/core";

import CartDialog from "../CartDialog/CartDialog";
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { colors } from "../../theme/theme";
import { getTotalQuantity } from "../../utils/cart";
import { useSelector } from "react-redux";

const NavbarCart = () => {
  const [open, setOpen] = React.useState(false);

  const cart = useSelector((state) => state.cart);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CartDialog
        open={open}
        cart={cart}
        editable={true}
        handleClose={handleClose}
      />
      <IconButton onClick={handleOpen} edge={"end"}>
        <Badge
          badgeContent={getTotalQuantity(cart)}
          color="primary"
          style={{ fontFamily: "sans-serif" }}
        >
          <ShoppingCartIcon style={{ color: colors.BLACK.main }} />
        </Badge>
      </IconButton>
    </React.Fragment>
  );
};

export default NavbarCart;

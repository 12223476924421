/**
 * Vráti pořadové číslo dne v rámci týdne (1 = Pondělí, 7 = Neděle)
 *
 * @param date
 * @returns {number|number}
 */
export const getDayOfWeek = (date) => {
  const dayOfWeek = new Date(date).getDay(); //0 = Sunday, 1 = Monday
  return dayOfWeek;
  // return dayOfWeek === 0 ? 7 : dayOfWeek; //7 = Sunday, 1 = Monday
};

export const convertHoursMinutesToHM = ({ hours, minutes }) => {
  return hours * 60 + minutes;
};

export const convertHMToHoursMinutes = (hm) => {
  return {
    hours: Math.floor(hm / 60),
    minutes: hm - Math.floor(hm / 60) * 60,
  };
};

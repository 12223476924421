import Delivery, { DeliveryWindowText } from "./Delivery";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getClosedDayForPointOfSaleAndDate } from "../../utils/delivery";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    "& .title": {
      fontSize: "110%",
      fontWeight: 800,
    },
    "& .subtitle": {
      fontSize: "90%",
      fontWeight: 300,
    },
    "& .description": {
      paddingTop: theme.spacing(0.5),
      fontSize: "70%",
      fontWeight: 300,
    },
    "& .descriptionButton": {
      paddingTop: theme.spacing(0.5),
      fontSize: "70%",
      fontWeight: 300,
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));

const DeliveryDialog = (props) => {
  const dialogClasses = useStyles();

  const settings = useSelector((state) => state.settings);

  const isDeliveryDialogOpen = useSelector(
    (state) => state.app.isDeliveryDialogOpen
  );

  const [deliveryDialogOpeningHours, setDeliveryDialogOpeningHours] =
    React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [pos, setPos] = React.useState(null);

  const onAcceptanceDelivery = (deliveryType, pos) => {
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (
      getClosedDayForPointOfSaleAndDate(settings, pos.id, now) ||
      getClosedDayForPointOfSaleAndDate(settings, pos.id, tomorrow)
    ) {
      setDeliveryType(deliveryType);
      setPos(pos);
      setDeliveryDialogOpeningHours(true);
    }
  };

  return (
    <>
      <Dialog open={isDeliveryDialogOpen} fullWidth={true} maxWidth={"sm"}>
        <Delivery
          settings={settings}
          onAcceptanceDelivery={onAcceptanceDelivery}
        />
      </Dialog>
      <Dialog
        open={deliveryDialogOpeningHours}
        onClose={() => {
          setDeliveryDialogOpeningHours(false);
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className={dialogClasses.dialogTitle}>
          <Box style={{ display: "flex" }}>
            <Box style={{ flexGrow: 1 }}>
              <Box className={"title"}>Upozornění na změnu provozní doby</Box>
            </Box>
            <Box>
              <IconButton
                className={dialogClasses.closeButton}
                onClick={() => setDeliveryDialogOpeningHours(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={dialogClasses.dialogContent}>
          <DeliveryWindowText
            settings={settings}
            deliveryType={deliveryType}
            pos={pos}
            showInfo={false}
          />
        </DialogContent>
        <DialogActions className={dialogClasses.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            size={"large"}
            onClick={() => {
              setDeliveryDialogOpeningHours(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliveryDialog;

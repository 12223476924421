const initDates = (addDays) => {
  let dates = [];
  for (let i = 0; i <= addDays; i++) {
    let d = new Date();
    d.setDate(d.getDate() + i);
    dates.push(d);
  }

  return dates;
};

const initHours = (pointOfSale) => {
  let startHour = 23;
  let endHour = 0;
  for (let i = 1; i <= 7; i++) {
    let [start, end] = pointOfSale["hours_" + i].split("-").map((o, index) => {
      let splitted = o.trim().split(":");
      return splitted[1].trim() * 1 === 0 && index === 1
        ? splitted[0].trim() * 1 - 1
        : splitted[0].trim() * 1;
    });
    if (start < startHour) {
      startHour = start;
    }
    if (end > endHour) {
      endHour = end;
    }
  }
  let hours = [];
  for (let i = startHour; i <= endHour; i++) {
    hours.push(i);
  }
  return hours;
};

const initMinutes = (intervalSize) => {
  let m = [];
  for (let i = 0; i < 60; i = i + intervalSize) {
    m.push(i);
  }
  return m;
};

export const initConfig = (settings, pointOfSaleId) => {
  const pointOfSale = settings.pointOfSales.find(
    (pos) => pos.id === pointOfSaleId
  );
  const dates = initDates(1); //addDays = počet přidaných dní ode dnešního dne, tzn. 1 = dnes + zítra, 2 = dnes + zítra + pozítří
  const hours = initHours(pointOfSale);
  const minutes = initMinutes(pointOfSale.deliveryTimeWindow * 1);
  return {
    isInterval: pointOfSale.deliveryTimeWindowAsInterval,
    intervalSize: pointOfSale.deliveryTimeWindow * 1,
    deliveryAtFirst: pointOfSale.deliveryAtFirst * 1,
    dates,
    hours,
    minutes,
    pointOfSale,
  };
};

export const initConfigMultiplePOS = (pointOfSales, intervalSize) => {
  let hMin = 23;
  let hMax = 0;
  for (let pos of pointOfSales) {
    let hours = initHours(pos);
    let startHour = hours[0];
    let endHour = hours[hours.length - 1];
    if (startHour < hMin) {
      hMin = startHour;
    }
    if (endHour > hMax) {
      hMax = endHour;
    }
  }
  let hours = [];
  for (let i = hMin; i <= hMax; i++) {
    hours.push(i);
  }
  return {
    hours,
    minutes: initMinutes(intervalSize),
  };
};

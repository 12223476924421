import Grid from "@material-ui/core/Grid";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getAdditionItemsForPizzaQuarter } from "../../utils/data";
import {
  getImgSrcForAdditionItem,
  getImgSrcForAdditionItemPizzaQuarterEmpty,
} from "../../utils/imgSrc";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  quarterContainer: {
    position: "relative",
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  quarterButton: {
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: 0,
    width: "100%",
    height: "100%",
    fontSize: "80%",
    [theme.breakpoints.up("sm")]: {
      fontSize: "100%",
    },
    fontWeight: 500,
    lineHeight: "1.25",
    letterSpacing: 0,
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    padding: theme.spacing(2.5),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    "&.notSelected": {
      //fontSize: "110%",
    },
    "& .MuiButton-label": {
      padding: theme.spacing(1),
      background: "rgba(255, 255, 255, 0.9)",
      borderRadius: 10,
      justifyContent: "center",
    },
  },
}));

const getDegForQuarter = (quarter) => {
  switch (quarter) {
    case 0:
      return 90;
    case 1:
      return 180;
    case 2:
      return 0;
    case 3:
      return 270;
    default:
      return 90;
  }
};

const justify = [
  { justifyContent: "flex-end", alignItems: "flex-end", textAlign: "center" },
  { justifyContent: "flex-start", alignItems: "flex-end", textAlign: "center" },
  { justifyContent: "flex-end", alignItems: "flex-start", textAlign: "center" },
  {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "center",
  },
];
const getJustifyForQuarter = (quarter) => {
  return justify[quarter];
};

const ProductItemDialogAdditionGroupPizzaQuarters = (props) => {
  const { onCompleted } = props;

  const classes = useStyles();

  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [quarters, setQuarters] = React.useState([false, false, false, false]);
  const [quarterIndex, setQuarterIndex] = React.useState(0);

  const openMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setQuarterIndex(index);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setQuarterIndex(0);
  };

  const selectItemForQuarter = (additionItem) => {
    const copy = [...quarters];
    copy[quarterIndex] = additionItem;
    setQuarters(copy);
    closeMenu();
  };

  React.useEffect(() => {
    if (quarters.filter((q) => !!q).length === 4) {
      onCompleted(quarters);
    }
  }, [quarters]); //Note: V dependency array nemá být onCompleted - jinak nelze vybrat potěrka po poskládanou pizzu

  return (
    <Box className={classes.container}>
      <Grid container spacing={1}>
        {quarters.map((quarter, index) => {
          return (
            <Grid item xs={6} key={index}>
              <Box className={classes.quarterContainer}>
                <img
                  style={{
                    transform: `rotate(${getDegForQuarter(index)}deg)`,
                  }}
                  src={
                    quarter.imgSrc
                      ? getImgSrcForAdditionItem(quarter)
                      : getImgSrcForAdditionItemPizzaQuarterEmpty()
                  }
                  alt={!quarter ? "Kliknutím vyberte" : quarter.title}
                />
                <Button
                  className={
                    classes.quarterButton + (!quarter ? " notSelected" : "")
                  }
                  style={getJustifyForQuarter(index)}
                  onClick={(event) => {
                    openMenu(event, index);
                  }}
                >
                  {!quarter ? "Kliknutím vyberte" : quarter.title}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {getAdditionItemsForPizzaQuarter(rangeOfGoods)
          .filter((additionItem) => {
            return additionItem.invisible === false;
          })
          .map((additionItem, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  selectItemForQuarter(additionItem);
                }}
              >
                {additionItem.title}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};

export default ProductItemDialogAdditionGroupPizzaQuarters;

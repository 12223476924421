import React from "react";
import { Pizza } from "./Pizza";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
    height: "100vh",
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const LoaderPizza = () => {
  const classes = useStyles();

  React.useEffect(() => {
    let pizza = new Pizza("pizza");

    (function update() {
      requestAnimationFrame(update);
      pizza.update();
    })();
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <img
          src={"/logo.png"}
          alt="Špizza Pizza"
          title="Špizza Pizza"
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <canvas id="pizza" />
      </div>
    </div>
  );
};

export default LoaderPizza;

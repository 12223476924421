import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MuiThemeProvider,
  Paper,
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";

import React from "react";
import { grey, red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { getCommissionStatusesAll } from "../adminEnum";
import { commissionVariantHorizontal } from "../CommissionDetail/CommissionDetail";
import { useDispatch, useSelector } from "react-redux";
import { SET_ADMIN_SELECTED_STATUSES } from "../../../constants/actionTypes";
import { headerHeight } from "../../../constants/constants";
import { canCommissionBeShownInAdmin } from "../../../utils/payment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1, 2),
    borderRadius: 0,
    boxSizing: "border-box",
    position: commissionVariantHorizontal ? "static" : "fixed",
    zIndex: 2,
    width: "100%",
    // borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  row: {
    display: "flex",
    flexDirection: commissionVariantHorizontal ? "column" : "row",
    justifyContent: "center",
    width: "100%",
    "& > div": {
      fontSize: "90%",
      fontFamily: "Roboto !important",
      margin: theme.spacing(0.5),
      width: commissionVariantHorizontal
        ? "100%"
        : 100 / (getCommissionStatusesAll().length + 1) + "%",
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& > .count": {
      textAlign: "center",
    },
  },
  status: {
    boxSizing: "border-box",
    borderRadius: 4,
    padding: theme.spacing(0.5),
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Roboto !important",
    fontWeight: 500,
    letterSpacing: 1,
    "& .button": {
      textTransform: "uppercase",
      fontFamily: "Roboto !important",
      fontWeight: 500,
      letterSpacing: 1,
    },
  },
  count: {
    display: "inline-block",
    minWidth: 24,
    minHeight: 24,
    padding: theme.spacing(0.5),
    boxSizing: "border-box",
    borderRadius: 24,
    textAlign: "center",
    fontFamily: "Roboto !important",
    fontWeight: 900,
  },
}));

const ThemedButton = (props) => {
  const { status, count, isSelected, onClick } = props;

  const theme = createMuiTheme({ palette: { primary: status.color } });

  return (
    <MuiThemeProvider theme={theme}>
      <Button
        className={"button"}
        fullWidth={true}
        color={"primary"}
        size={"small"}
        variant={isSelected ? "contained" : "text"}
        onClick={onClick}
      >
        {status.title} {!!commissionVariantHorizontal && <>({count})</>}
      </Button>
    </MuiThemeProvider>
  );
};

const Count = ({ value, statusCode }) => {
  const classes = useStyles();

  const highlighted =
    value * 1 > 0 && !["cancelled", "closed"].includes(statusCode);

  return (
    <Box
      className={classes.count}
      style={{
        backgroundColor: highlighted ? red[500] : "transparent",
        color: highlighted ? "#ffffff" : grey[400],
      }}
    >
      {value}
    </Box>
  );
};

const CommissionListFilterTable = () =>
  // {
  // commissionStatuses,
  // selectedStatuses,
  // handleSelectStatus,
  // headerHeight,
  // orderBy,
  // orderByOptions,
  // setOrderBy,
  // }
  {
    const classes = useStyles();

    const commissions = useSelector((state) => state.admin.commissions);
    const commissionsKeys = useSelector((state) => state.admin.commissionsKeys);
    const selectedStatuses = useSelector(
      (state) => state.app.adminSelectedStatuses
    );

    const dispatch = useDispatch();

    // const commissionsIds = useSelector((state) => {
    //   return state.admin.commissionsIds;
    // });
    // const commissions = useSelector((state) => state.admin.commissions);
    // const [anchorEl, setAnchorEl] = React.useState(null);

    const getCount = (statusCode, datetime) => {
      let c = 0;
      commissionsKeys
        .filter((v, i, a) => a.indexOf(v) === i)
        .forEach((firebaseKey) => {
          const commission = commissions[firebaseKey];
          if (
            !!commission &&
            commission.activeStatusCode === statusCode &&
            canCommissionBeShownInAdmin(commission)
          ) {
            c++;
          }
        });
      return c;
      // return commissions.filter((cs) => cs.activeStatusCode === statusCode)
      //   .length;
    };

    const handleSelectStatus = (statusCode) => {
      const copy = [...selectedStatuses];
      if (selectedStatuses.includes(statusCode)) {
        dispatch({
          type: SET_ADMIN_SELECTED_STATUSES,
          payload: copy.filter((ss) => ss !== statusCode),
        });
      } else {
        dispatch({
          type: SET_ADMIN_SELECTED_STATUSES,
          payload: copy.concat(statusCode),
        });
      }
    };

    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    // const handleSetOrderBy = (orderBy) => {
    //   setOrderBy(orderBy);
    //   handleClose();
    // };

    return (
      <>
        <Paper
          className={classes.root}
          elevation={4}
          style={{
            height: commissionVariantHorizontal ? "auto" : headerHeight,
          }}
        >
          <Box className={classes.row}>
            {/*<Box style={{ textAlign: "left" }}>*/}
            {/*<Button*/}
            {/*  fullWidth={!!commissionVariantHorizontal}*/}
            {/*  style={{ borderRadius: 4 }}*/}
            {/*  variant={"outlined"}*/}
            {/*  size={"small"}*/}
            {/*  startIcon={<SortIcon />}*/}
            {/*  onClick={handleClick}*/}
            {/*>*/}
            {/*  {orderBy.title}*/}
            {/*</Button>*/}
            {/*<Menu*/}
            {/*  anchorEl={anchorEl}*/}
            {/*  keepMounted*/}
            {/*  open={Boolean(anchorEl)}*/}
            {/*  onClose={handleClose}*/}
            {/*>*/}
            {/*  {orderByOptions.map((orderBy) => {*/}
            {/*    return (*/}
            {/*      <MenuItem*/}
            {/*        key={orderBy.title}*/}
            {/*        onClick={() => {*/}
            {/*          handleSetOrderBy(orderBy);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {orderBy.title}*/}
            {/*      </MenuItem>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</Menu>*/}
            {/*</Box>*/}
            {getCommissionStatusesAll().map((cs) => {
              return (
                <Box key={cs.code}>
                  <ThemedButton
                    status={cs}
                    count={getCount(cs.code, null)}
                    isSelected={selectedStatuses.includes(cs.code)}
                    onClick={() => {
                      handleSelectStatus(cs.code);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
          {!commissionVariantHorizontal && (
            <Box className={classes.row}>
              {/*<Box>*/}
              {/*  <strong>dnes</strong>*/}
              {/*</Box>*/}
              {getCommissionStatusesAll().map((cs) => {
                return (
                  <Box key={cs.code} className={"count"}>
                    <Count
                      value={getCount(cs.code, null)}
                      statusCode={cs.code}
                    />
                  </Box>
                );
              })}
            </Box>
          )}
          {/*{!commissionVariantHorizontal && (*/}
          {/*  <Box className={classes.row}>*/}
          {/*    <Box>*/}
          {/*      <strong>zítra</strong>*/}
          {/*    </Box>*/}
          {/*    {commissionStatuses.map((cs) => {*/}
          {/*      return (*/}
          {/*        <Box key={cs.code} className={"count"}>*/}
          {/*          <Count value={0} />*/}
          {/*        </Box>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Paper>
      </>
    );
  };

export default CommissionListFilterTable;

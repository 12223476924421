import React from "react";
import { baseUrl } from "../constants/constants";
import axios from "axios";
import htmr from "htmr";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      marginBottom: theme.spacing(0),
    },
    "& img": {
      borderRadius: 8 * 4,
      boxShadow: theme.shadows[5],
    },
    "& hr": {
      marginTop: 8 * 4,
      marginBottom: 8 * 4,
      border: "none",
      borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    },
  },
}));

const PageView = ({ pageSlug }) => {
  const classes = useStyles();

  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    const load = () => {
      const url = baseUrl + "/pages/" + pageSlug;
      axios.get(url).then((response) => {
        setContent(response.data.content);
      });
    };
    load();
  }, [pageSlug]);

  if (!content) {
    return <></>;
  }

  return (
    <Container
      style={{ marginTop: 32, marginBottom: 32 }}
      className={classes.root}
    >{htmr(content)}
    </Container>
  );
};

export default PageView;

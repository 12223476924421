import React from "react";

const DebugBarVariable = ({ variable, value, collapsed }) => {
  const variableName = variable.replace("REACT_APP_", "");
  return (
    <div
      style={{
        display: "inline-block",
        marginRight: !collapsed ? 16 : 4,
        marginBottom: !collapsed ? 8 : 4,
      }}
    >
      {!collapsed && (
        <>
          <div style={{ fontSize: 12 }}>{variableName}</div>
        </>
      )}
      <div style={{ color: "#ffffff", fontSize: 14 }}>{value}</div>
    </div>
  );
};

const DebugBar = () => {
  const [collapsed, setCollapsed] = React.useState(true);

  let variables = () => {
    let variables = [];
    for (let variable in process.env) {
      if (variable.startsWith("REACT_APP_")) {
        variables.push(variable);
      }
    }
    return variables;
  };

  const variablesArray = collapsed ? [variables()[0]] : variables();

  if (process.env.REACT_APP_SHOW_DEBUGBAR === "false") {
    return <></>;
  }

  return (
    <div
      onClick={() => {
        setCollapsed(!collapsed);
      }}
      style={{
        width: collapsed ? "auto" : "100%",
        borderTopLeftRadius: collapsed ? 10 : 0,
        background: "#ff0000",
        color: "#000000",
        paddingLeft: collapsed ? 8 : 4,
        paddingTop: 4,
        paddingBottom: 0,
        boxSizing: "border-box",
        position: "fixed",
        bottom: 0,
        right: 0,
        fontFamily: "monospace !important",
        cursor: "pointer",
        textAlign: "left",
      }}
    >
      {variablesArray.map((variable, index) => {
        return (
          <DebugBarVariable
            key={index}
            variable={variable}
            value={process.env[variable]}
            collapsed={collapsed}
          />
        );
      })}
    </div>
  );
};

export default DebugBar;

import { Box, Button, Container, makeStyles } from "@material-ui/core";

import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckoutDoneFailure from "../components/CheckoutDone/CheckoutDoneFailure";
import { KIOSK_ENABLED } from "../constants/constants";
import HomeSwiper from "../components/HomeSwiper/HomeSwiper";

const useStyles = makeStyles((theme) => ({
  topActions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  buttonBack: {
    textDecoration: "none",
  },
}));

const CheckoutDoneFailureView = () => {
  const classes = useStyles();

  const style = () => {
    if (KIOSK_ENABLED) {
      return {
        display: "flex",
        height: "100%",
        alignItems: "center",
      };
    }
    return {};
  };

  const styleOuter = () => {
    if (KIOSK_ENABLED) {
      return {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      };
    }
    return {
      display: "flex",
      flexDirection: "column",
    };
  };

  return (
    <Box style={styleOuter()}>
      {KIOSK_ENABLED && (
        <Box>
          <HomeSwiper
            initialSliders={["https://files.spizza.cz/slide-rozvoz_3.png"]}
          />
        </Box>
      )}
      <Box style={style()}>
        <Container>
          <Box className={classes.topActions}>
            <Link to={"/checkout"} className={classes.buttonBack}>
              <Button
                variant={"contained"}
                color={"secondary"}
                startIcon={<ArrowBackIcon />}
              >
                Zpět k pokladně
              </Button>
            </Link>
          </Box>
          <CheckoutDoneFailure />
        </Container>
      </Box>
    </Box>
  );
};

export default CheckoutDoneFailureView;

export const posVyskovKiosek = "Vyškov - kiosek: Vyškov: žádná zóna";
export const posVyskovStanek = "Vyškov - stánek: Vyškov: žádná zóna";
export const posVyskovRozvoz1 = "Vyškov - rozvoz: Vyškov: I. zóna";
export const posVyskovRozvoz2 = "Vyškov - rozvoz: Vyškov: II. zóna";
export const posVyskovRozvoz3 = "Vyškov - rozvoz: Vyškov: III. zóna";
export const posUherskyBrodStanek =
  "Uherský Brod - stánek: Uherský Brod: žádná zóna";

export const pointOfSaleTitle = {
  [posVyskovKiosek]: "Kiosek",
  [posVyskovStanek]: "Bistro",
  [posVyskovRozvoz1]: "Rozvoz 1",
  [posVyskovRozvoz2]: "Rozvoz 2",
  [posVyskovRozvoz3]: "Rozvoz 3",
  [posUherskyBrodStanek]: "Stánek",
};

export const pointOfSaleTitleForNewCommission = {
  [posVyskovKiosek]: "Pult",
  [posVyskovStanek]: "Telefon - osobní převzetí",
  [posVyskovRozvoz1]: "Telefon - rozvoz 1",
  [posVyskovRozvoz2]: "Telefon - rozvoz 2",
  [posVyskovRozvoz3]: "Telefon - rozvoz 3",
  [posUherskyBrodStanek]: "Stánek",
};

export const getAllPointOfSales = () => {
  return [
    posVyskovKiosek,
    posVyskovStanek,
    posVyskovRozvoz1,
    posVyskovRozvoz2,
    posVyskovRozvoz3,
    posUherskyBrodStanek,
  ];
};

export const getAllPointOfSalesOnlyDelivery = () => {
  return [posVyskovRozvoz1, posVyskovRozvoz2, posVyskovRozvoz3];
};

export const getAllPointOfSalesOnlyDotykacka = () => {
  return [
    posVyskovRozvoz1,
    posVyskovRozvoz2,
    posVyskovRozvoz3,
    posVyskovKiosek,
    posVyskovStanek,
  ];
};

export const localBusinesses = [
  { slug: "vyskov", title: "Vyškov" },
  { slug: "uhersky-brod", title: "Uherský Brod" },
];

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CommissionDetail from "../CommissionDetail/CommissionDetail";

const number = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const CommissionReportFilter = (props) => {
  const { commissions, enums, loading } = props;

  const [filter, setFilter] = React.useState(() => {
    const f = {};
    Object.keys(enums).forEach((key) => {
      f[key] = Object.keys(enums[key].items).map((itemKey) => {
        return itemKey;
      });
    });
    return f;
  });

  const [commission, setCommission] = React.useState(null);

  const handleToggleCheck = (event) => {
    const { target } = event;
    const { name, value } = target;

    let copy = [...filter[name]];
    if (copy.includes(value)) {
      copy = copy.filter((c) => {
        return c !== value;
      });
    } else {
      copy = copy.concat(value);
    }

    setFilter({ ...filter, [name]: copy });
  };

  const columns = {
    numberPosDay: "Číslo",
    commissionTakeOverDateTimeStamp: "Převzetí",
    pointOfSaleId: "Obchodní místo",
    commissionOrigin: "Zdroj",
    activeStatusCode: "Stav",
    commissionPaymentMethod: "Platba",
    csobPayStatus: "Stav platby",
    csobPayId: "Platba ID",
    dotykackaStatusCode: "Stav v Dotykačce",
    dotykackaOrderId: "Dotykačka ID",
    humanPriceSubtotal: "Mezisoučet",
    humanPriceDelivery: "Doprava",
    humanPriceTotal: "Celkem",
  };

  const rows = commissions.filter((c) => {
    return (
      (filter.pointOfSaleId.includes(c.pointOfSaleId) || !c.pointOfSaleId) &&
      (filter.commissionOrigin.includes(c.commissionOrigin) ||
        !c.commissionOrigin) &&
      (filter.activeStatusCode.includes(c.activeStatusCode) ||
        !c.activeStatusCode) &&
      (filter.commissionPaymentMethod.includes(c.commissionPaymentMethod) ||
        !c.commissionPaymentMethod) &&
      (filter.dotykackaStatusCode.includes(c.dotykackaStatusCode || "-1") ||
        !c.dotykackaStatusCode)
    );
  });

  const count = (columnKey, value) => {
    return commissions.filter((c) => {
      return (c[columnKey] || "-1") === value;
    }).length;
  };

  const total = (columnKey) =>
    rows.reduce((prev, current) => {
      return prev + current[columnKey];
    }, 0);

  const exportToCSV = () => {
    const csvData = [];

    rows.forEach((row) => {
      const r = {};
      Object.keys(columns).forEach((columnKey) => {
        const column = columns[columnKey];
        let value = row[columnKey];
        if (
          !!value &&
          enums.hasOwnProperty(columnKey) &&
          enums[columnKey].items.hasOwnProperty(value)
        ) {
          value = enums[columnKey].items[value];
        }
        if (columnKey === "commissionTakeOverDateTimeStamp") {
          const dt = new Date(value);
          value = [dt.getDate(), dt.getMonth() + 1, dt.getFullYear()].join(".");
        }
        r[column] = value;
      });
      csvData.push(r);
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "spizzaExport" + fileExtension);
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        fullWidth={true}
        open={!!commission}
        onClose={() => {
          setCommission(null);
        }}
      >
        <DialogContent>
          <CommissionDetail
            commission={commission}
            handleChangeCommission={() => {
              alert("Zde nelze měnit objednávku.");
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCommission(null);
            }}
            variant={"text"}
          >
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2}>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              {Object.keys(columns).map((columnKey) => {
                const column = columns[columnKey];
                return <TableCell>{column}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.keys(columns).map((columnKey) => {
                if (enums.hasOwnProperty(columnKey)) {
                  const en = enums[columnKey];
                  return (
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <FormGroup>
                        {Object.keys(en.items).map((itemKey) => {
                          const item = en.items[itemKey];
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filter[columnKey].includes(itemKey)}
                                  name={columnKey}
                                  value={itemKey}
                                  onChange={handleToggleCheck}
                                  size={"small"}
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>
                                  {item}{" "}
                                  <small>({count(columnKey, itemKey)})</small>
                                </span>
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    </TableCell>
                  );
                }
                return <TableCell />;
              })}
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <strong>
                  Počet: {rows.length} ze {commissions.length}{" "}
                  {commissions.length === 2000 && (
                    <> !! Dosaženo maxima! Je potřeba zmenšit časový úsek! !!</>
                  )}
                </strong>
              </TableCell>
              <TableCell
                colSpan={Object.keys(columns).length - 6}
                align={"right"}
              >
                <strong>Suma:</strong>
              </TableCell>
              {[
                "humanPriceSubtotal",
                "humanPriceDelivery",
                "humanPriceTotal",
              ].map((columnKey) => {
                return (
                  <TableCell align={"right"} style={{ whiteSpace: "nowrap" }}>
                    <strong>{number(total(columnKey))}</strong>
                  </TableCell>
                );
              })}
            </TableRow>
            {loading && (
              <TableRow>
                <TableCell colSpan={Object.keys(columns).length}>
                  Generuji řádky tabulky pro zvolený časový úsek&hellip;
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              rows.map((row) => {
                return (
                  <TableRow key={row.number}>
                    {Object.keys(columns).map((columnKey) => {
                      let value = row[columnKey];
                      if (
                        !!value &&
                        enums.hasOwnProperty(columnKey) &&
                        enums[columnKey].items.hasOwnProperty(value)
                      ) {
                        value = enums[columnKey].items[value];
                      }
                      if (columnKey === "numberPosDay") {
                        value = (
                          <Button
                            onClick={() => {
                              setCommission(row);
                            }}
                            variant={"text"}
                          >
                            {value}
                          </Button>
                        );
                      }
                      if (columnKey === "commissionTakeOverDateTimeStamp") {
                        const dt = new Date(value);
                        value = [
                          dt.getDate(),
                          dt.getMonth() + 1,
                          dt.getFullYear(),
                        ].join(".");
                      }
                      if (
                        [
                          "humanPriceSubtotal",
                          "humanPriceDelivery",
                          "humanPriceTotal",
                        ].includes(columnKey)
                      ) {
                        value = number(value);
                      }
                      return (
                        <TableCell
                          align={
                            [
                              "humanPriceSubtotal",
                              "humanPriceDelivery",
                              "humanPriceTotal",
                            ].includes(columnKey)
                              ? "right"
                              : "left"
                          }
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell>
                <strong>
                  Počet: {rows.length} ze {commissions.length}{" "}
                  {commissions.length === 2000 && (
                    <> !! Dosaženo maxima! Je potřeba zmenšit časový úsek! !!</>
                  )}
                </strong>
              </TableCell>
              <TableCell
                colSpan={Object.keys(columns).length - 4}
                align={"right"}
              >
                <strong>Suma:</strong>
              </TableCell>
              {[
                "humanPriceSubtotal",
                "humanPriceDelivery",
                "humanPriceTotal",
              ].map((columnKey) => {
                return (
                  <TableCell align={"right"} style={{ whiteSpace: "nowrap" }}>
                    <strong>{number(total(columnKey))}</strong>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>

        <Box p={2}>
          <Button
            onClick={() => {
              exportToCSV();
            }}
            variant={"contained"}
            color={"secondary"}
          >
            Exportovat tabulku do Excelu
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CommissionReportFilter;

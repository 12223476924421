import { Box, Button, Container, makeStyles } from "@material-ui/core";

import React from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { baseUrl, KIOSK_ENABLED } from "../constants/constants";
import CheckoutDone from "../components/CheckoutDone/CheckoutDone";
import HomeSwiper from "../components/HomeSwiper/HomeSwiper";

const useStyles = makeStyles((theme) => ({
  topActions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  buttonBack: {
    textDecoration: "none",
  },
}));

const CheckoutDoneView = () => {
  const classes = useStyles();

  const { commissionUid } = useParams();

  const [commission, setCommission] = React.useState(null);

  React.useEffect(() => {
    const load = () => {
      const url = baseUrl + "/commission/" + commissionUid;
      axios.get(url).then((response) => {
        setCommission(response.data.commission.content);
      });
    };
    load();
  }, [commissionUid]);

  const style = () => {
    if (KIOSK_ENABLED) {
      return {
        display: "flex",
        height: "100%",
        alignItems: "center",
      };
    }
    return {};
  };

  const styleOuter = () => {
    if (KIOSK_ENABLED) {
      return {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      };
    }
    return {
      display: "flex",
      flexDirection: "column",
    };
  };

  if (!commission) {
    return <></>;
  }

  return (
    <Box style={styleOuter()}>
      {KIOSK_ENABLED && (
        <Box>
          <HomeSwiper
            initialSliders={["https://files.spizza.cz/slide-rozvoz_3.png"]}
          />
        </Box>
      )}
      <Box style={style()}>
        <Container>
          <Box className={classes.topActions}>
            <Link to={"/"} className={classes.buttonBack}>
              <Button
                variant={"contained"}
                color={"secondary"}
                startIcon={<ArrowBackIcon />}
              >
                Zpět na hlavní stránku
              </Button>
            </Link>
          </Box>
          <CheckoutDone commission={commission} />
        </Container>
      </Box>
    </Box>
  );
};

export default CheckoutDoneView;

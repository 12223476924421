import React from "react";
import CommissionList from "../../components/Admin/CommissionList/CommissionList";
import { useSelector } from "react-redux";

const CommissionListAdminView = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);

  return <CommissionList localBusiness={localBusiness} />;
};

export default React.memo(CommissionListAdminView);

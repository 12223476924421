import {
  APP_LOADING,
  FETCH_SETTINGS_INIT,
  FETCH_SETTINGS_SUCCESS,
} from "../constants/actionTypes";
import { apiGetSettings } from "../api/endpoints";
import axios from "axios";

export const fetchSettings = () => {
  return (dispatch) => {
    dispatch({ type: APP_LOADING, payload: true });
    dispatch({ type: FETCH_SETTINGS_INIT });
    axios.get(apiGetSettings()).then((response) => {
      dispatch({ type: FETCH_SETTINGS_SUCCESS, payload: response.data });
      dispatch({ type: APP_LOADING, payload: false });
    });
  };
};

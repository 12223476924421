import {
  convertHMToHoursMinutes,
  convertHoursMinutesToHM,
  getDayOfWeek,
} from "./dateTimeHelper";
import { getCartPriceSubtotalForCapacityCalculation } from "../utils/price";
import {
  getAllPointOfSalesOnlyDelivery,
  pointOfSaleTitle,
  posVyskovKiosek,
  posVyskovRozvoz1,
  posVyskovRozvoz2,
  posVyskovRozvoz3,
  posVyskovStanek,
} from "../constants/pointOfSales";
import {
  defaultCapacityDefinitionMASO,
  defaultCapacityDefinitionPIZZA,
  defaultCapacityDefinitionROZVOZ,
  defaultPricePizzaRodinna,
  intervalSize,
  sourceMASO,
  sourcePIZZA,
  sourceROZVOZ,
} from "../components/DateTimePicker/logicConstants";
import {
  commissionOriginCounter,
  commissionOriginInternet,
  commissionOriginKiosk,
  commissionOriginTelephone,
  days,
  vosSectionMeat,
  vosSectionPizza,
} from "../constants/constants";
import {
  cancelledStatusCode,
  closedStatusCode,
} from "../components/Admin/adminEnum";
import { SOURCE_MASO, SOURCE_PIZZA } from "../components/Admin/Capacity/utils";

export const getCommissionNumberLb = (commission) => {
  let letter = "";
  if (commission.hasOwnProperty("commissionOrigin")) {
    switch (commission.commissionOrigin) {
      case commissionOriginInternet:
        {
          letter = "E";
        }
        break;
      case commissionOriginTelephone:
        {
          letter = "T";
        }
        break;
      case commissionOriginCounter:
        {
          letter = "P";
        }
        break;
      case commissionOriginKiosk:
        {
          letter = "P";
        }
        break;
      default:
        {
          letter = "";
        }
        break;
    }
  }
  return letter + commission.numberPosDay;
};

export const buildCapacityDefinition = (
  localBusiness,
  source,
  dayOfWeek,
  hours,
  minutes
) => {
  return {
    localBusiness,
    source,
    dayOfWeek,
    hours,
    minutes,
    value:
      source === sourcePIZZA
        ? defaultCapacityDefinitionPIZZA
        : source === sourceMASO
        ? defaultCapacityDefinitionMASO
        : defaultCapacityDefinitionROZVOZ,
  };
};

export const buildCapacity = (
  localBusinessTitle,
  source,
  date,
  hours,
  minutes
) => {
  return {
    localBusinessTitle,
    source,
    date: {
      date: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      dayOfWeek: getDayOfWeek(date),
    },
    hours: hours,
    minutes: minutes,
    value: 0,
    commissions: [],
  };
};

export const getCapacityDefinition = (
  localBusinessTitle,
  source,
  dayOfWeek,
  hours,
  minutes,
  data
) => {
  // console.log(data.capacities);
  let capacityDefinition = data.capacities.find(
    (c) =>
      c.localBusiness === localBusinessTitle &&
      c.source === source &&
      c.dayOfWeek === dayOfWeek &&
      c.hours === hours &&
      c.minutes === minutes
  );
  if (!capacityDefinition) {
    capacityDefinition = buildCapacityDefinition(
      localBusinessTitle,
      source,
      dayOfWeek,
      hours,
      minutes
    );
  }
  return capacityDefinition;
};

const hasDelivery = (commission) =>
  getAllPointOfSalesOnlyDelivery().includes(commission.pointOfSaleId);

const getActualHM = (commission, commissionTakeOverDateTime, source) => {
  let actualHM = convertHoursMinutesToHM({
    hours: commissionTakeOverDateTime.getHours(),
    minutes: commissionTakeOverDateTime.getMinutes(),
  });

  if (commission.hasOwnProperty("internal") && commission.internal) {
    if (commission.source === source) {
      if (source === SOURCE_PIZZA && commission.type === "pult") {
        return (
          actualHM +
          Math.ceil(
            (defaultPricePizzaRodinna * commission.value) /
              defaultCapacityDefinitionPIZZA
          ) *
            intervalSize -
          intervalSize
        );
      }
      if (source === SOURCE_PIZZA && commission.type === "pauza") {
        return (
          actualHM +
          Math.ceil(commission.value / intervalSize) * intervalSize -
          intervalSize
        );
      }
      if (source === SOURCE_MASO && commission.type === "pauza") {
        return (
          actualHM +
          Math.ceil(commission.value / intervalSize) * intervalSize -
          intervalSize
        );
      }
    }
    return 0;
  }

  if (source === sourceROZVOZ) {
    if (commission.pointOfSaleId === posVyskovStanek) {
      actualHM = actualHM + intervalSize * 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
    }
    if (commission.pointOfSaleId === posVyskovKiosek) {
      actualHM = actualHM + intervalSize * 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
    }
    if (commission.pointOfSaleId === posVyskovRozvoz1) {
      actualHM = actualHM + intervalSize * 1; //rozvoz do 1. zóny zabere 1*15 minut
    }
    if (commission.pointOfSaleId === posVyskovRozvoz2) {
      actualHM = actualHM + intervalSize * 1; //rozvoz do 2. zóny zabere 1*15 minut
    }
    if (commission.pointOfSaleId === posVyskovRozvoz3) {
      actualHM = actualHM + intervalSize * 2; //rozvoz do 3. zóny zabere 2*15 minut
    }
  }

  actualHM = actualHM - intervalSize;

  return actualHM;
};

export const getActualPointsDifficulty = (commission, source) => {
  if (commission.hasOwnProperty("internal") && commission.internal) {
    if (commission.source === source) {
      if (source === SOURCE_PIZZA && commission.type === "pult") {
        return defaultPricePizzaRodinna * commission.value;
      }
      if (source === SOURCE_PIZZA && commission.type === "pauza") {
        return Math.round(
          (defaultCapacityDefinitionPIZZA / intervalSize) * commission.value
        );
      }
      if (source === SOURCE_MASO && commission.type === "pauza") {
        return Math.round(
          (defaultCapacityDefinitionMASO / intervalSize) * commission.value
        );
      }
    }
    return 0;
  }

  if (source === sourcePIZZA) {
    return getCartPriceSubtotalForCapacityCalculation(
      commission,
      vosSectionPizza
    );
  }
  if (source === sourceMASO) {
    return getCartPriceSubtotalForCapacityCalculation(
      commission,
      vosSectionMeat
    );
  }
  if (source === sourceROZVOZ) {
    if (commission.pointOfSaleId === posVyskovStanek) {
      return 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
    }
    if (commission.pointOfSaleId === posVyskovKiosek) {
      return 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
    }
    if (commission.pointOfSaleId === posVyskovRozvoz1) {
      return 1; //rozvoz do 1. zóny zabere 1*15 minut
    }
    if (commission.pointOfSaleId === posVyskovRozvoz2) {
      return 1; //rozvoz do 2. zóny zabere 1*15 minut
    }
    if (commission.pointOfSaleId === posVyskovRozvoz3) {
      return 2; //rozvoz do 3. zóny zabere 2*15 minut
    }
  }
};

export const getPointsDifficultyAdditionForROZVOZ = (commission) => {
  if (commission.pointOfSaleId === posVyskovStanek) {
    return 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
  }
  if (commission.pointOfSaleId === posVyskovKiosek) {
    return 0; //objednávka bez rozvozu nezatíží zdroj rozvoz
  }
  if (commission.pointOfSaleId === posVyskovRozvoz1) {
    return 1; //rozvoz do 1. zóny zabere v každém intervalu 1 jednotku
  }
  if (commission.pointOfSaleId === posVyskovRozvoz2) {
    return 2; //rozvoz do 2. zóny zabere v každém intervalu 2 jednotky
  }
  if (commission.pointOfSaleId === posVyskovRozvoz3) {
    return 1; //rozvoz do 3. zóny zabere v každém intervalu 1 jednotku
  }
  return 0;
};

export const getCapacities = (localBusinessTitle, date, data) => {
  let capacities = [];

  const internalCommissions = data.internalCommissions.map((ic) => {
    return {
      ...ic,
      commissionTakeOverDateTimeStamp: ic.startAt,
    };
  });

  for (let commission of [...data.commissions, ...internalCommissions]) {
    let commissionTakeOverDateTime = new Date();
    commissionTakeOverDateTime.setTime(
      commission.commissionTakeOverDateTimeStamp
    ); //zde se používá timestamp

    if (
      !(
        commissionTakeOverDateTime.getDate() === date.getDate() &&
        commissionTakeOverDateTime.getMonth() === date.getMonth() &&
        commissionTakeOverDateTime.getFullYear() === date.getFullYear()
      )
    ) {
      continue;
    }

    //výpočet pro zdroj PIZZA
    let actualHM = getActualHM(
      commission,
      commissionTakeOverDateTime,
      sourcePIZZA
    );

    let actualPointsDifficulty = getActualPointsDifficulty(
      commission,
      sourcePIZZA
    );

    do {
      const res = convertHMToHoursMinutes(actualHM);
      const cHours = res.hours;
      const cMinutes = res.minutes;
      const capacityDefinition = getCapacityDefinition(
        localBusinessTitle,
        sourcePIZZA,
        getDayOfWeek(commissionTakeOverDateTime),
        cHours,
        cMinutes,
        data
      );
      let capacity = capacities.find(
        (c) =>
          c.localBusinessTitle === localBusinessTitle &&
          c.source === sourcePIZZA &&
          c.date.date === date.getDate() &&
          c.date.month === date.getMonth() &&
          c.date.year === date.getFullYear() &&
          c.hours === cHours &&
          c.minutes === cMinutes
      );
      if (!capacity || !capacity.hasOwnProperty("value")) {
        capacity = buildCapacity(
          localBusinessTitle,
          sourcePIZZA,
          date,
          cHours,
          cMinutes
        );
        capacities.push(capacity);
      }

      let pointsDifficultyAddition = Math.min(
        capacityDefinition.value,
        actualPointsDifficulty
      );

      capacity.value = capacity.value + pointsDifficultyAddition;

      capacity.capacityDefinition = { ...capacityDefinition };
      const commissionCopy = { ...commission };
      commissionCopy.pointsDifficultyAddition = pointsDifficultyAddition;
      capacity.commissions.push(commissionCopy);

      actualPointsDifficulty =
        actualPointsDifficulty - pointsDifficultyAddition;
      actualHM = actualHM - intervalSize;
    } while (actualPointsDifficulty > 0);

    //výpočet pro zdroj MASO
    actualHM = getActualHM(commission, commissionTakeOverDateTime, sourceMASO);

    actualPointsDifficulty = getActualPointsDifficulty(commission, sourceMASO);

    do {
      const res = convertHMToHoursMinutes(actualHM);
      const cHours = res.hours;
      const cMinutes = res.minutes;
      const capacityDefinition = getCapacityDefinition(
        localBusinessTitle,
        sourceMASO,
        getDayOfWeek(commissionTakeOverDateTime),
        cHours,
        cMinutes,
        data
      );
      let capacity = capacities.find(
        (c) =>
          c.localBusinessTitle === localBusinessTitle &&
          c.source === sourceMASO &&
          c.date.date === date.getDate() &&
          c.date.month === date.getMonth() &&
          c.date.year === date.getFullYear() &&
          c.hours === cHours &&
          c.minutes === cMinutes
      );
      if (!capacity || !capacity.hasOwnProperty("value")) {
        capacity = buildCapacity(
          localBusinessTitle,
          sourceMASO,
          date,
          cHours,
          cMinutes
        );
        capacities.push(capacity);
      }

      let pointsDifficultyAddition = Math.min(
        capacityDefinition.value,
        actualPointsDifficulty
      );

      capacity.value = capacity.value + pointsDifficultyAddition;

      capacity.capacityDefinition = { ...capacityDefinition };
      const commissionCopy = { ...commission };
      commissionCopy.pointsDifficultyAddition = pointsDifficultyAddition;
      capacity.commissions.push(commissionCopy);

      actualPointsDifficulty =
        actualPointsDifficulty - pointsDifficultyAddition;
      actualHM = actualHM - intervalSize;
    } while (actualPointsDifficulty > 0);

    //výpočet pro zdroj ROZVOZ
    if (hasDelivery(commission)) {
      actualHM = getActualHM(
        commission,
        commissionTakeOverDateTime,
        sourceROZVOZ
      );
      actualPointsDifficulty = getActualPointsDifficulty(
        commission,
        sourceROZVOZ
      );

      do {
        const res = convertHMToHoursMinutes(actualHM);
        const cHours = res.hours;
        const cMinutes = res.minutes;
        const capacityDefinition = getCapacityDefinition(
          localBusinessTitle,
          sourceROZVOZ,
          getDayOfWeek(commissionTakeOverDateTime),
          cHours,
          cMinutes,
          data
        );
        let capacity = capacities.find(
          (c) =>
            c.localBusinessTitle === localBusinessTitle &&
            c.source === sourceROZVOZ &&
            c.date.date === date.getDate() &&
            c.date.month === date.getMonth() &&
            c.date.year === date.getFullYear() &&
            c.hours === cHours &&
            c.minutes === cMinutes
        );
        if (!capacity || !capacity.hasOwnProperty("value")) {
          capacity = buildCapacity(
            localBusinessTitle,
            sourceROZVOZ,
            date,
            cHours,
            cMinutes
          );
          capacities.push(capacity);
        }

        let pointsDifficultyAddition =
          getPointsDifficultyAdditionForROZVOZ(commission);

        capacity.value = capacity.value + pointsDifficultyAddition;

        capacity.capacityDefinition = { ...capacityDefinition };
        const commissionCopy = { ...commission };
        commissionCopy.pointsDifficultyAddition = pointsDifficultyAddition;
        capacity.commissions.push(commissionCopy);

        actualPointsDifficulty = actualPointsDifficulty - 1;
        actualHM = actualHM - intervalSize;
      } while (actualPointsDifficulty > 0);
    }
  }

  return capacities;
};

export const getCapacity = (
  localBusinessTitle,
  source,
  date,
  hours,
  minutes,
  data
) => {
  const capacities = getCapacities(localBusinessTitle, date, data);
  let capacity = capacities.find(
    (c) =>
      c.localBusinessTitle === localBusinessTitle &&
      c.source === source &&
      c.date.date === date.getDate() &&
      c.date.month === date.getMonth() &&
      c.date.year === date.getFullYear() &&
      c.hours === hours &&
      c.minutes === minutes
  );
  if (!capacity || !capacity.hasOwnProperty("value")) {
    capacity = buildCapacity(localBusinessTitle, source, date, hours, minutes);
  }
  return capacity;
};

export const filterCommissionsForCapacity = (
  commissions,
  localBusinessTitle,
  date
) => {
  return commissions.filter((c) => {
    // console.log(c);
    let cDate = new Date(c.commissionTakeOverDateTimeStamp * 1);
    return (
      (!c.hasOwnProperty("capacityCheck") || c.capacityCheck === true) && //do kapacit se počítají jenom objednávky, u kterých je povoleno, aby se počítaly do kapacit
      cDate.getDate() === date.getDate() && //do kapacity se počítají objednávky v rámci stejného dne, měsíce a roku
      cDate.getMonth() === date.getMonth() &&
      cDate.getFullYear() === date.getFullYear() &&
      c.activeStatusCode !== cancelledStatusCode && //do kapacity se nepočítají stornované objednávky
      c.activeStatusCode !== closedStatusCode && //do kapacity se nepočítají předané objednávky
      c.localBusiness === localBusinessTitle //do kapacity se počítají pouze ty objednávky, které vyrábí stejná provozovna
    );
  });
};

export const getDailyOperatingHoursForDayOfWeekAndPointOfSale = (
  dayOfWeek,
  pointOfSale
) => {
  const [from, to] = pointOfSale["hours_" + dayOfWeek]
    .split("-")
    .map((timeString) => {
      const [h, m] = timeString
        .trim()
        .split(":")
        .map((value) => {
          return value.trim() * 1;
        });
      const dt = new Date();
      dt.setHours(h, m, 0, 0);
      return dt;
    });

  return {
    from: {
      dateTime: from,
      hours: from.getHours(),
      minutes: from.getMinutes(),
    },
    to: {
      dateTime: to,
      hours: to.getHours(),
      minutes: to.getMinutes(),
    },
  };
};

export const getDailyOperatingHoursForPointOfSales = (pointOfSales) => {
  const list = [];
  for (let pointOfSale of pointOfSales) {
    for (let day of days) {
      let dailyOperatingHours =
        getDailyOperatingHoursForDayOfWeekAndPointOfSale(
          day.index === 0 ? 7 : day.index,
          pointOfSale
        );
      list.push({
        dayOfWeek: day.index,
        pointOfSale: pointOfSale,
        operatingHours: dailyOperatingHours,
      });
    }
  }
  return list;
};

// export const getDailyOperatingHoursForDateAndPointOfSale = (
//   date,
//   pointOfSale
// ) => {
//   const dayOfWeek = getDayOfWeek(date);
//
//   const [from, to] = pointOfSale["hours_" + dayOfWeek]
//     .split("-")
//     .map((timeString) => {
//       const [h, m] = timeString
//         .trim()
//         .split(0)
//         .map((value) => {
//           return value.trim() * 1;
//         });
//       const dt = new Date(date);
//       dt.setHours(h, m, 0, 0);
//       return dt;
//     });
//
//   return {
//     from,
//     to,
//   };
// };

import { days } from "../../../../constants/constants";

export const ACTION_TYPE_CHANGE = "ACTION_TYPE_CHANGE";

const initialState = {
  checked: [],
  dayOfWeek: days.find((d) => d.index === new Date().getDay()),
  batchPIZZA: -1,
  batchMASO: -1,
  batchROZVOZ: -1,
  config: {
    pointOfSales: [],
    hours: [],
    minutes: [],
  },
  capacities: [],
  commissions: [],
  selectedCommissionId: "",
};

const capacityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_CHANGE: {
      return { ...state, [action.payload.name]: action.payload.value };
    }
    default: {
      return state;
    }
  }
};

export default capacityReducer;

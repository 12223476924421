import { pizza30s, product2Plus1Gratis } from "../../constants/constants";

export const getCountOfPizza30InCart = (cart) => {
  let count = 0;
  for (let i = 0; i < cart.items.length; i++) {
    let cartItem = cart.items[i];
    if (pizza30s.includes(cartItem.product.subtitle.toLowerCase())) {
      count = count + cartItem.quantity;
    }
  }
  return count;
};

export const getCountOfPizza30FreeInCart = (cart) => {
  let count = 0;
  for (let i = 0; i < cart.items.length; i++) {
    let cartItem = cart.items[i];
    if (is2Plus1Gratis(cartItem)) {
      count = count + cartItem.quantity;
    }
  }
  return count;
};

export const is2Plus1Gratis = (cartItem) => {
  return cartItem.product.fullTitle === product2Plus1Gratis;
};

import { Box, makeStyles } from "@material-ui/core";

import React from "react";
import { commissionVariantHorizontal } from "../CommissionDetail/CommissionDetail";
import {
  firebaseDb,
  firebaseDbPathCommission,
} from "../../../service/firebaseConfig";
import CommissionListFilterTable from "./CommissionListFilterTable";
import { useDispatch, useSelector } from "react-redux";
import CommissionDetailInFirebase from "../CommissionDetail/CommissionDetailInFirebase";
import { headerHeight } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  loading: {
    background: "#ffffff",
    height: "100vh",
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    paddingTop: commissionVariantHorizontal
      ? 0
      : headerHeight + theme.spacing(1),
    boxSizing: "border-box",
    backgroundColor: "#000000",
  },
}));

const orderByOptions = [
  {
    title: "Datum a čas převzetí",
    sortFn: (a, b) => {
      const aX = a.commissionTakeOverDateTimeStamp;
      const bX = b.commissionTakeOverDateTimeStamp;
      if (aX === bX) return 0;
      if (aX < bX) return -1; //vzestupně
      if (aX > bX) return 1;
      return 0;
    },
  },
];

const getStartAt = () => {
  let startAt = new Date();
  startAt.setHours(0, 0, 0, 0);
  startAt = startAt.getTime();

  return startAt;
};

const getEndAt = (days = 2) => {
  let endAt = new Date();
  endAt.setDate(endAt.getDate() + days);
  endAt.setHours(23, 59, 59, 0);
  endAt = endAt.getTime();

  return endAt;
};

const CommissionList = (props) => {
  const { localBusiness } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const commissions = useSelector((state) => state.admin.commissions);
  const commissionsKeys = useSelector((state) => state.admin.commissionsKeys);
  const selectedStatuses = useSelector(
    (state) => state.app.adminSelectedStatuses
  );

  const [loading, setLoading] = React.useState(true);

  const initLoad = async () => {
    setLoading(true);
    const commissions = {};
    await firebaseDb
      .ref(firebaseDbPathCommission)
      .orderByChild("commissionTakeOverDateTimeStamp")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          const firebaseKey = snap.key;
          const commission = snap.val();
          if (commission.localBusiness === localBusiness.title) {
            commissions[firebaseKey] = commission;
          }
        });
      });
    dispatch({
      type: "ADMIN_COMMISSIONS_SET",
      payload: {
        commissions,
      },
    });
    setLoading(false);
  };

  const initObserver = async () => {
    firebaseDb
      .ref(firebaseDbPathCommission)
      .orderByChild("commissionTakeOverDateTimeStamp")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .on("child_added", (snapshot) => {
        const firebaseKey = snapshot.key;
        const commission = snapshot.val();
        if (commission.localBusiness === localBusiness.title) {
          if (!commissionsKeys.includes(firebaseKey)) {
            dispatch({
              type: "ADMIN_COMMISSIONS_ADD",
              payload: {
                firebaseKey,
                commission,
              },
            });
          }
        }
      });
  };

  React.useEffect(() => {
    initLoad();
  }, []);

  React.useEffect(() => {
    if (loading === false) {
      initObserver();
    }
  }, [loading]);

  if (loading) {
    return <Box className={classes.loading}>Nahrávám objednávky...</Box>;
  }
  if (!loading && commissions.length === 0) {
    return <Box className={classes.loading}>Žádné objednávky...</Box>;
  }

  return (
    <>
      <Box>
        <CommissionListFilterTable />
      </Box>
      <Box className={classes.container}>
        {commissionsKeys
          .filter((v, i, a) => a.indexOf(v) === i)
          .filter((key) => {
            const commission = commissions[key];
            return selectedStatuses.includes(commission.activeStatusCode);
          })
          .sort((a, b) => {
            return (
              commissions[a].commissionTakeOverDateTimeStamp -
              commissions[b].commissionTakeOverDateTimeStamp
            );
          })
          .map((key, index) => {
            return <CommissionDetailInFirebase key={key} firebaseKey={key} />;
          })}
      </Box>
    </>
  );
};

export default CommissionList;

import {
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import { formatPrice } from "../../utils/price";
import ProductItemDialog from "./ProductItemDialog";
import { getImgSrcForProduct } from "../../utils/imgSrc";
import { contextAdmin, contextHome } from "../../constants/contexts";
import ProductItemDotykackaDialog from "./ProductItemDotykackaDialog";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import WarningIcon from "@material-ui/icons/Warning";

const ProductItem = (props) => {
  const {
    classes,
    product,
    addToCart,
    context,
    toggleVisibility,
    visible,
    disabled,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [dotykackaDialogOpen, setDotykackaDialogOpen] = useState(false);

  const openDotykackaDialog = (e) => {
    e.preventDefault();
    setDotykackaDialogOpen(true);
  };

  const closeDotykackaDialog = () => {
    setDotykackaDialogOpen(false);
  };

  const handleAddCartItemToCart = (cartItem) => {
    closeDialog();
    addToCart(cartItem);
  };

  const getColor = () => {
    if (!visible) {
      return "rgba(0, 0, 0, 0.25)";
    }
    if (!product.dotykacka[0]) {
      return "#000";
    }
    return parseInt(product.dotykacka[0].hexColor.replace("#", ""), 16) >
      0xffffff / 2
      ? "#000"
      : "#fff";
  };

  const isWarning = () => {
    let dotykackaPrice = 0;
    for (let d of product.dotykacka) {
      dotykackaPrice = dotykackaPrice + d.priceWithVat.trim() * 1;
    }
    if (dotykackaPrice !== 0 && product.price.trim() * 1 !== dotykackaPrice) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <ProductItemDialog
        open={dialogOpen}
        product={product}
        handleDialogClose={closeDialog}
        submit={handleAddCartItemToCart}
      />
      <ProductItemDotykackaDialog
        open={dotykackaDialogOpen}
        product={product}
        handleDialogClose={closeDotykackaDialog}
      />
      {context === contextAdmin && (
        <Box className={classes.adminItem}>
          <Box
            style={{ margin: 8 }}
            display={
              window.location.pathname === "/admin/products" ? "flex" : "none"
            }
            justifyContent={"space-between"}
          >
            <Box>
              <IconButton size={"small"} onClick={toggleVisibility}>
                {visible && <VisibilityIcon />}
                {!visible && <VisibilityOffIcon />}
              </IconButton>
            </Box>
            <Box>
              {product.dotykacka.length > 0 && (
                <Box display={"flex"} alignItems={"center"}>
                  <Box>
                    <IconButton onClick={openDotykackaDialog} size={"small"}>
                      <DesktopWindowsIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    {isWarning() && (
                      <WarningIcon color={"primary"} size={"small"} />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <ButtonBase
            disabled={disabled}
            style={{ width: "100%", display: "block" }}
            onClick={() => {
              if (window.location.pathname !== "/admin/products") {
                openDialog();
              }
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              className={classes.adminItemInner}
              style={{
                color: getColor(),
                background: !!product.dotykacka[0]
                  ? product.dotykacka[0].hexColor
                  : "#ffffff",
              }}
            >
              <Box>
                <Typography variant={"h5"} style={{ textAlign: "left" }}>
                  {product.title}
                </Typography>
                <Typography variant={"h6"} style={{ textAlign: "left" }}>
                  {product.subtitle}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={"subtitle1"}
                  style={{ textAlign: "right" }}
                >
                  {formatPrice(product.price)}
                </Typography>
              </Box>
            </Box>
          </ButtonBase>
        </Box>
      )}
      {context === contextHome && (
        <Card className={classes.product} variant="outlined">
          <Grid container className={classes.item}>
            <Grid item xs={12} sm={7}>
              {product.tagPizzaOfMonth && (
                <Box className={classes.chip}>Pizza měsíce</Box>
              )}
              {product.tagBurgerOfMonth && (
                <Box className={classes.chip}>Burger měsíce</Box>
              )}
              <Box>
                <span className={classes.title}>{product.title}</span>
                <br />
                <span className={classes.subtitle}>{product.subtitle}</span>
              </Box>
              <Box display={{ xs: "block", sm: "none" }}>
                <img
                  style={{ width: "100%" }}
                  src={getImgSrcForProduct(product)}
                  alt={product.title}
                />
              </Box>
              <Typography className={classes.description}>
                {product.description.replace(/\s/g, " ")}
              </Typography>
              {product.allergens && (
                <Typography className={classes.allergens}>
                  Alergeny: {product.allergens}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box display={{ xs: "none", sm: "block" }}>
                <img
                  style={{ width: "100%" }}
                  src={getImgSrcForProduct(product)}
                  alt={product.title}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.price}
              style={{ position: "relative" }}
            >
              <div style={{ position: "absolute", bottom: 4 }}>
                Cena: <strong>{formatPrice(product.price)}</strong>
              </div>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              {!product.notCartInsertable && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={openDialog}
                >
                  Vložit do košíku
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </React.Fragment>
  );
};

export default React.memo(ProductItem);

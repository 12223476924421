export const staticPages = [
  {
    title: "O nás",
    pageSlug: "o-nas",
  },
  {
    title: "Kontakty - otevírací doba",
    pageSlug: "kontakty",
  },
  {
    title: "Obchodní podmínky",
    pageSlug: "obchodni-podminky",
  },
  // {
  //   title: "Kariéra",
  //   pageSlug: "kariera",
  // },
  // {
  //   title: "Franšíza",
  //   pageSlug: "fransiza",
  // },
];

import React from "react";
import { KIOSK_ENABLED, kioskMode } from "../constants/constants";
import { AppBar, Box, makeStyles, Toolbar } from "@material-ui/core";
import NavbarCart from "../components/NavbarCart/NavbarCart";
import PhoneIcon from "@material-ui/icons/Phone";
import { colors } from "../theme/theme";
import { Link } from "react-router-dom";
import { staticPages } from "../constants/staticPages";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.white,
  },
  toolbar: {
    height: 56,
    alignItems: "center",
  },
  logo: {
    flexGrow: 1,
    height: "50%",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    "& img": {
      height: "100%",
    },
  },
  logoTitle: {
    marginLeft: theme.spacing(2),
    color: colors.BLACK.main,
    fontWeight: 900,
    fontSize: 20,
  },
  telephone: {
    color: colors.RED.main,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 15,
    "& svg": {
      width: 15,
      height: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
      "& svg": {
        width: 30,
        height: 30,
      },
    },
  },
  account: {
    color: colors.BLACK.main,
    fontWeight: theme.typography.fontWeightLight,
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  footerToolbar: {
    height: 0,
    [theme.breakpoints.up("md")]: {
      height: 54,
    },
  },
  footer: {
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      background: '#ffffff',
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      textAlign: "left",
    },
    "& div": {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
        marginLeft: theme.spacing(3),
      },
    },
    "& a": {
      color: colors.BLACK.light,
      fontSize: "85%",
      textDecoration: "none",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
      <React.Fragment>
        <Box className={classes.footerToolbar} />
    <Box className={classes.footer}>
      <Box>
        <Link to={"/"}>Objednat si</Link>
      </Box>
      <Box>
        <Link to={"/rating"}>Hodnocení</Link>
      </Box>
      {staticPages.map((page, index) => {
        return (
          <Box key={index}>
            <Link className={classes.link} to={"/" + page.pageSlug}>
              {page.title}
            </Link>
          </Box>
        );
      })}
    </Box>
      </React.Fragment>
  );
};

const NavBar = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar} id="appBar">
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logo}>
            <Link to={"/"}>
              <img src={"/logo.png"} alt="Špizza Pizza" title="Špizza Pizza" />
            </Link>
          </Box>
          <Box className={classes.telephone}>
            <PhoneIcon />
            &nbsp;<span>77&nbsp;55&nbsp;11111</span>
          </Box>
          <Box style={{ marginLeft: 8 }}>
            <NavbarCart />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />
    </React.Fragment>
  );
};

const Layout = (props) => {
  const { children } = props;

  return (
    <React.Fragment>
      {!KIOSK_ENABLED && <NavBar />}
      {children}
      {!KIOSK_ENABLED && <Footer />}
    </React.Fragment>
  );
};

export default Layout;

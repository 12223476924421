import { Grid, TextField } from "@material-ui/core";

import React from "react";
import smartformModule from "@smartform.cz/smartform";

const PlaceAutoComplete = (props) => {
  const { handleChangeStatus } = props;

  const [inputValue, setInputValue] = React.useState(""); //TODO - předvyplnit ze STORE
  // const [status, setStatus] = React.useState({
  //   type: "init",
  //   msg: "",
  // });

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFocus = (event) => {
    setInputValue(event.target.value);
    checkAddress();
  };

  const handleBlur = (event) => {
    setInputValue(event.target.value);
  };

  const checkAddress = () => {
    handleChangeStatus({ type: "checking", msg: "" });
    if (inputValue.length >= 1) {
      let re = new RegExp("^.+ [/0-9a-zA-Z]+, [0-9]{5} .+$");
      if (inputValue && re.test(inputValue)) {
        handleChangeStatus({ type: "valid", msg: "", address: inputValue });
      } else {
        handleChangeStatus({
          type: "error",
          msg: "Adresa se zdá, že není úplná.",
        });
      }
    } else {
      handleChangeStatus({
        type: "error",
        msg: "Adresa se zdá, že není úplná.",
      });
    }
  };

  React.useEffect(() => {
    smartformModule.load();
    window.smartform.beforeInit = () => {
      window.smartform.setClientId("6dTUQF4lca");
    };
  }, []);

  React.useEffect(() => {
    checkAddress();
  }, [inputValue]);

  // React.useEffect(() => {
  //   handleChangeStatus(status);
  // }, [status]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={"text"}
            variant="outlined"
            id="autocomplete"
            name="autocomplete"
            label={"Doručovací adresa"}
            value={inputValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            inputProps={{
              className: "smartform-whole-address",
            }}
          />
        </Grid>
        {/*<Grid item xs={3}>*/}
        {/*  <Button*/}
        {/*    fullWidth*/}
        {/*    style={{ height: "100%" }}*/}
        {/*    onClick={handleSubmit}*/}
        {/*    color={"secondary"}*/}
        {/*    variant={"contained"}*/}
        {/*  >*/}
        {/*    Ověřit*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
};

export default PlaceAutoComplete;

import React from "react";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export const contextCheckoutForm_Checkout = "checkout";
export const contextCheckoutForm_Admin = "admin";

const CheckoutForm = (props) => {
  const { getError, cart, handleChange, context } = props;

  const classes = useStyles();

  return (
    <>
      <TextField
        className={classes.textField}
        fullWidth
        variant={"outlined"}
        label={"Jméno a příjmení"}
        required={context === contextCheckoutForm_Checkout}
        helperText={getError("customerFullName")}
        error={getError("customerFullName").length > 1}
        name={"customerFullName"}
        value={cart.customerFullName}
        onChange={handleChange}
      />
      <TextField
        className={classes.textField}
        fullWidth
        variant={"outlined"}
        label={"E-mail"}
        required={context === contextCheckoutForm_Checkout}
        helperText={getError("customerEmail")}
        error={getError("customerEmail").length > 1}
        name={"customerEmail"}
        value={cart.customerEmail}
        onChange={handleChange}
      />
      <TextField
        className={classes.textField}
        fullWidth
        variant={"outlined"}
        label={"Telefon"}
        required={context === contextCheckoutForm_Checkout}
        helperText={getError("customerTelephone")}
        error={getError("customerTelephone").length > 1}
        name={"customerTelephone"}
        value={cart.customerTelephone}
        onChange={handleChange}
      />
      <TextField
        className={classes.textField}
        fullWidth
        variant={"outlined"}
        label={"Text na krabici"}
        multiline
        rows={3}
        name={"commissionTextOnTheBox"}
        value={cart.commissionTextOnTheBox}
        onChange={handleChange}
      />
      <TextField
        className={classes.textField}
        fullWidth
        variant={"outlined"}
        label={"Poznámka k objednávce"}
        multiline
        rows={3}
        name={"commissionComment"}
        value={cart.commissionComment}
        onChange={handleChange}
      />
    </>
  );
};

export default CheckoutForm;

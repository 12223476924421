import React, { forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
  useTheme,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { formatDate, formatTime } from "../../utils/dateTime";
import { useDispatch, useSelector } from "react-redux";
import { formatPrice } from "../../utils/price";
import HourContainer from "./containers/HourContainer";
import DateContainer from "./containers/DateContainer";
import { initConfig } from "./logicDHM";
import { isDisabled } from "./logicIsDisabled";
import axios from "axios";
import {
  apiGetAdminCapacity,
  apiGetCommissionCapacity,
  apiGetInternalCommissionCapacity,
} from "../../api/endpoints";
import { filterCommissionsForCapacity } from "../../functions/functions";
import { posVyskovKiosek } from "../../constants/pointOfSales";
import { updateCommission } from "../../actions/cartActions";
import { contextHome } from "../../constants/contexts";
import {
  meatSectionProductionInfo_isVisibleForCart,
  meatSectionProductionInfo_text,
  meatSectionProductionInfo_text2,
} from "../MeatSectionProductionInfo/MeatSectionProductionInfo";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
}));

const DateTimePicker = forwardRef((props, ref) => {
  const { onSubmit } = props;

  const makeTimeTests = true;

  const classes = useStyles();

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = false;

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const commission = useSelector((state) => state.cart);
  const pointOfSale = useSelector((state) =>
    state.settings.pointOfSales.find(
      (pos) => pos.id === state.cart.pointOfSaleId
    )
  );
  const commissionCreatedAt = useSelector(
    (state) => state.app.adminCommissionCreatedAt
  );

  const [config, setConfig] = React.useState(() => {
    return initConfig(settings, pointOfSale.id);
  });
  React.useEffect(() => {
    setConfig(initConfig(settings, pointOfSale.id));
  }, [settings, pointOfSale]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    load();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getDateIndexFromCommission = () => {
    if (!commission.commissionTakeOverDateTime) {
      return 0;
    } else {
      const now = new Date();
      const dt = new Date(commission.commissionTakeOverDateTime);
      return dt.getDate() - now.getDate();
    }
  };

  const [dateIndex, setDateIndex] = React.useState(() => {
    return getDateIndexFromCommission();
  });

  const getSelectedDHMFromCommission = () => {
    if (!commission.commissionTakeOverDateTime) {
      return {
        dateIndex: getDateIndexFromCommission(),
        d: null,
        h: null,
        m: null,
      };
    } else {
      const dt = new Date(commission.commissionTakeOverDateTime);
      return {
        dateIndex: dateIndex,
        d: dt,
        h: dt.getHours(),
        m: dt.getMinutes(),
      };
    }
  };

  const [selectedDHM, setSelectedDHM] = React.useState(() => {
    return getSelectedDHMFromCommission();
  });

  const handleSelectTime = (h, m, servicePrice) => {
    setSelectedDHM({ dateIndex: dateIndex, d: config.dates[dateIndex], h, m });

    const tx = config.dates[dateIndex];

    const takeOverDateTime = new Date(
      tx.getFullYear(),
      tx.getMonth(),
      tx.getDate(),
      h,
      m,
      0
    );

    onSubmit(takeOverDateTime, servicePrice);
    handleClose();
  };

  const load = async () => {
    const request0 = axios.get(apiGetCommissionCapacity()); //aktuální vytíženost
    const request1 = axios.get(apiGetAdminCapacity()); //nastavená kapacita (dle administrace)
    const request2 = axios.get(apiGetInternalCommissionCapacity()); //nastavená kapacita (dle administrace)
    axios.all([request0, request1, request2]).then(
      axios.spread((...responses) => {
        setCommissions(responses[0].data); //aktuální vytíženost
        setCapacities(responses[1].data.content); //kapacity
        setInternalCommissions(responses[2].data); //aktuální vytíženost
      })
    );
  };

  const [commissions, setCommissions] = React.useState([]);
  const [capacities, setCapacities] = React.useState([]);
  const [internalCommissions, setInternalCommissions] = React.useState([]);
  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (!commission.commissionTakeOverDateTime) {
      setSelectedDHM(getSelectedDHMFromCommission());
    }
  }, [commission.commissionTakeOverDateTime]);

  const getCommissionsOfDate = (date, commissions) => {
    const pos = settings.pointOfSales.find(
      (pos) => pos.id === commission.pointOfSaleId
    );
    return filterCommissionsForCapacity(
      commissions,
      pos.localBusinessTitle,
      date
    );
  };

  //metody pro vytvoření objektu TimeObject
  const servicePrice = (config, h) => {
    return config.pointOfSale["servicePrice_" + h] * 1;
  };

  const formatPriceWithText = (price) => {
    if (price === 0) {
      return formatPrice(price);
    }
    return "Cena: " + formatPrice(price);
  };

  const buildTimeObject = (h, m) => {
    const sp = servicePrice(config, h);
    const dt = new Date(config.dates[dateIndex].getTime());
    dt.setHours(h, m, 0, 0);
    return {
      datetime: dt,
      title: formatTime(h, m, config.isInterval, config.intervalSize),
      servicePrice: sp,
      price: formatPriceWithText(sp),
      isSelected:
        selectedDHM.dateIndex === dateIndex &&
        selectedDHM.h === h &&
        selectedDHM.m === m,
      isDisabled: isDisabled(
        config.dates[dateIndex],
        h,
        m,
        config,
        commission,
        getCommissionsOfDate(config.dates[dateIndex], commissions),
        makeTimeTests,
        capacities,
        settings,
        !!commissionCreatedAt ? commissionCreatedAt : new Date(),
        internalCommissions
      ),
    };
  };

  useImperativeHandle(ref, () => ({
    makeCheck(preparedCart) {
      const request0 = axios.get(apiGetCommissionCapacity()); //aktuální vytíženost
      const request1 = axios.get(apiGetAdminCapacity()); //nastavená kapacita (dle administrace)
      const request2 = axios.get(apiGetInternalCommissionCapacity()); //nastavená kapacita (dle administrace)
      return axios.all([request0, request1, request2]).then(
        axios.spread((...responses) => {
          return !isDisabled(
            config.dates[selectedDHM.dateIndex],
            selectedDHM.h,
            selectedDHM.m,
            config,
            preparedCart,
            getCommissionsOfDate(
              config.dates[selectedDHM.dateIndex],
              responses[0].data
            ),
            makeTimeTests,
            responses[1].data.content,
            settings,
            !!commissionCreatedAt ? commissionCreatedAt : new Date(),
            responses[2].data
          );
        })
      );
    },
  }));

  const getLabel = () => {
    if (!!selectedDHM.d) {
      return (
        formatDate(selectedDHM.d) +
        ", " +
        buildTimeObject(selectedDHM.h, selectedDHM.m).title
      );
    }
    return "Vyberte z nabídky";
  };

  const isAdmin = window.location.href.includes("admin");

  const handleChangeCapacityCheck = () => {
    const cch = !commission.capacityCheck;
    dispatch(updateCommission("capacityCheck", cch));
  };

  return (
    <>
      <Button color={"primary"} variant={"contained"} onClick={handleOpen}>
        {getLabel()}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Box style={{ display: "flex" }}>
            <Box style={{ flexGrow: 1 }}>
              {commission.pointOfSaleId !== posVyskovKiosek
                ? "Vyberte datum a čas převzetí"
                : "Vyberte čas převzetí"}
            </Box>
            <Box>
              <IconButton onClick={handleClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {commission.pointOfSaleId !== posVyskovKiosek && (
            <DateContainer
              config={config}
              selectedDateIndex={dateIndex}
              handleClick={setDateIndex}
            />
          )}
          {meatSectionProductionInfo_isVisibleForCart(commission) && (
            <Box style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16 }}>
              {meatSectionProductionInfo_text2()}
            </Box>
          )}
          <HourContainer
            config={config}
            handleSelect={handleSelectTime}
            buildTimeObject={buildTimeObject}
          />
          <Box hidden={!isAdmin} style={{ padding: 8 }}>
            <FormControlLabel
              control={
                <Switch
                  color={"primary"}
                  checked={commission.capacityCheck}
                  onChange={() => {
                    handleChangeCapacityCheck();
                  }}
                />
              }
              label="Použít kapacity"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default DateTimePicker;

import { csobPaymentStatuses, csobPaymentTitle } from "./csob";

export const getPaymentMethod = (commission) => {
  return commission.commissionPaymentMethod;
};

export const isPaymentMethodPaymentOnline = (commission) => {
  return getPaymentMethod(commission) === csobPaymentTitle;
};

export const getPaymentMethodPaymentOnlineStatus = (commission) => {
  return csobPaymentStatuses.find(
    (cps) => cps.csobTitle === commission.csobPayStatus
  );
};

export const isPaymentMethodPaymentOnlinePaid = (commission) => {
  const paymentStatus = getPaymentMethodPaymentOnlineStatus(commission);
  if (paymentStatus) {
    return paymentStatus.paid;
  }
  return false;
};

export const canCommissionBeShownInAdmin = (commission) => {
  //objednávka má platební způsob Platba on-line a zároveň objednávka není zaplacena -> nezobrazovat
  //objenávka má platební způsob Platba on-line a zároveň objednávka je zaplacena -> zobrazovat
  //objednávka nemá platební způsob Platba on-line -> zobrazovat
  return !(
    isPaymentMethodPaymentOnline(commission) &&
    !isPaymentMethodPaymentOnlinePaid(commission)
  );
};

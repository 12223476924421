import { Box, IconButton, makeStyles } from "@material-ui/core";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { colors } from "../../theme/theme";
import { getImgSrcForCategory } from "../../utils/imgSrc";
import { KIOSK_ENABLED } from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  arrow: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    },
  },
  categories: {
    backgroundColor: KIOSK_ENABLED ? colors.ORANGE.main : colors.WHITE.main,
    zoom: KIOSK_ENABLED ? 1.5 : 1,
    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.14)",
    "& .menu-item-wrapper:focus": {
      outline: "none",
    },
    "& .scroll-menu-arrow--disabled button": {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      cursor: "default",
    },
  },
  category: {
    width: 150,
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    boxSizing: "border-box",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    borderBottomStyle: "solid",
    borderBottomWidth: 4,
    borderBottomColor: KIOSK_ENABLED ? colors.ORANGE.main : colors.WHITE.main,
    "&:hover": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "&.active": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "& > div": {
      display: "block",
      "& > img": {
        height: 32,
      },
    },
  },
}));

const Category = (props) => {
  const { classes, category, selected } = props;

  return (
    <Box className={classes.category + (selected ? " active" : "")}>
      {category.imgSrc && (
        <Box>
          <img src={getImgSrcForCategory(category)} alt={category.title} />
        </Box>
      )}
      <Box>{category.title}</Box>
    </Box>
  );
};

const Menu = (classes, categories, selected) => {
  return categories.map((category, index) => {
    return (
      <Category
        key={index}
        classes={classes}
        category={category}
        selected={index === selected}
      />
    );
  });
};

const ArrowLeft = (classes) => {
  return (
    <IconButton color={"secondary"} size={"small"} className={classes.arrow}>
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const ArrowRight = (classes) => {
  return (
    <IconButton color={"secondary"} size={"small"} className={classes.arrow}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};

const Categories = (props) => {
  const { categories, changeCategory } = props;

  const classes = useStyles();
  const [selected, setSelected] = React.useState(0);

  const onSelect = (index) => {
    setSelected(index);
    changeCategory(categories[index]);
  };

  return (
    <Box className={classes.categories}>
      <ScrollMenu
        data={Menu(classes, categories, selected)}
        alignCenter={false}
        arrowLeft={ArrowLeft(classes)}
        arrowRight={ArrowRight(classes)}
        selected={selected}
        onSelect={onSelect}
        transition={1.5}
        hideSingleArrow={true}
        scrollBy={2}
      />
    </Box>
  );
};

export default Categories;

import { getCartPriceSubtotalForCapacityCalculation } from "../../../utils/price";
import { vosSectionMeat, vosSectionPizza } from "../../../constants/constants";

export const SOURCE_PIZZA = "PIZZA";
export const SOURCE_MASO = "MASO";
export const SOURCE_ROZVOZ = "ROZVOZ";

const DAYS = [
  {
    code: 1,
    label: "Pondělí",
  },
  {
    code: 2,
    label: "Úterý",
  },
  {
    code: 3,
    label: "Středa",
  },
  {
    code: 4,
    label: "Čtvrtek",
  },
  {
    code: 5,
    label: "Pátek",
  },
  {
    code: 6,
    label: "Sobota",
  },
  {
    code: 0,
    label: "Neděle",
  },
];

const SOURCES = [
  {
    code: SOURCE_PIZZA,
    label: SOURCE_PIZZA,
    defaultValue: 50,
    commissionValueFn: (commission) => {
      return getCartPriceSubtotalForCapacityCalculation(
        commission,
        vosSectionPizza
      );
    },
    reduceFn: (prev, current) => {
      return prev + current;
    },
  },
  {
    code: SOURCE_MASO,
    label: SOURCE_MASO,
    defaultValue: 50,
    commissionValueFn: (commission) => {
      return getCartPriceSubtotalForCapacityCalculation(
        commission,
        vosSectionMeat
      );
    },
    reduceFn: (prev, current) => {
      return prev + current;
    },
  },
  {
    code: SOURCE_ROZVOZ,
    label: SOURCE_ROZVOZ,
    defaultValue: 1,
    commissionValueFn: (commission) => {
      return 0; //TODO: Nebo 0
    },
    reduceFn: (prev, current) => {
      return current;
    },
  },
];

const COLUMNS = [
  {
    code: "capacityDefinitionPizza",
    source: SOURCE_PIZZA,
    type: "definition",
    label: "Definice",
  },
  {
    code: "actualCapacityPizza",
    source: SOURCE_PIZZA,
    type: "actual",
    label: "Spotřeba",
  },
  {
    code: "restCapacityPizza",
    source: SOURCE_PIZZA,
    type: "rest",
    label: "Zbývá",
  },
  {
    code: "actualCapacityPizza_commissions",
    source: SOURCE_PIZZA,
    type: "commissions",
    label: "Objednávky",
  },
  {
    code: "capacityDefinitionMeat",
    source: SOURCE_MASO,
    type: "definition",
    label: "Definice",
  },
  {
    code: "actualCapacityMeat",
    source: SOURCE_MASO,
    type: "actual",
    label: "Spotřeba",
  },
  {
    code: "restCapacityMeat",
    source: SOURCE_MASO,
    type: "rest",
    label: "Zbývá",
  },
  {
    code: "actualCapacityMeat_commissions",
    source: SOURCE_MASO,
    type: "commissions",
    label: "Objednávky",
  },
  {
    code: "capacityDefinitionDelivery",
    source: SOURCE_ROZVOZ,
    type: "definition",
    label: "Definice",
  },
  {
    code: "actualCapacityDelivery",
    source: SOURCE_ROZVOZ,
    type: "actual",
    label: "Spotřeba",
  },
  {
    code: "restCapacityDelivery",
    source: SOURCE_ROZVOZ,
    type: "rest",
    label: "Zbývá",
  },
  {
    code: "actualCapacityDelivery_commissions",
    source: SOURCE_ROZVOZ,
    type: "commissions",
    label: "Objednávky",
  },
];

const GROUP_BYs = [
  { code: 1, label: "1 min" },
  { code: 2, label: "2 min" },
  { code: 10, label: "10 min" },
  { code: 15, label: "15 min" },
  { code: 30, label: "30 min" },
  { code: 60, label: "60 min" },
];

export const formatCodeToHM = (code) => {
  const h = Math.floor(code / 60);
  const m = code - h * 60;

  return [h, m]
    .map((t) => {
      return t < 10 ? "0" + t : t;
    })
    .join(":");
};

export const getDays = () => {
  return DAYS;
};

export const getSources = () => {
  return SOURCES;
};

export const getGroupBys = () => {
  return GROUP_BYs;
};

export const getColumns = () => {
  return COLUMNS;
};

export const getTimes = () => {
  const times = [];
  for (let h = 8; h < 22; h++) {
    for (let m = 0; m < 60; m++) {
      const code = h * 60 + m;
      times.push({
        code,
        label: formatCodeToHM(code),
      });
    }
  }
  return times;
};

export const generateCode = (dayCode, timeCode, sourceCode) => {
  return [dayCode, timeCode, sourceCode].join("_");
};

// export const initDefinitions = () => {
//   const definitions = {};
//   getDays().forEach((day) => {
//     getTimes().forEach((time) => {
//       getSources().forEach((source) => {
//         const code = generateCode(day.code, time.code, source.code);
//         definitions[code] = source.defaultValue;
//       });
//     });
//   });
//
//   return definitions;
// };

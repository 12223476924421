import React from "react";
import "./LayoutCustomer.css";

const LayoutCustomer = (props) => {
  const { children } = props;

  return <React.Fragment>{children}</React.Fragment>;
};

export default LayoutCustomer;

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    "& .title": {
      fontSize: "110%",
      fontWeight: 800,
    },
    "& .subtitle": {
      fontSize: "90%",
      fontWeight: 300,
    },
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  table: {
    "& th": {
      fontWeight: 600,
    },
    "& tr": {
      "& td:nth-child(2), & th:nth-child(2)": {
        background: "rgba(255, 255, 0, 0.5)",
      },
      "& td:nth-child(n+3), & th:nth-child(n+3)": {
        background: "rgba(0, 255, 0, 0.5)",
      },
    },
  },
}));

const ProductItemDotykackaDialog = (props) => {
  const { open, product, handleDialogClose } = props;

  const fullScreen = false;

  const dialogClasses = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={fullScreen}
    >
      <DialogTitle className={dialogClasses.dialogTitle}>
        <Box style={{ display: "flex" }}>
          <Box style={{ flexGrow: 1 }}>
            <Box className={"title"}>{product.title}</Box>
            <Box className={"subtitle"}>{product.subtitle}</Box>
          </Box>
          <Box>
            <IconButton
              className={dialogClasses.closeButton}
              onClick={handleDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={dialogClasses.dialogContent}>
        {product.dotykacka && product.dotykacka.length > 0 && (
          <>
            <Table size="small" className={dialogClasses.table}>
              <TableHead>
                <TableRow>
                  <TableCell component={"th"} />
                  <TableCell>Sortimentová tabulka</TableCell>
                  <TableCell colSpan={product.dotykacka.length}>
                    Dotykačka
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component={"th"}>Systémový název</TableCell>
                  <TableCell>{product.fullTitle}</TableCell>
                  <TableCell colSpan={product.dotykacka.length}>-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Cloud ID</TableCell>
                  <TableCell>-</TableCell>
                  {product.dotykacka.map((d) => {
                    return <TableCell>{d["_cloudId"]}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Product ID</TableCell>
                  <TableCell>-</TableCell>
                  {product.dotykacka.map((d) => {
                    return <TableCell>{d.id}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Název</TableCell>
                  <TableCell>{product.title}</TableCell>
                  {product.dotykacka.map((d) => {
                    return <TableCell>{d.name}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Název 2. řádek</TableCell>
                  <TableCell>{product.subtitle}</TableCell>
                  <TableCell colSpan={product.dotykacka.length}>-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Cena</TableCell>
                  <TableCell>{product.price}</TableCell>
                  {product.dotykacka.map((d) => {
                    return <TableCell>{d.priceWithVat}</TableCell>;
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProductItemDotykackaDialog;

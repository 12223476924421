import React from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  makeStyles,
  Typography,
} from "@material-ui/core";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import {
  acceptedStatusCode,
  closedStatusCode,
  finishedStatusCode,
  inProductionStatusCode,
  onWayStatusCode,
} from "../adminEnum";
import {
  addressXNA,
  commissionOriginCounter,
} from "../../../constants/constants";
import axios from "axios";
import {apiPutCommission, apiPutCommissionDotykackaNote} from "../../../api/endpoints";
import { useSnackbar } from "notistack";
import {
  canCommissionBeShownInAdmin,
  isPaymentMethodPaymentOnline,
  isPaymentMethodPaymentOnlinePaid,
} from "../../../utils/payment";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      borderRadius: 4,
    },
  };
});

export const DOTYKACKA_STATUS_READY_TO_CREATE = "ready_to_create";
export const DOTYKACKA_STATUS_CREATED = "created";
export const DOTYKACKA_STATUS_CLOSED = "closed";
export const DOTYKACKA_STATUS_NONE = "none";

const getDotykackaStatusCode = (commission) => {
  if (!!commission.dotykackaStatusCode) {
    return commission.dotykackaStatusCode;
  }

  if (
    [
      inProductionStatusCode,
      finishedStatusCode,
      onWayStatusCode,
      commission.commissionOrigin === commissionOriginCounter
        ? acceptedStatusCode
        : null,
    ].includes(commission.activeStatusCode) &&
    !commission.dotykackaStatusCode
  ) {
    return DOTYKACKA_STATUS_READY_TO_CREATE;
  }
  return DOTYKACKA_STATUS_NONE;
};

const CommissionButtonsDotykacka = (props) => {
  const { commission } = props;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleCreate = () => {
    const copy = { ...commission };
    copy.dotykackaStatusCode = DOTYKACKA_STATUS_CREATED;
    setIsLoading(true);
    axios
      .put(apiPutCommission(copy.uid), copy)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Založeno v Dotykačce", {
            variant: "success",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          "Chyba při zakládání v Dotykačce: " + error.response.data.error,
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      });
  };

  const handleSetNote = () => {

    setIsLoading(true);
    axios
        .put(apiPutCommissionDotykackaNote(commission.uid), {})
        .then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Poznámka v Dotykačce přidána", {
              variant: "success",
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          enqueueSnackbar(
              "Chyba přidávání poznámky v Dotykačce: " + error.response.data.error,
              {
                variant: "error",
              }
          );
          setIsLoading(false);
        });
  }

  const handleClose = () => {
    const copy = { ...commission };
    copy.dotykackaStatusCode = DOTYKACKA_STATUS_CLOSED;
    setIsLoading(true);
    axios
      .put(apiPutCommission(copy.uid), copy)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Uzavřeno v Dotykačce", {
            variant: "success",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          "Chyba při uzavíraní v Dotykačce: " + error.response.data.error,
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      });
  };

  if (getDotykackaStatusCode(commission) === DOTYKACKA_STATUS_READY_TO_CREATE) {
    return (
      <Box>
        <Box mb={0.5} />
        <Box>
          <Button
            startIcon={<DesktopMacIcon />}
            size={"large"}
            color={"secondary"}
            variant={"contained"}
            className={classes.button}
            onClick={handleCreate}
            disabled={isLoading}
          >
            Založit v Dotykačce
          </Button>
        </Box>
      </Box>
    );
  }

  if (getDotykackaStatusCode(commission) === DOTYKACKA_STATUS_CREATED) {
    return (
      <Box>
        <Box mb={0.5}>
          <Chip label={"Založeno v Dotykačce"} />
        </Box>
          {/*<Box mb={0.5}>*/}
          {/*    <Button*/}
          {/*        startIcon={<DesktopMacIcon />}*/}
          {/*        size={"small"}*/}
          {/*        color={"secondary"}*/}
          {/*        variant={"contained"}*/}
          {/*        className={classes.button}*/}
          {/*        onClick={handleCreate}*/}
          {/*        disabled={isLoading}*/}
          {/*    >*/}
          {/*        Znovu založit v Dotykačce*/}
          {/*    </Button>*/}
          {/*</Box>*/}
        <Box>
          {commission.customerAddress !== addressXNA && ( //objednávka je rozvozová
            <Button
              startIcon={<DesktopMacIcon />}
              size={"large"}
              color={"secondary"}
              variant={"contained"}
              className={classes.button}
              onClick={handleClose}
              disabled={isLoading}
            >
              Uzavřít v Dotykačce + tisk
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  if (getDotykackaStatusCode(commission) === DOTYKACKA_STATUS_CLOSED) {
    return (
      <Box>
        <Box mb={0.5}>
          <Chip label={"Založeno v Dotykačce"} />
        </Box>
        <Box>
          <Chip label={"Uzavřeno v Dotykačce"} />
        </Box>
      </Box>
    );
  }

  return <></>;
};

export default CommissionButtonsDotykacka;

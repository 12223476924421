import "./App.css";

import { Provider } from "react-redux";
import React from "react";
import Router from "./routes";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import store from "./store";
import theme from "./theme/theme";
import DebugBar from "./components/DebugBar/DebugBar";
import DateFnsUtils from "@date-io/date-fns";
import { cs } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
      <DebugBar />
    </Provider>
  );
}

export default App;

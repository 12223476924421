import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import CommissionDetail from "../CommissionDetail/CommissionDetail";
import axios from "axios";
import { apiGetCommissions } from "../../../api/endpoints";
import { DatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";

const headerHeight = 0;

const useStyles = makeStyles((theme) => ({
  loading: {
    background: "#ffffff",
    height: "100vh",
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    paddingTop: headerHeight + theme.spacing(1),
    boxSizing: "border-box",
    backgroundColor: "#000000",
  },
}));

const orderByOptions = [
  {
    title: "Číslo objednávky",
    sortFn: (a, b) => {
      const aX = a.numberLb;
      const bX = b.numberLb;
      if (aX === bX) return 0;
      if (aX < bX) return 1; //sestupně
      if (aX > bX) return -1;
      return 0;
    },
  },
];

const CommissionListArchive = (props) => {
  const { localBusiness } = props;

  const classes = useStyles();

  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const [commissions, setCommissions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const settings = useSelector((state) => state.settings);

  const init = () => {
    setLoading(true);

    dateFrom.setHours(0, 0, 0, 0);
    const df = dateFrom.getTime();
    dateTo.setHours(23, 59, 59, 0);
    const dt = dateTo.getTime();

    const config = {
      params: {
        dateFrom: df,
        dateTo: dt,
        localBusiness: localBusiness.title,
      },
    };

    axios
      .get(apiGetCommissions(), config)
      .then((response) => {
        setCommissions(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    init();
  }, [localBusiness, dateFrom]);

  const handleDateFrom = (date) => {
    setDateFrom(date);
    setDateTo(date);
  };
  const handleSubmit = () => {
    init();
  };

  // React.useEffect(() => {
  //   console.log(settings);
  // }, [settings]);

  return (
    <>
      <Box mt={2} mb={2} pl={2} pr={2} display={"flex"}>
        <Box mr={4} display={"flex"} alignItems={"center"}>
          <Box mr={1}>
            <strong>Zvolte den</strong>
          </Box>
          <Box>
            <DatePicker
              value={dateFrom}
              onChange={handleDateFrom}
              format={"d. M. yyyy"}
              autoOk={true}
              disableToolbar={true}
              showTodayButton={true}
              cancelLabel={"Zrušit"}
              clearLabel={"Zrušit"}
              okLabel={"OK"}
              todayLabel={"dnes"}
            />
          </Box>
        </Box>
        <Box flexGrow={1} style={{ textAlign: "left" }}>
          <Button
            onClick={handleSubmit}
            color={"primary"}
            variant={"contained"}
            size={"small"}
          >
            Vypsat
          </Button>
        </Box>
      </Box>
      {loading && (
        <>
          <Box className={classes.loading}>Nahrávám objednávky...</Box>
        </>
      )}
      {!loading && (
        <Box className={classes.container}>
          {commissions.sort(orderByOptions[0].sortFn).map((c, index) => {
            return (
              <CommissionDetail
                commission={c}
                key={index}
                handleChangeCommission={() => {
                  alert("Funkce není implementována.");
                }}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default CommissionListArchive;

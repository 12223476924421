import {
  FETCH_RANGE_OF_GOODS_INIT,
  FETCH_RANGE_OF_GOODS_SUCCESS,
} from "../constants/actionTypes";
import axios from "axios";
import { apiGetProducts } from "../api/endpoints";
import { contextAdmin, contextHome } from "../constants/contexts";
import md5 from "md5";

const naiveCache = false;
const generateCacheKey = (url) => {
  const dt = new Date();
  return [
    "cache",
    md5(
      [url, dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours()].join(
        "_"
      )
    ),
  ].join("_");
};

export const fetchRangeOfGoods = (apiCode, context) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RANGE_OF_GOODS_INIT });
    const url =
      context === contextAdmin
        ? apiGetProducts(apiCode, ["dotykackaCloudId=312671418"]) //CloudId pro Vyškov
        : apiGetProducts(apiCode);
    const key = generateCacheKey(url);
    if (naiveCache && sessionStorage.hasOwnProperty(key)) {
      const rog = sessionStorage.getItem(key);
      dispatch({
        type: FETCH_RANGE_OF_GOODS_SUCCESS,
        payload: JSON.parse(rog),
      });
    } else {
      axios.get(url).then((response) => {
        const rog = { ...response.data };
        if (
          context === contextHome ||
          window.location.pathname !== "/admin/products"
        ) {
          rog.products = [
            ...rog.products.filter((p) => {
              return !p.invisible;
            }),
          ];
        }
        dispatch({ type: FETCH_RANGE_OF_GOODS_SUCCESS, payload: rog });
        if (naiveCache) {
          sessionStorage.setItem(key, JSON.stringify(rog));
        }
      });
    }
  };
};

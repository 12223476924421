import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";
import { cartMiddleware } from "../middleware/cartMiddleware";
import { logger } from "redux-logger/src";

const store = process.env.REACT_APP_ENVIRONMENT === 'development' ? createStore(
  rootReducer,
  applyMiddleware(thunk, cartMiddleware, logger)
) : createStore(
    rootReducer,
    applyMiddleware(thunk, cartMiddleware)
);

export default store;

import {
  additionGroupPizzaHalf,
  additionGroupPizzaQuarter,
  product2Plus1Gratis,
  product2Plus1GratisAdditionGroupTitles,
} from "../constants/constants";

export const getCategories = (rog) => {
  return rog.categories.filter((category) => {
    return (
      getProducts(rog).filter((product) => {
        return (
          product.categories && product.categories.includes(category.title)
        );
      }).length > 0
    );
  });
};

export const getProducts = (rog) => {
  return rog.products;
};

export const getProductsOfCategory = (rog, category) => {
  return getProducts(rog).filter((product) => {
    return product.categories && product.categories.includes(category.title);
  });
};

export const getAdditionGroups = (rog) => {
  return rog.additionGroups;
};

export const getAdditionGroupsOfProduct = (rog, product) => {
  if (product.fullTitle === product2Plus1Gratis) {
    // pokud vybíraný produkt je 2+1 Pizza Zdarma
    return getAdditionGroups(rog).filter((additionGroup) => {
      //pak nabídni doplňky z této množiny
      return product2Plus1GratisAdditionGroupTitles.includes(
        additionGroup.fullTitle
      );
    });
  }
  return getAdditionGroups(rog).filter((additionGroup) => {
    return (
      product.additionGroups &&
      product.additionGroups.includes(additionGroup.fullTitle)
    );
  });
};

export const getAdditionGroupOfAdditionItem = (rog, additionItem) => {
  return getAdditionGroups(rog).find((additionGroup) => {
    return additionGroup.fullTitle === additionItem.groupTitle;
  });
};

export const getAdditionItems = (rog) => {
  return rog.additionsItems;
};

export const getAdditionItemsOfAdditionGroup = (rog, additionGroup) => {
  return getAdditionItems(rog).filter((additionItem) => {
    return additionGroup.fullTitle === additionItem.groupTitle;
  });
};

export const getAdditionItemsForPizzaQuarter = (rog) => {
  return getAdditionItems(rog).filter((additionItem) => {
    return additionItem.groupTitle === additionGroupPizzaQuarter;
  });
};

export const getAdditionItemsForPizzaHalf = (rog) => {
  return getAdditionItems(rog).filter((additionItem) => {
    return additionItem.groupTitle === additionGroupPizzaHalf;
  });
};

export const isProductInRangeOfGoods = (rog, product) => {
  const found = getProducts(rog).find((p) => p.fullTitle === product.fullTitle);
  return !!found;
};

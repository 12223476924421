import React, { useRef, useState } from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, Button, Container } from "@material-ui/core";
import { baseUrl } from "../../constants/constants";
import axios from "axios";
import JoditEditor from "jodit-react";
import { useSnackbar } from "notistack";

const PageEditor = (props) => {
  const { pageSlug } = props;

  const { enqueueSnackbar } = useSnackbar();

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  React.useEffect(() => {
    const load = () => {
      const url = baseUrl + "/pages/" + pageSlug;
      axios.get(url).then((response) => {
        setContent(response.data.content);
      });
    };
    load();
  }, [pageSlug]);

  const handleSave = () => {
    const url = baseUrl + "/pages/" + pageSlug;
    const data = {
      content: content,
    };
    axios.put(url, data).then((response) => {
      if (response.status === 200) {
        enqueueSnackbar("Stránka byla uložena.", {
          variant: "success",
        });
      }
    });
  };

  return (
    <>
      <Container style={{ paddingTop: 16, height: "100%" }}>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {}}
        />
        <Box mt={2}>
          <Button
            color={"secondary"}
            size={"medium"}
            variant={"contained"}
            onClick={handleSave}
          >
            Uložit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default PageEditor;

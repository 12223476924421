import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import CapacityHeaderItem from "./CapacityHeaderItem";
import { days } from "../../../constants/constants";
import { generateClassesForItem, generateKey, useStyles } from "./functions";
import { buildCapacityDefinition } from "../../../functions/functions";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_TYPE_CHANGE } from "./reducers/capacityReducer";

const optionsPointsDifficulty = () => {
  let values = [];
  values.push(-1);
  for (let i = 0; i <= 4 * 800; i = i + 25) {
    values.push(i);
  }
  return values;
};

const optionsPointsDelivery = () => {
  let values = [];
  values.push(-1);
  for (let i = 0; i <= 4 * 2; i = i + 1) {
    values.push(i);
  }
  return values;
};

const CapacityHeader = (props) => {
  const { onSubmit } = props;

  const localBusinessTitle = "Vyškov";

  const classes = useStyles();

  const capacityState = useSelector((state) => state.capacity);
  const dispatch = useDispatch();

  const dayOfWeek = capacityState.dayOfWeek;

  const getIntersections = () => {
    let intersections = [];
    for (let hours of capacityState.config.hours) {
      for (let minutes of capacityState.config.minutes) {
        const key = generateKey(hours, minutes);
        const checked = capacityState.checked.includes(key);
        if (checked) {
          intersections.push({
            hours,
            minutes,
          });
        }
      }
    }
    return intersections;
  };

  const capacitiesModifiedCount = () => {
    let modificator = 0;
    if (capacityState.batchPIZZA !== -1) {
      modificator = modificator + 1;
    }
    if (capacityState.batchMASO !== -1) {
      modificator = modificator + 1;
    }
    if (capacityState.batchROZVOZ !== -1) {
      modificator = modificator + 1;
    }
    return getIntersections().length * modificator;
  };

  const handleBatchSave = () => {
    const capacitiesCopy = [...capacityState.capacities];
    const checked = getIntersections(); //získá všechny průsečíky zaškrnutých POS a časů

    for (let ch of checked) {
      for (let source of ["PIZZA", "MASO", "ROZVOZ"]) {
        let capacityDefinition = capacitiesCopy.find((c) => {
          return (
            c.localBusiness === localBusinessTitle &&
            c.source === source &&
            c.dayOfWeek === dayOfWeek.index &&
            c.hours === ch.hours &&
            c.minutes === ch.minutes
          );
        });
        if (
          !capacityDefinition ||
          !capacityDefinition.hasOwnProperty("value")
        ) {
          capacityDefinition = buildCapacityDefinition(
            localBusinessTitle,
            source,
            dayOfWeek.index,
            ch.hours,
            ch.minutes
          );
          capacitiesCopy.push(capacityDefinition);
        }
        if (capacityState["batch" + source] !== -1) {
          capacityDefinition.value = capacityState["batch" + source];
        }
      }
    }
    onSubmit(capacitiesCopy);
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "batchPIZZA",
        value: -1,
      },
    });
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "batchMASO",
        value: -1,
      },
    });
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "batchROZVOZ",
        value: -1,
      },
    });
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "capacities",
        value: capacitiesCopy,
      },
    });
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "checked",
        value: [],
      },
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        pb={4}
        style={{ width: "100%" }}
      >
        <Box>
          <FormControl variant="outlined" size={"small"}>
            <InputLabel>Den</InputLabel>
            <Select
              value={capacityState.dayOfWeek}
              name={"dayOfWeek"}
              onChange={(event) => {
                dispatch({
                  type: ACTION_TYPE_CHANGE,
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                });
                dispatch({
                  type: ACTION_TYPE_CHANGE,
                  payload: {
                    name: "checked",
                    value: [],
                  },
                });
                dispatch({
                  type: ACTION_TYPE_CHANGE,
                  payload: {
                    name: "batchPIZZA",
                    value: -1,
                  },
                });
                dispatch({
                  type: ACTION_TYPE_CHANGE,
                  payload: {
                    name: "batchMASO",
                    value: -1,
                  },
                });
                dispatch({
                  type: ACTION_TYPE_CHANGE,
                  payload: {
                    name: "batchROZVOZ",
                    value: -1,
                  },
                });
              }}
              label={"Den"}
            >
              {days.map((d) => {
                return (
                  <MenuItem key={d.code} value={d}>
                    {d.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box style={{ textAlign: "right" }}>
          <Box display={"flex"}>
            <Box ml={1} mr={1} mt={1}>
              PIZZA:
            </Box>
            <Box>
              <FormControl
                variant="outlined"
                size={"small"}
                style={{ marginRight: 8 }}
              >
                <Select
                  value={capacityState.batchPIZZA}
                  name={"batchPIZZA"}
                  onChange={(event) => {
                    dispatch({
                      type: ACTION_TYPE_CHANGE,
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  {optionsPointsDifficulty().map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {v === -1 && <i>beze změny</i>}
                        {v !== -1 && (
                          <>
                            {v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          </>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box ml={1} mr={1} mt={1}>
              MASO:
            </Box>
            <Box>
              <FormControl
                variant="outlined"
                size={"small"}
                style={{ marginRight: 8 }}
              >
                <Select
                  value={capacityState.batchMASO}
                  name={"batchMASO"}
                  onChange={(event) => {
                    dispatch({
                      type: ACTION_TYPE_CHANGE,
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  {optionsPointsDifficulty().map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {v === -1 && <i>beze změny</i>}
                        {v !== -1 && (
                          <>
                            {v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          </>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box ml={1} mr={1} mt={1}>
              ROZVOZ:
            </Box>
            <Box>
              <FormControl
                variant="outlined"
                size={"small"}
                style={{ marginRight: 8 }}
              >
                <Select
                  value={capacityState.batchROZVOZ}
                  name={"batchROZVOZ"}
                  onChange={(event) => {
                    dispatch({
                      type: ACTION_TYPE_CHANGE,
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  {optionsPointsDelivery().map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {v === -1 && <i>beze změny</i>}
                        {v !== -1 && <>{v}</>}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                variant={"contained"}
                color={"primary"}
                size={"medium"}
                onClick={handleBatchSave}
              >
                Hromadně nastavit kapacity ({capacitiesModifiedCount()})
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container className={classes.row}>
        <Grid
          item
          style={{ width: "25%" }}
          className={generateClassesForItem(
            capacityState.config,
            capacityState,
            null,
            null,
            null,
            ["header"]
          )}
        >
          <CapacityHeaderItem source={null} hours={null} minutes={null} />
        </Grid>
        <Grid
          item
          style={{ width: "25%" }}
          className={generateClassesForItem(
            capacityState.config,
            capacityState,
            "PIZZA",
            null,
            null,
            ["header"]
          )}
        >
          <CapacityHeaderItem source={"PIZZA"} hours={null} minutes={null} />
        </Grid>
        <Grid
          item
          style={{ width: "25%" }}
          className={generateClassesForItem(
            capacityState.config,
            capacityState,
            "MASO",
            null,
            null,
            ["header"]
          )}
        >
          <CapacityHeaderItem source={"MASO"} hours={null} minutes={null} />
        </Grid>
        <Grid
          item
          style={{ width: "25%" }}
          className={generateClassesForItem(
            capacityState.config,
            capacityState,
            "ROZVOZ",
            null,
            null,
            ["header"]
          )}
        >
          <CapacityHeaderItem source={"ROZVOZ"} hours={null} minutes={null} />
        </Grid>
      </Grid>
    </>
  );
};

export default CapacityHeader;

import { makeStyles } from "@material-ui/core";
import { intervalSize } from "../../DateTimePicker/logicConstants";

export const generateKey = (hours, minutes) => {
  return [hours, minutes].join("__");
};

export const getItemWidth = (config, state, grow = 1) => {
  return (100 / (config.pointOfSales.length + 2)) * grow + "%";
};

export const getItemColor = (pct, third = 50) => {
  return [
    "hsl(",
    ((1 - pct) * 120).toString(10),
    ", 100%, " + third + "%)",
  ].join("");
};

export const getDateForDayOfWeek = (dayOfWeek) => {
  const now = new Date();
  const diff = (dayOfWeek + 7 - now.getDay()) % 7;
  now.setDate(now.getDate() + diff);
  return now;
};

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "table",
    height: "100%",
    "&:nth-child(3n)": {
      borderBottom: "solid 2px rgba(0, 0, 0, 0.5)",
    },
    "&:nth-child(12n)": {
      borderBottom: "solid 3px rgba(0, 0, 0, 0.75)",
    },
    "& .item": {
      border: "solid 1px rgba(0, 0, 0, 0.25)",
      padding: theme.spacing(0),
      display: "table-cell",
      verticalAlign: "middle",
      height: "100%",
    },
    "& .capacity": {
      background: "#fff",
    },
    "& .checked": {
      background: "rgba(255, 0, 0, 0.12)",
    },
    "& .header": {
      background: "rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(1.5),
    },
    "& .now": {
      background: "rgba(255, 255, 0, 0.12)",
    },
  },
}));

export const generateClassesForItem = (
  config,
  state,
  source,
  hours,
  minutes,
  initialClasses = []
) => {
  const classes = [...initialClasses];
  classes.push("item");
  if (
    state.checked.includes(generateKey(source, state.dayOfWeek, hours, minutes))
  ) {
    classes.push("checked");
  }
  if (
    state.checked.includes(
      generateKey(null, state.dayOfWeek, hours, minutes)
    ) &&
    state.checked.includes(generateKey(source, state.dayOfWeek, null, null))
  ) {
    classes.push("checked");
  }
  const now = new Date();
  const dtFrom = new Date(now);
  dtFrom.setHours(hours, minutes, now.getSeconds(), now.getMilliseconds());
  const dtTo = new Date(now);
  dtTo.setHours(hours, minutes, now.getSeconds(), now.getMilliseconds());
  dtTo.setTime(dtTo.getTime() + intervalSize * 60000);
  if (
    now.getDay() === state.dayOfWeek.index &&
    now.getTime() >= dtFrom.getTime() &&
    now.getTime() < dtTo.getTime()
  ) {
    classes.push("now");
  }
  return classes.join(" ");
};

import {
    additionItemNoSauce,
    addressXNA,
    commissionOriginCounter,
    commissionOriginTelephone,
    vosSections,
} from "../constants/constants";
import {
    initialCommissionStatus,
    initialCommissionStatusForTelephoneOrigin,
} from "../components/Admin/adminEnum";
import {formatDate, formatTime} from "./dateTime";
import {
    formatPrice,
    getCartPriceDelivery,
    getCartPriceSubtotal,
    getCartPriceTotal,
} from "./price";

export const getDeliveryTextForCart = (cart, pos) => {
    return (
        pos.textCart +
        (cart.customerAddress !== addressXNA ? " " + cart.customerAddress : "")
    );
};

export const showDeliveryInCart = (cart) => {
    return true;
};

export const getTotalQuantity = (cart) => {
    let q = 0;
    for (let item of cart.items) {
        q = q + item.quantity;
    }
    return q;
};

export const hasItemsInSection = (cart, section) => {
    return (
        cart.items.filter((cartItem) => {
            return (
                cartItem.product.sections &&
                cartItem.product.sections.includes(section.title)
            );
        }).length > 0 && !!cart.sections
    );
};

export const prepareCart = (cart, rangeOfGoods, pos) => {
    const copy = JSON.parse(JSON.stringify(cart)); //deep clone

    if (rangeOfGoods) {
        //Nápoje v menu (resp. doplňky v menu burgeru) přesunout do sekce samostatných produktů
        for (let cartItem of copy.items) {
            for (let additionItem of cartItem.additionItems) {
                if (additionItem.productFullTitle) {
                    const product = rangeOfGoods.products.find(
                        (p) => p.fullTitle === additionItem.productFullTitle
                    );
                    if (product) {
                        cartItem.additionItems = cartItem.additionItems.filter(
                            (ai) => ai.productFullTitle !== product.fullTitle
                        );
                        copy.items.push({
                            quantity: cartItem.quantity,
                            product,
                            additionItems: [],
                        });
                    }
                }
            }
        }
    }

    //Nastavit výchozí stav pro sekce VOS
    const sections = {};
    for (let vs of vosSections) {
        sections[vs.code] = false;
    }
    copy.sections = sections;

    //Nastavit výchozí stav objednávky
    copy.activeStatusCode = [
        commissionOriginTelephone,
        commissionOriginCounter,
    ].includes(copy.commissionOrigin)
        ? initialCommissionStatusForTelephoneOrigin
        : initialCommissionStatus;

    const d = new Date(cart.commissionTakeOverDateTime);

    //Datum a čas převzetí uložit jako UNIX timestamp
    copy.commissionTakeOverDateTimeStamp = d.getTime();

    //vypočítaná data
    copy.humanDeliveryText = getDeliveryTextForCart(cart, pos);
    copy.humanTakeOverDateTime =
        formatDate(d) +
        ", " +
        formatTime(
            d.getHours(),
            d.getMinutes(),
            pos.deliveryTimeWindowAsInterval,
            pos.deliveryTimeWindow * 1
        );
    copy.humanPriceSubtotal = getCartPriceSubtotal(cart);
    copy.humanPriceDelivery = getCartPriceDelivery(cart);
    copy.humanPriceTotal = getCartPriceTotal(cart);

    return copy;
};

export const getCartItemAdditionItemQuantityKey = (additionItem) => {
    return additionItem.groupTitle + ":" + additionItem.title;
}

export const getCartItemAdditionItemQuantity = (cartItem, additionItem, defaultValue) => {
    const key = getCartItemAdditionItemQuantityKey(additionItem);
    return (cartItem.hasOwnProperty('additionItemsQty') && cartItem.additionItemsQty.hasOwnProperty(key)) ? cartItem.additionItemsQty[key] : defaultValue;
}
import React from "react";
import CapacityGrid from "./CapacityGrid";
import CapacityHeader from "./CapacityHeader";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { getDailyOperatingHoursForPointOfSales } from "../../../functions/functions";
import { ACTION_TYPE_CHANGE } from "./reducers/capacityReducer";
import { intervalSize } from "../../DateTimePicker/logicConstants";

export const buildConfig = (pointOfSales) => {
  const dailyOperatingHours =
    getDailyOperatingHoursForPointOfSales(pointOfSales);
  const minH =
    Math.min(
      ...dailyOperatingHours.map((doh) => {
        return doh.operatingHours.from.hours;
      })
    ) - 2;
  const maxH = Math.max(
    ...dailyOperatingHours.map((doh) => {
      return doh.operatingHours.to.hours;
    })
  );
  const hours = [];
  for (let i = minH; i < maxH; i++) {
    hours.push(i);
  }
  const minutes = [];
  for (let i = 0; i < 60; i = i + intervalSize) {
    minutes.push(i);
  }
  return {
    pointOfSales,
    dailyOperatingHours,
    hours,
    minutes,
  };
};

const Capacity = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "capacities",
        value: props.capacities,
      },
    });
  }, [props.capacities]);
  //
  React.useEffect(() => {
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "commissions",
        value: props.commissions,
      },
    });
  }, [props.commissions]);

  React.useEffect(() => {
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "config",
        value: buildConfig(props.pointOfSales),
      },
    });
  }, [props.pointOfSales]);

  return (
    <Box style={{ padding: 16 }}>
      <Box
        style={{
          position: "sticky",
          top: 130,
          paddingTop: 32,
          background: "#ffffff",
          zIndex: 1,
        }}
      >
        <CapacityHeader onSubmit={props.onSubmit} />
      </Box>
      <Box>
        <CapacityGrid />
      </Box>
    </Box>
  );
};

export default Capacity;

import { intervalSize } from "./logicConstants";
import { getClosedDayForPointOfSaleAndDateTime } from "../../utils/delivery";
import {
  getActualPointsDifficulty,
  getCapacities,
} from "../../functions/functions";
import { getCartPriceSubtotalForCapacityCalculation } from "../../utils/price";
import { vosSectionMeat, vosSectionPizza } from "../../constants/constants";
import {
  SOURCE_MASO,
  SOURCE_PIZZA,
  SOURCE_ROZVOZ,
} from "../Admin/Capacity/utils";

export const isDisabled = (
  d,
  h,
  m,
  config,
  commission,
  commissions,
  makeTimeTests,
  capacities,
  settings,
  createdAt,
  internalCommissions
) => {

  if(commission.hasOwnProperty('capacityCheck') && commission.capacityCheck === false){
    return false;
  }

  let dayOfWeek = new Date(d).getDay(); //0 = Sunday, 1 = Monday
  dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek; //7 = Sunday, 1 = Monday

  const intervalStart = new Date();
  intervalStart.setHours(h, m, 0);

  const intervalEnd = new Date(intervalStart);
  intervalEnd.setTime(intervalEnd.getTime() + intervalSize * 60 * 1000);

  //předpokládáme, že na daný časový blok byla přiřazena aktuální objednávka, vytvoříme simulovanou objednávku (SO)
  const c = { ...commission };
  const dt = new Date(d);
  dt.setHours(h, m, 0, 0);
  c.commissionTakeOverDateTime = dt;
  c.commissionTakeOverDateTimeStamp = dt.getTime();

  //TEST: čas převzetí SO již byl
  if (makeTimeTests && c.commissionTakeOverDateTime < new Date()) {
    return true;
  }

  //TEST: čas převzetí SO je před časem prvního možného odběru
  const firstTakeOverAt = new Date(createdAt);
  firstTakeOverAt.setTime(
    firstTakeOverAt.getTime() + config.deliveryAtFirst * 60 * 1000
  );
  if (makeTimeTests && c.commissionTakeOverDateTime < firstTakeOverAt) {
    return true;
  }

  //TEST: čas převzetí SO je uvnitř času, během kterého se nerozváží
  let operationHoursStart = new Date(d);
  let operationHoursEnd = new Date(d);
  let splitted = config.pointOfSale["hours_" + dayOfWeek].split("-");
  operationHoursStart.setHours(
    splitted[0].trim().split(":")[0].trim() * 1,
    splitted[0].trim().split(":")[1].trim() * 1,
    0,
    0
  );
  operationHoursEnd.setHours(
    splitted[1].trim().split(":")[0].trim() * 1,
    splitted[1].trim().split(":")[1].trim() * 1,
    0,
    0
  );
  if (
    c.commissionTakeOverDateTime.getTime() < operationHoursStart.getTime() ||
    c.commissionTakeOverDateTime.getTime() >= operationHoursEnd.getTime()
  ) {
    return true;
  }

  //TEST: Čas převzetí je uvnitř času kdy je zavřeno dle Sortimentové tabulky
  if (
    getClosedDayForPointOfSaleAndDateTime(
      settings,
      c.pointOfSaleId,
      c.commissionTakeOverDateTime
    )
  ) {
    return true;
  }

  let pointsOfDifficultyPIZZA = getActualPointsDifficulty(
    commission,
    SOURCE_PIZZA
  );
  let pointsOfDifficultyMASO = getActualPointsDifficulty(
    commission,
    SOURCE_MASO
  );
  let pointsOfDifficultyROZVOZ = getActualPointsDifficulty(
    commission,
    SOURCE_ROZVOZ
  );

  //Získej aktuální kapacity pro objednávky vč. zadávané objednávky
  const calculatedCapacities = getCapacities(
    "Vyškov", //TODO - změnit, aby bylo dynamické - UB vs. Vyškov
    c.commissionTakeOverDateTime,
    {
      commissions: commissions.concat(c),
      capacities,
      internalCommissions,
    }
  );
  // console.log(h, m);
  // console.log(calculatedCapacities);
  // console.log(pointsOfDifficultyPIZZA);
  // console.log(pointsOfDifficultyMASO);
  // console.log(pointsOfDifficultyROZVOZ);
  for (let capacity of calculatedCapacities) {
    if (
      //vyfiltruje jenom ty aktuální kapacity, které obsahují zadávanou objednávku
      !capacity.commissions
        .map((c) => c.commissionTakeOverDateTimeStamp)
        .includes(c.commissionTakeOverDateTimeStamp)
    ) {
      continue;
    }

    // console.log(capacity);

    if (
      capacity.capacityDefinition.value === 0 &&
      capacity.capacityDefinition.source === SOURCE_PIZZA &&
      pointsOfDifficultyPIZZA > 0
    ) {
      return true;
    }

    if (
      capacity.capacityDefinition.value === 0 &&
      capacity.capacityDefinition.source === SOURCE_MASO &&
      pointsOfDifficultyMASO > 0
    ) {
      return true;
    }

    if (
      capacity.capacityDefinition.value === 0 &&
      capacity.capacityDefinition.source === SOURCE_ROZVOZ &&
      pointsOfDifficultyROZVOZ > 0
    ) {
      return true;
    }

    if (
      capacity.commissions.length - 1 !== 0 &&
      capacity.value > capacity.capacityDefinition.value
    ) {
      return true;
    }

    // if (
    //   capacity.capacityDefinition.pointsDifficultySectionPizza <= 0 &&
    //   capacity.capacityDefinition.pointsDifficultySectionMeat <= 0
    // ) {
    //   return true;
    // }
    //
    // if (
    //   capacity.commissions.length - 1 !== 0 &&
    //   (capacity.pointsDifficultySectionPizza >
    //     capacity.capacityDefinition.pointsDifficultySectionPizza ||
    //     capacity.pointsDifficultySectionMeat >
    //       capacity.capacityDefinition.pointsDifficultySectionMeat)
    // ) {
    //   return true;
    // }
    //
    // if (
    //   pointsDifficultySectionPizza > 0 &&
    //   capacity.capacityDefinition.pointsDifficultySectionPizza === 0
    // ) {
    //   return true;
    // }
    //
    // if (
    //   pointsDifficultySectionMeat > 0 &&
    //   capacity.capacityDefinition.pointsDifficultySectionMeat === 0
    // ) {
    //   return true;
    // }
    //
    // if (capacity.pointsDelivery > capacity.capacityDefinition.pointsDelivery) {
    //   return true;
    // }
  }

  return false;
};

export const isDisabledForInternalCommission = (
  d,
  h,
  m,
  commissions,
  makeTimeTests,
  capacities,
  settings,
  createdAt,
  internalCommission,
  internalCommissions
) => {
  const intervalStart = new Date();
  intervalStart.setHours(h, m, 0);

  const intervalEnd = new Date(intervalStart);
  intervalEnd.setTime(intervalEnd.getTime() + intervalSize * 60 * 1000);

  //předpokládáme, že na daný časový blok byla přiřazena aktuální objednávka, vytvoříme simulovanou objednávku (SO)
  const c = { ...internalCommission };
  const dt = new Date(d);
  dt.setHours(h, m, 0, 0);
  c.commissionTakeOverDateTime = dt;
  c.commissionTakeOverDateTimeStamp = dt.getTime();

  //TEST: čas převzetí SO již byl
  if (makeTimeTests && c.commissionTakeOverDateTime < new Date()) {
    return true;
  }

  //Získej aktuální kapacity pro objednávky vč. zadávané objednávky
  const calculatedCapacities = getCapacities(
    "Vyškov", //TODO - změnit, aby bylo dynamické - UB vs. Vyškov
    c.commissionTakeOverDateTime,
    {
      commissions: commissions,
      capacities,
      internalCommissions: internalCommissions.concat(c),
    }
  );
  for (let capacity of calculatedCapacities) {
    if (
      //vyfiltruje jenom ty aktuální kapacity, které obsahují zadávanou objednávku
      !capacity.commissions
        .map((c) => c.commissionTakeOverDateTimeStamp)
        .includes(c.commissionTakeOverDateTimeStamp)
    ) {
      continue;
    }

    if (c.source !== capacity.source) {
      continue;
    }

    if (
      capacity.commissions.length - 1 !== 0 &&
      capacity.value > capacity.capacityDefinition.value &&
      internalCommission.source === capacity.source
    ) {
      return true;
    }
  }

  return false;
};

import React, { useEffect, useState } from "react";
import { getTimeHHMMSS } from "../../utils/dateTime";

export const Clock = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return <>{getTimeHHMMSS(date)}</>;
};

export default Clock;

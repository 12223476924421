import {
  ADD_ITEM_TO_CART,
  APPLY_SALE_ACTIONS,
  CHANGE_CART_ITEM_QUANTITY_ADD,
  CHANGE_CART_ITEM_QUANTITY_SUB,
  EMPTY_CART,
  SET_CART,
  SET_POINT_OF_SALE,
  SET_TAKEOVER_DATETIME,
  UPDATE_CART_ITEM,
  UPDATE_COMMISSION,
} from "../constants/actionTypes";

import update from "immutability-helper";
import { addressXNA, commissionOriginInternet } from "../constants/constants";
import { initialCommissionStatus } from "../components/Admin/adminEnum";

export const initialState = {
  pointOfSaleId: null, //?string
  items: [], //array
  saleActions: [], //array
  servicePrice: 0, //integer
  customerFullName: "", //string
  customerEmail: "", //string
  customerTelephone: "", //string
  customerAddress: addressXNA, //string
  commissionComment: "", //string
  commissionTextOnTheBox: "", //string
  commissionTakeOverDateTime: null, //integer
  commissionPaymentMethod: null,
  customerCheckTerms: false, //bool
  customerCheckMarketing: false, //bool
  activeStatusCode: initialCommissionStatus, //string,
  commissionOrigin: commissionOriginInternet,
  createdByJobPosition: null,
  isCounterSale: false,
  takeAway: true,
  commissionCommentInternal: "",
  isSectionPizzaBaked: false,
  capacityCheck: true
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART: {
      return action.payload.cart;
    }
    case EMPTY_CART: {
      return { ...initialState };
    }
    case SET_POINT_OF_SALE: {
      const stateCopy = { ...state };
      stateCopy.pointOfSaleId = action.payload.pointOfSale.id;
      stateCopy.customerAddress = action.payload.address;
      stateCopy.servicePrice = action.payload.deliveryPrice;

      return stateCopy;
    }
    case ADD_ITEM_TO_CART: {
      const stateCopy = update(state, {
        items: { $push: [action.payload] },
      });
      stateCopy.commissionTakeOverDateTime = null;
      return stateCopy;
    }
    case UPDATE_CART_ITEM: {
      const { cartItem, index } = action.payload;

      const copy = { ...state };

      const copyItems = [...state.items];
      copyItems[index] = cartItem;

      copy.items = copyItems;
      copy.commissionTakeOverDateTime = null;

      return copy;
    }
    case CHANGE_CART_ITEM_QUANTITY_ADD: {
      const index = state.items.indexOf(action.payload.cartItem);

      const stateCopy = { ...state };
      const cartItemCopy = { ...action.payload.cartItem };

      cartItemCopy.quantity = cartItemCopy.quantity + 1;
      stateCopy.items[index] = cartItemCopy;
      stateCopy.commissionTakeOverDateTime = null;

      return stateCopy;
    }
    case CHANGE_CART_ITEM_QUANTITY_SUB: {
      const index = state.items.indexOf(action.payload.cartItem);

      const stateCopy = { ...state };
      const cartItemCopy = { ...action.payload.cartItem };

      if (cartItemCopy.quantity === 1) {
        stateCopy.items.splice(index, 1);
        stateCopy.commissionTakeOverDateTime = null;
      } else {
        cartItemCopy.quantity = cartItemCopy.quantity - 1;
        stateCopy.items[index] = cartItemCopy;
        stateCopy.commissionTakeOverDateTime = null;
      }

      return stateCopy;
    }
    case APPLY_SALE_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.saleActions = action.payload;

      return stateCopy;
    }
    case UPDATE_COMMISSION: {
      return { ...state, [action.payload.property]: action.payload.value };
    }
    case SET_TAKEOVER_DATETIME: {
      const stateCopy = { ...state };
      stateCopy.commissionTakeOverDateTime =
        action.payload.commissionTakeOverDateTime;
      stateCopy.servicePrice = action.payload.servicePrice;

      return stateCopy;
    }
    default: {
      return state;
    }
  }
};

export default cartReducer;

import React from "react";
import { Box, Button, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import ForwardIcon from "@material-ui/icons/Forward";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: grey[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -24,
    marginLeft: -24,
  },
}));

const ThemedButton = (props) => {
  const { nextStatus, onClick } = props;

  const classes = useStyles();

  const theme = createMuiTheme({ palette: { primary: nextStatus.color } });

  return (
    <MuiThemeProvider theme={theme}>
      <Box className={classes.wrapper}>
        <Button
          startIcon={<ForwardIcon />}
          style={{
            fontSize: "125%",
          }}
          fullWidth={true}
          color={"primary"}
          size={"large"}
          variant={"outlined"}
          onClick={() => {
            onClick(nextStatus);
          }}
        >
          {nextStatus.btnTitle}
        </Button>
      </Box>
    </MuiThemeProvider>
  );
};

export default ThemedButton;

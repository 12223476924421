import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, makeStyles } from "@material-ui/core";
import ProductList from "../../components/ProductList/ProductList";
import { contextAdmin } from "../../constants/contexts";
import Sticky from "react-stickynode";
import CommissionNew2 from "../../components/Admin/CommissionNew/CommissionNew2";

const useStyles = makeStyles((theme) => ({
  cart: {
    backgroundColor: "#ffffe0",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  cartContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    fontSize: "150%",
    fontWeight: 900,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const CommissionNewView = () => {
  const classes = useStyles();
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);
  const cart = useSelector((state) => state.cart);

  return (
    <Grid container id="content">
      <Grid
        item
        md={9}
        xs={9}
        style={{ opacity: !cart.createdByJobPosition ? 0.1 : 1 }}
      >
        <ProductList
          context={contextAdmin}
          disabled={!cart.createdByJobPosition}
        />
      </Grid>
      <Grid item md={3} xs={3} className={classes.cart}>
        <Box>
          <Sticky
            enabled={true}
            top={"#appBar"}
            bottomBoundary={"#content"}
            innerZ={1}
          >
            <Box className={classes.cartContainer} pt={3}>
              <CommissionNew2 localBusiness={localBusiness} />
            </Box>
          </Sticky>
        </Box>
      </Grid>
    </Grid>
  );

  // return <CommissionNew localBusiness={localBusiness} />;
};

export default React.memo(CommissionNewView);

import React, { useRef } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Cart from "../Cart/Cart";
import { contextCheckout } from "../../constants/contexts";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setTakeOverDateTime,
  updateCommission,
} from "../../actions/cartActions";
import axios from "axios";
import {
  apiGetAdminCapacity,
  apiGetCommissionCapacity,
  apiGetInternalCommissionCapacity,
  apiPostCommission,
} from "../../api/endpoints";
import CheckoutForm, { contextCheckoutForm_Checkout } from "./CheckoutForm";
import { prepareCart } from "../../utils/cart";
import { csobPaymentTitle } from "../../utils/csob";
import { isDisabled } from "../DateTimePicker/logicIsDisabled";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  columnTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  buttonChooseDateTime: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& a": {
      color: theme.palette.primary.contrastText,
      // fontSize: "250%",
      textDecoration: "none",
    },
  },
  button: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: "150%",
    textDecoration: "none",
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  gridTitle: {
    fontWeight: 600,
  },
  gridValue: {
    fontWeight: 200,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
  },
  formControl: {
    "& .MuiSelect-selectMenu": {
      whiteSpace: "normal !important",
    },
  },
  checkoutRow: {
    display: "flex",

    alignItems: "center",
    flexDirection: "column",
    "& > div:first-child": {
      textAlign: "center",
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      "& > div:first-child": {
        textAlign: "left",
        marginBottom: theme.spacing(0.5),
      },
    },
  },
}));

const Checkout = (props) => {
  const classes = useStyles();
  const settings = useSelector((state) => state.settings);
  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const history = useHistory();

  const [checkoutResponse, setCheckoutResponse] = React.useState({
    loading: false,
    redirectUrl: null,
  });

  const childRef = useRef();

  const paymentMethods = settings.paymentMethods.filter((pm) =>
    pm.pointOfSales.includes(cart.pointOfSaleId)
  );

  React.useEffect(() => {
    if (!cart.paymentMethod) {
      dispatch(
        updateCommission("commissionPaymentMethod", paymentMethods[0].title)
      );
    }
  }, [settings]);

  const schema = yup.object().shape({
    customerFullName: yup
      .string()
      .required("Jméno a příjmení je povinný údaj."),
    customerEmail: yup
      .string()
      .required("E-mail je povinný údaj.")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "E-mail nemá správný formát."
      ),
    customerTelephone: yup
      .string()
      .required("Telefon je povinný údaj.")
      .matches(/^(\+)?[- 0-9]{9,}$/, "Telefon nemá správný formát."),
    commissionTakeOverDateTime: yup
      .mixed()
      .required("Datum a čas převzetí je povinný údaj."),
    customerCheckTerms: yup
      .boolean()
      .oneOf(
        [true],
        "Pro pokračování je nutné souhlasit s Obchodními podmínkami."
      ),
  });

  const getError = (path) => {
    let error = errors.find((e) => e.path === path);
    if (error) {
      return error.message;
    }
    return "";
  };

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      dispatch(updateCommission(event.target.name, event.target.checked));
    } else {
      dispatch(updateCommission(event.target.name, event.target.value));
    }
  };

  const handleChangeTakeoverDateTime = (datetime, servicePrice) => {
    dispatch(setTakeOverDateTime(datetime, servicePrice));
  };

  const handleSubmit = () => {
    setCheckoutResponse({
      loading: true,
      redirectUrl: null,
    });
    const returnUrl = window.location.href;
    schema
      .validate(cart, { abortEarly: false })
      .then(() => {
        const pos = settings.pointOfSales.find(
          (pos) => pos.id === cart.pointOfSaleId
        );
        const preparedCart = prepareCart(cart, rangeOfGoods, pos);
        childRef.current.makeCheck(preparedCart).then((canBeSent) => {
          // console.log(canBeSent);
          if (canBeSent) {
            //objednávka se odesílá do na server
            const data = {
              returnUrl,
              content: preparedCart,
            };
            axios
              .post(apiPostCommission(), JSON.stringify(data))
              .then((response) => {
                if (response.status === 201) {
                  //objednávka založena
                  if (
                    response.data.commission.content.commissionPaymentMethod ===
                    csobPaymentTitle
                  ) {
                    //zákazník platí kartou online
                    window.location.replace(response.data.processUrl);
                    //window.location.href = response.data.processUrl; //tento způsob redirectu resetuje store
                  } else {
                    setCheckoutResponse({
                      loading: false,
                      redirectUrl:
                        "/checkout/" + response.data.commission.content.uid,
                      // redirectUrl: "/checkout/failure",
                    });
                  }
                } else {
                  //objednávka nebyla založena
                  setCheckoutResponse({
                    loading: false,
                    redirectUrl: "/checkout/failure",
                  });
                }
                //požadavek vrací výsledek
                //1 - objednávka založena
                //2 - objednávka nebyla založena
                //3 - je nutné zaplatit objednávku -> link na platební bránu
              })
              .catch((err) => {
                //objednávka nebyla založena
                setCheckoutResponse({
                  loading: false,
                  redirectUrl: "/checkout/failure",
                });
              });
          } else {
            setErrors([
              {
                path: "commissionTakeOverDateTime",
                message: "Takové datum a čas převzetí již nelze zvolit.",
              },
            ]);
            setCheckoutResponse({
              loading: false,
              redirectUrl: null,
            });
          }
        });
      })
      .catch((err) => {
        const errors = [];
        err.inner.forEach((e) => {
          errors.push(e);
        });
        setErrors(errors);
        setCheckoutResponse({
          loading: false,
          redirectUrl: null,
        });
      });
  };

  if (checkoutResponse.redirectUrl !== null) {
    return <Redirect to={checkoutResponse.redirectUrl} />;
  }

  return (
    <Container>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <Typography variant={"h4"} className={classes.columnTitle}>
            Přehled objednávky
          </Typography>
          <Cart cart={cart} editable={false} context={contextCheckout} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h4"} className={classes.columnTitle}>
            Informace o zákazníkovi
          </Typography>
          <CheckoutForm
            getError={getError}
            cart={cart}
            handleChange={handleChange}
            context={contextCheckoutForm_Checkout}
          />
          <Box className={classes.checkoutRow}>
            <Box
              flexGrow={1}
              style={{ paddingLeft: 12, color: "rgba(0, 0, 0, 0.75)" }}
            >
              Datum a čas převzetí:
              {getError("commissionTakeOverDateTime").length > 1 && (
                <>
                  <Box className={classes.error}>
                    {getError("commissionTakeOverDateTime")}
                  </Box>
                </>
              )}
            </Box>
            <Box>
              <DateTimePicker
                onSubmit={handleChangeTakeoverDateTime}
                ref={childRef}
              />
            </Box>
          </Box>
          <Box className={classes.checkoutRow} mt={2}>
            <Box
              flexGrow={1}
              style={{ paddingLeft: 12, color: "rgba(0, 0, 0, 0.75)" }}
            >
              Způsob platby:
              {getError("commissionPaymentMethod").length > 1 && (
                <>
                  <Box className={classes.error}>
                    {getError("commissionPaymentMethod")}
                  </Box>
                </>
              )}
            </Box>
            <Box>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size={"small"}
              >
                <Select
                  value={cart.commissionPaymentMethod}
                  onChange={handleChange}
                  name={"commissionPaymentMethod"}
                >
                  {paymentMethods.map((pm) => {
                    return (
                      <MenuItem
                        style={{ whiteSpace: "normal !important" }}
                        value={pm.title}
                      >
                        {pm.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer}>
        <Grid item xs={12} style={{ textAlign: "center", marginBottom: 8 * 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cart.customerCheckTerms}
                onChange={handleChange}
                name="customerCheckTerms"
                color="primary"
              />
            }
            label="Souhlasím s Obchodními podmínkami"
          />
          {getError("customerCheckTerms").length > 1 && (
            <>
              <Box className={classes.error}>
                {getError("customerCheckTerms")}
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cart.customerCheckMarketing}
                onChange={handleChange}
                name="customerCheckMarketing"
                color="primary"
              />
            }
            label="Souhlasím se zpracováním osobních údajů za účelem zasílání nabídek a slev"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: 32 }}>
          <Button
            color={"primary"}
            variant="contained"
            size="large"
            disabled={checkoutResponse.loading}
            className={classes.button}
            onClick={handleSubmit}
          >
            Závazně objednat
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Checkout;

import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hourContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  hourRow: {},
  hourBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: 500,
    fontSize: "110%",
    textAlign: "center",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  timeBox: {
    padding: theme.spacing(0.5),
    "&:last-child": {
      paddingRight: theme.spacing(2),
    },
    verticalAlign: "top",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  timeButton: {
    fontWeight: 600,
    "& .MuiButton-label": {
      display: "block",
    },
    "& .status": {
      fontWeight: 400,
      fontSize: "85%",
      minWidth: 62,
      textAlign: "center",
    },
  },
}));

const TimeObjectStatus = ({ timeObject }) => {
  const now = new Date();
  // console.log(now.getTime(), timeObject.datetime.getTime());
  if (!timeObject.isDisabled) {
    return <Box className={"status"}>{timeObject.price}</Box>;
  }
  if (timeObject.isDisabled && now.getTime() <= timeObject.datetime.getTime()) {
    return (
      <Box className={"status"} style={{ color: "red" }}>
        obsazeno
      </Box>
    );
  }

  return <Box className={"status"}>&nbsp;</Box>;
};

const HourContainer = (props) => {
  const { config, handleSelect, buildTimeObject } = props;

  const classes = useStyles();

  return (
    <Box className={classes.hourContainer}>
      <Table>
        <TableBody>
          {config.hours.map((h) => {
            return (
              <TableRow className={classes.hourRow} key={h}>
                <TableCell className={classes.hourBox}>{h}&nbsp;h</TableCell>
                {config.minutes.map((m, index) => {
                  const timeObject = buildTimeObject(h, m);
                  return (
                    <TableCell className={classes.timeBox} key={index}>
                      <Button
                        className={classes.timeButton}
                        fullWidth
                        size={"small"}
                        color={timeObject.isSelected ? "primary" : "secondary"}
                        variant={"contained"}
                        disabled={timeObject.isDisabled}
                        onClick={() => {
                          handleSelect(h, m, timeObject.servicePrice);
                        }}
                      >
                        <Box>{timeObject.title}</Box>
                        <TimeObjectStatus timeObject={timeObject} />
                      </Button>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default HourContainer;

import {
  FETCH_RANGE_OF_GOODS_INIT,
  FETCH_RANGE_OF_GOODS_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  categories: [],
  products: [],
  additionItems: [],
  additionGroups: [],
};

const rangeOfGoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANGE_OF_GOODS_INIT: {
      return state;
    }
    case FETCH_RANGE_OF_GOODS_SUCCESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default rangeOfGoodsReducer;

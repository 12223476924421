import {
  FETCH_ADMIN_CAPACITY_INIT,
  FETCH_ADMIN_CAPACITY_SUCCESS,
} from "../constants/actionTypes";
import { apiGetAdminCapacity, apiPutAdminCapacity } from "../api/endpoints";
import axios from "axios";

export const fetchAdminCapacity = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ADMIN_CAPACITY_INIT });
    axios.get(apiGetAdminCapacity()).then((response) => {
      dispatch({
        type: FETCH_ADMIN_CAPACITY_SUCCESS,
        payload: response.data.content,
      });
    });
  };
};

export const saveAdminCapacity = (data) => {
  return (dispatch) => {
    axios.put(apiPutAdminCapacity(), { content: data }).then((response) => {
      dispatch({
        type: FETCH_ADMIN_CAPACITY_SUCCESS,
        payload: response.data.content,
      });
    });
  };
};

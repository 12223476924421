import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";

import Cart from "../Cart/Cart";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { contextHome } from "../../constants/contexts";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    "& .title": {
      fontSize: "110%",
      fontWeight: 800,
    },
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
}));

const CartDialog = (props) => {
  const { open, cart, editable, handleClose } = props;

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = false;

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle className={classes.dialogTitle}>
        <Box style={{ display: "flex" }}>
          <Box style={{ flexGrow: 1 }}>
            <Box className={"title"}>Vaše objednávka</Box>
          </Box>
          <Box>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Cart cart={cart} editable={editable} context={contextHome} />
      </DialogContent>
    </Dialog>
  );
};

export default CartDialog;

export const localStorageAuthVar = "auth";

export const roleAdmin = "admin"; //bůh / olymp / nejvyšší uživatel
export const roleLocalBusinessAdmin = "localBusiness-admin"; // vedoucí pobočky
export const roleLocalBusiness = "localBusiness"; // pracovník pobočky

const naiveLogins = [
  {
    loginName: "admin",
    password: "SpizzaJeTop",
    localBusinessTitle: "Vyškov",
    role: roleAdmin,
    useSound: true,
  },
  {
    loginName: "vyskov",
    password: "SpizzaJe1",
    localBusinessTitle: "Vyškov",
    role: roleLocalBusiness,
    useSound: false,
  },
  {
    loginName: "vyskov-admin",
    password: "SpizzaJeNej",
    localBusinessTitle: "Vyškov",
    role: roleLocalBusinessAdmin,
    useSound: false,
  },
  {
    loginName: "uhersky-brod",
    password: "SpizzaJe1",
    localBusinessTitle: "Uherský Brod",
    role: roleLocalBusiness,
    useSound: true,
  },
  {
    loginName: "uhersky-brod-admin",
    password: "SpizzaJeNej",
    localBusinessTitle: "Uherský Brod",
    role: roleLocalBusinessAdmin,
    useSound: true,
  },
];

export const setAuth = (check) => {
  window.localStorage.setItem(
    localStorageAuthVar,
    JSON.stringify({ ...check, password: null })
  );
};

export const getAuth = () => {
  return JSON.parse(window.localStorage.getItem(localStorageAuthVar));
};

export const removeAuth = () => {
  window.localStorage.removeItem(localStorageAuthVar);
};

export const isAuth = () => {
  const auth = getAuth();
  return !!auth;
};

export const checkNaiveAuth = (loginName, password) => {
  return naiveLogins.find(
    (l) => l.loginName === loginName && l.password === password
  );
};

import { getCommissionStatusesAll } from "../adminEnum";
import { Box, Chip } from "@material-ui/core";
import React from "react";
import { getCommissionNumberLb } from "../../../functions/functions";
import { pointOfSaleTitle } from "../../../constants/pointOfSales";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_TYPE_CHANGE } from "./reducers/capacityReducer";
import { formatDatetimeAdmin } from "../../../utils/dateTime";

const CapacityGridItemCommissionChip = (props) => {
  const { commission } = props;
  const status = getCommissionStatusesAll().find(
    (cs) => cs.code === commission.activeStatusCode
  );

  const dispatch = useDispatch();
  const selectedCommissionId = useSelector(
    (state) => state.capacity.selectedCommissionId
  );

  const handleClick = () => {
    dispatch({
      type: ACTION_TYPE_CHANGE,
      payload: {
        name: "selectedCommissionId",
        value: commission.uid === selectedCommissionId ? "" : commission.uid,
      },
    });
  };

  if (commission.hasOwnProperty("internal") && commission.internal) {
    return (
      <>
        <Chip
          size={"small"}
          label={
            <Box display={"flex"}>
              <Box
                style={{
                  marginRight: 4,
                }}
              >
                {commission.type === "pauza" ? "Přestávka" : "Výroba na pult"}
              </Box>
              <Box>
                <strong>
                  {commission.type === "pauza"
                    ? commission.value + "m"
                    : commission.value + "x"}
                </strong>
              </Box>
            </Box>
          }
          style={{
            backgroundColor: "#fff",
          }}
        />
      </>
    );
  }

  return (
    <>
      <Chip
        onClick={handleClick}
        size={"small"}
        label={
          <>
            <strong>{getCommissionNumberLb(commission)}</strong>{" "}
            {
              formatDatetimeAdmin(commission.commissionTakeOverDateTimeStamp)
                .hhmm
            }{" "}
            <small>{pointOfSaleTitle[commission.pointOfSaleId]}</small>
          </>
        }
        style={{
          backgroundColor: status.color[200],
          border:
            commission.uid === selectedCommissionId ? "solid 4px red" : "none",
        }}
      />
    </>
  );
};

export default CapacityGridItemCommissionChip;

import React from "react";
import InternalCommissionPrescription from "../../components/Admin/Capacity/InternalCommissionPrescription/InternalCommissionPrescription";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

const InternalCommissionPrescriptionView = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);

  return (
    <Box style={{ padding: 2 * 8 }}>
      <InternalCommissionPrescription />
    </Box>
  );
};

export default InternalCommissionPrescriptionView;

import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Cart from "../Cart/Cart";
import { formatDate, formatTime } from "../../utils/dateTime";
import { addressXNA } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelledStatusCode,
  getCommissionStatusesAll,
  initialCommissionStatus,
  newStatusCode,
} from "../Admin/adminEnum";
import { emptyCart } from "../../actions/cartActions";
import { csobPaymentStatuses, csobPaymentTitle } from "../../utils/csob";
import { Redirect } from "react-router-dom";
import { contextHome, contextReport } from "../../constants/contexts";
import {
  isPaymentMethodPaymentOnline,
  isPaymentMethodPaymentOnlinePaid,
} from "../../utils/payment";
import { SET_CART } from "../../constants/actionTypes";
import { fetchRangeOfGoods } from "../../actions/rangeOfGoodsActions";
import axios from "axios";
import { apiPutCommission } from "../../api/endpoints";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& .title": {
      marginBottom: theme.spacing(3),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
  },
  columnTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
}));

const CheckoutDone = (props) => {
  const { commission, status } = props;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const settings = useSelector((state) => state.settings);

  const pointOfSale = useSelector((state) => {
    return state.settings.pointOfSales.find((pos) => {
      return pos.id === commission.pointOfSaleId;
    });
  });

  const localBusiness = useSelector((state) => {
    return state.settings.localBusinesses.find((lb) => {
      return (
        lb.title ===
        state.settings.pointOfSales.find((pos) => {
          return pos.id === commission.pointOfSaleId;
        }).localBusinessTitle
      );
    });
  });

  React.useEffect(() => {
    if (
      commission.commissionPaymentMethod !== csobPaymentTitle && //objednávka není placena přes platební bránu
      commission.activeStatusCode === initialCommissionStatus //objednávka je nová
    ) {
      dispatch(emptyCart());
    }
    if (
      commission.commissionPaymentMethod === csobPaymentTitle && //objednávka je placena přes platební bránu
      csobPaymentStatuses.find((cps) => {
        return cps.csobTitle === commission.csobPayStatus;
      }).paid && //platba je provedena
      commission.activeStatusCode === initialCommissionStatus //objednávka je nová
    ) {
      dispatch(emptyCart());
    }
  }, [commission]);

  const classes = useStyles();

  const formatDateTimeFromString = (dString) => {
    const dt = new Date(dString);
    const date = formatDate(dt);
    const time = formatTime(
      dt.getHours(),
      dt.getMinutes(),
      pointOfSale.deliveryTimeWindowAsInterval,
      pointOfSale.deliveryTimeWindow
    );
    return date + ", " + time;
  };

  React.useEffect(() => {
    if (commission.hasOwnProperty("number")) {
      if (
        commission.activeStatusCode === newStatusCode &&
        isPaymentMethodPaymentOnline(commission) &&
        !isPaymentMethodPaymentOnlinePaid(commission)
      ) {
        const commissionCopy = {
          ...commission,
          activeStatusCode: cancelledStatusCode,
        };
        axios.put(apiPutCommission(commission.uid), commissionCopy);

        const apiCode = pointOfSale
          ? pointOfSale.apiCode
          : settings.pointOfSales[0].apiCode;
        dispatch(fetchRangeOfGoods(apiCode, contextHome));

        dispatch({
          type: SET_CART,
          payload: {
            cart: {
              ...commission,
              activeStatusCode: initialCommissionStatus,
              commissionTakeOverDateTime: null, //integer
              commissionPaymentMethod: null,
            },
          },
        });
      }
    }
  }, [commission]);

  if (!localBusiness) {
    return <></>;
  }

  // if (
  //   commission.commissionPaymentMethod === csobPaymentTitle && //objednávka je placena přes platební bránu
  //   !csobPaymentStatuses.find((cps) => {
  //     return cps.csobTitle === commission.csobPayStatus;
  //   }).paid && //platba není provedena
  //   commission.activeStatusCode === initialCommissionStatus //objednávka je nová
  // ) {
  //   return <Redirect to={"/checkout/failure"} />;
  // }

  if (
    cart.hasOwnProperty("number") &&
    isPaymentMethodPaymentOnline(commission) &&
    !isPaymentMethodPaymentOnlinePaid(commission)
  ) {
    return <Redirect to={"/checkout/failure"} />;
  }

  return (
    <Container>
      <Grid container spacing={10} style={{ marginBottom: 16 }}>
        <Grid item xs={12} className={classes.pageTitle}>
          <Typography variant={"h3"} className={"title"}>
            Objednávka byla vytvořena!
          </Typography>
          <Typography>
            Objednávka získala číslo: <strong>{commission.numberPosDay}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h4"} className={classes.columnTitle}>
            Přehled objednávky
          </Typography>
          <Cart cart={commission} editable={false} context={contextReport} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={"h4"} className={classes.columnTitle}>
            Informace o zákazníkovi
          </Typography>
          <Box mb={1}>
            <Typography variant={"caption"}>Jméno a příjmení</Typography>
            <Typography>{commission.customerFullName}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>E-mail</Typography>
            <Typography>{commission.customerEmail}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Telefon</Typography>
            <Typography>{commission.customerTelephone}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Text na krabici</Typography>
            <Typography>{commission.commissionTextOnTheBox}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Poznámka</Typography>
            <Typography>{commission.commissionComment}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Datum a čas převzetí</Typography>
            <Typography>
              {formatDateTimeFromString(commission.commissionTakeOverDateTime)}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Způsob platby</Typography>
            <Typography>{commission.commissionPaymentMethod}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant={"caption"}>Stav objednávky</Typography>
            <Typography>
              {
                getCommissionStatusesAll().find(
                  (cs) => cs.code === commission.activeStatusCode
                )?.title
              }
            </Typography>
          </Box>
          {commission.commissionPaymentMethod === csobPaymentTitle && (
            <Box mb={1}>
              <Typography variant={"caption"}>Stav platby</Typography>
              <Typography>
                {
                  csobPaymentStatuses.find((cps) => {
                    return cps.csobTitle === commission.csobPayStatus;
                  }).title
                }
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutDone;

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React from "react";
import { useSelector } from "react-redux";
import { getBigImgSrcForSlider } from "../../utils/imgSrc";

const HomeSwiper = (props) => {
  const { initialPointOfSaleId = null } = props;

  const settings = useSelector((state) => state.settings);
  const pointOfSale = useSelector((state) =>
    state.settings.pointOfSales.find(
      (pos) => pos.id === state.cart.pointOfSaleId
    )
  );

  const [sliders, setSliders] = React.useState([]);
  const [autoPlay, setAutoplay] = React.useState(false);

  let carousel = React.useRef(null);

  React.useEffect(() => {
    const loadSliders = (pointOfSaleId) => {
      setSliders(
        settings.sliders
          .filter((s) => {
            return s.pointOfSales.includes(pointOfSaleId);
          })
          .map((s) => {
            return getBigImgSrcForSlider(s);
          })
      );
    };
    if (initialPointOfSaleId) {
      loadSliders(initialPointOfSaleId);
    } else {
      loadSliders(pointOfSale ? pointOfSale.id : settings.pointOfSales[0].id);
    }
  }, [pointOfSale, settings]);

  React.useEffect(() => {
    // carousel.state.selectedItem = 0; // je to zakomentované, protože
    //carousel.setPosition(0, true); // z nějakého důvodu, když se nastavuje position, nebo selectedItem, tak se nefunguje autoplay a loop
    setAutoplay(true);
  }, [sliders]);

  return (
    <div>
      <Carousel
        ref={(el) => (carousel = el)}
        showArrows={false}
        showThumbs={false}
        autoPlay={autoPlay}
        infiniteLoop={true}
        showStatus={false}
        interval={7500}
        transitionTime={1500}
      >
        {sliders.map((slider) => {
          return (
            <div key={sliders.indexOf(slider)}>
              <img src={slider} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HomeSwiper;

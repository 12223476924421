import { Box } from "@material-ui/core";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  firebaseDb,
  firebaseDbPathCommission,
} from "../../service/firebaseConfig";
import { posVyskovKiosek, posVyskovStanek } from "../../constants/pointOfSales";
import {
  acceptedStatusCode,
  cancelledStatusCode,
  finishedStatusCode,
  inProductionStatusCode,
  newStatusCode,
  onWayStatusCode,
} from "../Admin/adminEnum";
import HomeSwiper from "../HomeSwiper/HomeSwiper";

const getStartAt = () => {
  let startAt = new Date();
  startAt.setHours(0, 0, 0, 0);
  startAt = startAt.getTime();

  return startAt;
};

const getEndAt = (days = 0) => {
  let endAt = new Date();
  endAt.setDate(endAt.getDate() + days);
  endAt.setHours(23, 59, 59, 0);
  endAt = endAt.getTime();

  return endAt;
};

const CommissionBox = (props) => {
  const { number, firebaseKey } = props;

  const dispatch = useDispatch();

  const loadCommission = async (firebaseKey) => {
    firebaseDb
      .ref(firebaseDbPathCommission)
      .child(firebaseKey)
      .on("value", (snapshot) => {
        dispatch({
          type: "COMMISSIONS_VALUE",
          payload: {
            firebaseKey,
            commission: snapshot.val(),
          },
        });
      });
  };

  React.useEffect(() => {
    loadCommission(firebaseKey);
  }, [firebaseKey]);

  return <>{number}</>;
};

const CommissionCustomer = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const velikost = params["velikost"] * 1 || 150;
  const typ = params["typ"] || "pult"; //kiosek

  const columns = [5 * 132, typ === "kiosek" ? 500 : 160, 5 * 132];
  const columnsTotal = columns[0] + columns[1] + columns[2];
  const tileSize = velikost;
  const tilePadding = tileSize / 15;
  const tileFont = tileSize / 1.76;

  const dispatch = useDispatch();
  const commissions = useSelector((state) => state.admin.commissions);
  const commissionsKeys = useSelector((state) => state.admin.commissionsKeys);

  const [loading, setLoading] = React.useState(true);

  const initLoad = async () => {
    setLoading(true);
    const commissions = {};
    await firebaseDb
      .ref(firebaseDbPathCommission)
      .orderByChild("commissionTakeOverDateTimeStamp")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          const firebaseKey = snap.key;
          const commission = snap.val();
          if (commission.localBusiness === "Vyškov") {
            commissions[firebaseKey] = commission;
          }
        });
      });
    dispatch({
      type: "ADMIN_COMMISSIONS_SET",
      payload: {
        commissions,
      },
    });
    setLoading(false);
  };

  const initObserver = async () => {
    firebaseDb
      .ref(firebaseDbPathCommission)
      .orderByChild("commissionTakeOverDateTimeStamp")
      .startAt(getStartAt())
      .endAt(getEndAt())
      .on("child_added", (snapshot) => {
        const firebaseKey = snapshot.key;
        const commission = snapshot.val();
        if (commission.localBusiness === "Vyškov") {
          if (!commissionsKeys.includes(firebaseKey)) {
            dispatch({
              type: "ADMIN_COMMISSIONS_ADD",
              payload: {
                firebaseKey,
                commission,
              },
            });
          }
        }
      });
  };

  React.useEffect(() => {
    initLoad();
    initObserver();
  }, []);

  const getCommissions = (type) => {
    switch (type) {
      case "v_priprave": {
        return commissionsKeys
          .filter((key) => {
            const c = commissions[key];
            return (
              [posVyskovStanek, posVyskovKiosek].includes(c.pointOfSaleId) &&
              [acceptedStatusCode, inProductionStatusCode].includes(
                c.activeStatusCode
              )
            );
          })
          .sort((a, b) => {
            return (
              commissions[a].commissionTakeOverDateTimeStamp -
              commissions[b].commissionTakeOverDateTimeStamp
            );
          });
      }
      case "k_vyzvednuti": {
        return commissionsKeys
          .filter((key) => {
            const c = commissions[key];
            return (
              [posVyskovStanek, posVyskovKiosek].includes(c.pointOfSaleId) &&
              [finishedStatusCode].includes(c.activeStatusCode)
            );
          })
          .sort((a, b) => {
            return (
              commissions[a].commissionTakeOverDateTimeStamp -
              commissions[b].commissionTakeOverDateTimeStamp
            );
          });
      }
      case "other": {
        return commissionsKeys
          .filter((key) => {
            const c = commissions[key];
            return (
              [posVyskovStanek, posVyskovKiosek].includes(c.pointOfSaleId) &&
              [newStatusCode, cancelledStatusCode, onWayStatusCode].includes(
                c.activeStatusCode
              )
            );
          })
          .sort((a, b) => {
            return (
              commissions[a].commissionTakeOverDateTimeStamp -
              commissions[b].commissionTakeOverDateTimeStamp
            );
          });
      }
    }
    return [];
  };

  const getCommissionNumber = (key) => {
    return commissions[key].numberPosDay;
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        width: "100%",
      }}
    >
      {typ === "kiosek" && (
        <Box>
          <HomeSwiper initialPointOfSaleId={posVyskovKiosek} />
        </Box>
      )}
      <Box
        style={{
          flexGrow: 1,
          color: "#fff",
          backgroundColor: "#000",
          backgroundImage: 'url("/assets/customer/background.png")',
          backgroundSize: "cover",
          maxWidth: "100%",
          minWidth: "100%",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: typ === "kiosek" ? 50 : 50,
            paddingLeft: typ === "kiosek" ? 20 : 50,
            paddingRight: typ === "kiosek" ? 20 : 50,
            maxHeight: "100%",
            minHeight: "100%",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <Box style={{ paddingLeft: typ === "kiosek" ? 30 : 190, paddingRight: typ === "kiosek" ? 0 : 150 }}>
            <img
              src={"/assets/customer/header.png"}
              style={{ width: "100%" }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexGrow: 1,
              height: "75%",
              paddingTop: 40,
            }}
          >
            <Box
              style={{
                display: "flex",
                width: (columns[0] / columnsTotal) * 100 + "%",
                maxWidth: (columns[0] / columnsTotal) * 100 + "%",
                minWidth: (columns[0] / columnsTotal) * 100 + "%",
                flexDirection: "row",
                flexWrap: "wrap",
                alignContent: "flex-start",
                alignItems: "flex-start",
                justifyContent: "center",
                // alignContent: "center",
              }}
            >
              {getCommissions("other").map((key) => {
                return (
                  <Box
                    key={key}
                    style={{
                      display: "none",
                    }}
                  >
                    <CommissionBox
                      number={getCommissionNumber(key)}
                      firebaseKey={key}
                    />
                  </Box>
                );
              })}
              {getCommissions("v_priprave").map((key) => {
                return (
                  <Box
                    key={key}
                    style={{
                      padding: tilePadding,
                    }}
                  >
                    <Box
                      style={{
                        backgroundImage:
                          "url('/assets/customer/c_preparing.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: tileSize,
                        width: tileSize,
                        fontSize: tileFont,
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      <CommissionBox
                        number={getCommissionNumber(key)}
                        firebaseKey={key}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              style={{
                width: (columns[1] / columnsTotal) * 100 + "%",
                maxWidth: (columns[1] / columnsTotal) * 100 + "%",
                minWidth: (columns[1] / columnsTotal) * 100 + "%",
              }}
            />
            <Box
              style={{
                display: "flex",
                width: (columns[2] / columnsTotal) * 100 + "%",
                maxWidth: (columns[2] / columnsTotal) * 100 + "%",
                minWidth: (columns[2] / columnsTotal) * 100 + "%",
                flexDirection: "row",
                flexWrap: "wrap",
                alignContent: "flex-start",
                alignItems: "flex-start",
                justifyContent: "center",
                // alignContent: "center",
              }}
            >
              {getCommissions("k_vyzvednuti").map((key) => {
                return (
                  <Box
                    key={key}
                    style={{
                      padding: tilePadding,
                    }}
                  >
                    <Box
                      style={{
                        backgroundImage:
                          "url('/assets/customer/c_finished.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: tileSize,
                        width: tileSize,
                        fontSize: tileFont,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      <CommissionBox
                        number={getCommissionNumber(key)}
                        firebaseKey={key}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommissionCustomer;

export const checkDelivery = (address, settings) => {
  let zoneTitle = null,
    sc = 0;
  let i,
    iMax = settings.deliveryZonesTowns.length;
  for (i = 0; i < iMax; i++) {
    let score = 0;
    if (
      !settings.deliveryZonesTowns[i].townSpecification_2 &&
      address
        .toLowerCase()
        .includes(
          settings.deliveryZonesTowns[i].townSpecification_1.toLowerCase()
        )
    ) {
      score = 1;
    }
    if (
      settings.deliveryZonesTowns[i].townSpecification_2 &&
      address
        .toLowerCase()
        .includes(
          settings.deliveryZonesTowns[i].townSpecification_1.toLowerCase()
        ) &&
      address
        .toLowerCase()
        .includes(
          settings.deliveryZonesTowns[i].townSpecification_2.toLowerCase()
        )
    ) {
      score = 2;
    }
    if (score > sc) {
      zoneTitle = settings.deliveryZonesTowns[i].zoneTitle;
    }
  }

  if (zoneTitle) {
    let pos = settings.pointOfSales.find((pos) => pos.zoneTitle === zoneTitle);
    if (pos) {
      return pos;
    }
  }

  return null;
};

export const servicePrice = (pos) => {
  //vrátí maximální cenu služby (pro POS)
  let p = 0;
  for (let i = 0; i <= 23; i++) {
    if (pos["servicePrice_" + i] * 1 > p) {
      p = pos["servicePrice_" + i] * 1;
    }
  }
  return p;
};

export const getClosedDays = (settings) => {
  return settings.closedDays.map((closedDay) => {
    const [d, m, y] = closedDay.day.split(".").map((o, i) => {
      if (i === 1) {
        //indexy měsíců se počítají od nuly, a proto odečítáme jedničku
        return o.trim() * 1 - 1;
      }
      return o.trim() * 1;
    });
    const [from, to] = closedDay.time.split("-").map((o) => {
      return o.trim();
    });
    const [tfh, tfm] = from.split(":").map((o) => {
      return o.trim() * 1;
    });
    const [tth, ttm] = to.split(":").map((o) => {
      return o.trim() * 1;
    });
    return {
      closedDay,
      pointOfSales: closedDay.pointOfSales,
      description: closedDay.description,
      date: {
        y: y,
        m: m,
        d: d,
      },
      timeFrom: {
        h: tfh,
        m: tfm,
        hm: tfh * 60 + tfm,
      },
      timeTo: {
        h: tth,
        m: ttm,
        hm: tth * 60 + ttm,
      },
      dateTimeFrom: new Date(y, m, d, tfh, tfm, 0, 0),
      dateTimeTo: new Date(y, m, d, tth, ttm, 59, 0),
    };
  });
};

export const getClosedDayForPointOfSaleAndDate = (
  settings,
  pointOfSaleId,
  dateTime
) => {
  const closedDays = getClosedDays(settings);
  const dt = new Date(dateTime);
  dt.setHours(0, 0, 0, 0);
  const found = closedDays.find((cd) => {
    const dx = new Date(cd.date.y, cd.date.m, cd.date.d, 0, 0, 0, 0);
    return (
      cd.pointOfSales.includes(pointOfSaleId) && dt.getTime() === dx.getTime()
    );
  });
  if (found) {
    return found;
  }
  return null;
};

export const getClosedDayForPointOfSaleAndDateTime = (
  settings,
  pointOfSaleId,
  dateTime
) => {
  const closedDays = getClosedDays(settings);
  const found = closedDays.find(
    (cd) =>
      cd.pointOfSales.includes(pointOfSaleId) &&
      cd.dateTimeFrom <= dateTime &&
      cd.dateTimeTo >= dateTime
  );
  if (found) {
    return found;
  }
  return null;
};

import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { formatCodeToHM, getSources, SOURCE_PIZZA } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { buildConfig } from "../Capacity";
import axios from "axios";
import {
  apiGetAdminCapacity,
  apiGetCommissionCapacity,
  apiGetInternalCommissionCapacity,
  apiPostInternalCommission,
} from "../../../../api/endpoints";
import {
  isDisabled,
  isDisabledForInternalCommission,
} from "../../../DateTimePicker/logicIsDisabled";
import { filterCommissionsForCapacity } from "../../../../functions/functions";
import { convertHMToHoursMinutes } from "../../../../functions/dateTimeHelper";
import { intervalSize } from "../../../DateTimePicker/logicConstants";
import {
  availableJobPositionsForPauza,
  jobPosition2Source,
  jobPositionObsluha,
} from "../../adminEnum";

const getTimes = (config) => {
  if (!config) {
    return [];
  }

  const times = [];
  config.hours.forEach((h) => {
    config.minutes.forEach((m) => {
      const code = h * 60 + m;
      times.push({
        code,
        label: formatCodeToHM(code),
      });
    });
  });
  return times;
};

const InternalCommission = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);
  const settings = useSelector((state) => state.settings);

  const [config, setConfig] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [form, setForm] = React.useState({});
  const [pizza50s, setPizza50s] = React.useState(1);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [commissions, setCommissions] = React.useState([]);
  const [capacities, setCapacities] = React.useState([]);
  const [internalCommissions, setInternalCommissions] = React.useState([]);
  const load = async () => {
    const request0 = axios.get(apiGetCommissionCapacity()); //aktuální vytíženost
    const request1 = axios.get(apiGetAdminCapacity()); //nastavená kapacita (dle administrace)
    const request2 = axios.get(apiGetInternalCommissionCapacity()); //nastavená kapacita (dle administrace)
    axios.all([request0, request1, request2]).then(
      axios.spread((...responses) => {
        setCommissions(responses[0].data); //aktuální vytíženost
        setCapacities(responses[1].data.content); //kapacity
        setInternalCommissions(responses[2].data); //aktuální vytíženost
      })
    );
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    const pointOfSales = settings.pointOfSales.filter(
      (pos) => pos.localBusinessTitle === localBusiness.title
    );
    setConfig(buildConfig(pointOfSales));
  }, [settings.pointOfSales, localBusiness]);

  const handleOpen = (type) => {
    load();
    setType(type);
    setForm({
      jobPosition: jobPositionObsluha,
      source:
        type === "pult" ? SOURCE_PIZZA : jobPosition2Source[jobPositionObsluha],
      startAt: getTimes(config)[0].code,
      duration: 5,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value });
  };
  React.useEffect(() => {
    setForm({ ...form, source: jobPosition2Source[form.jobPosition] });
  }, [form.jobPosition]);

  const handleChangeFormPizza50s = (value) => {
    setPizza50s(value);
  };

  const createInternalCommissionData = () => {
    const source = type === "pult" ? SOURCE_PIZZA : form.source;
    const value = type === "pult" ? pizza50s : form.duration;

    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const time = dt.getTime();
    const startAt = time + form.startAt * 60 * 1000;

    const data = {
      uid: uuidv4(),
      type: type,
      title: type === "pauza" ? "Přestávka" : "Výroba na pult",
      internal: true,
      startAt: startAt,
      value: value,
      source: source,
      jobPosition: type === "pult" ? "" : form.jobPosition,
    };

    return data;
  };

  const handleSubmit = () => {
    const data = createInternalCommissionData();

    axios.post(apiPostInternalCommission(), data).then((response) => {
      if (response.status === 201) {
        handleClose();
        enqueueSnackbar(
          type === "pauza"
            ? "Přestávka byla uložena."
            : "Výroba na pult byla uložena.",
          {
            variant: "success",
          }
        );
      }
    });
  };

  const disabled = (timeCode) => {
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const time = dt.getTime();
    const startAt = time + timeCode * 60 * 1000;

    const internalCommission = createInternalCommissionData();
    internalCommission.startAt = startAt;

    const { hours, minutes } = convertHMToHoursMinutes(timeCode);

    return isDisabledForInternalCommission(
      dt,
      hours,
      minutes,
      filterCommissionsForCapacity(commissions, localBusiness.title, dt),
      true,
      capacities,
      settings,
      dt,
      internalCommission,
      internalCommissions
    );
  };

  if (!config) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => {
          handleOpen("pauza");
        }}
        variant={"contained"}
        color={"secondary"}
        style={{
          marginRight: 16,
        }}
      >
        Přestávka
      </Button>
      <Button
        onClick={() => {
          handleOpen("pult");
        }}
        variant={"contained"}
        color={"secondary"}
      >
        Výroba na pult
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {type === "pauza" && "Přestávka"}
          {type === "pult" && "Výroba na pult"}
        </DialogTitle>
        <DialogContent style={{ width: 300 }}>
          {type === "pauza" && (
            <>
              {/*<Box mb={2}>*/}
              {/*  <FormControl fullWidth variant={"outlined"} size={"small"}>*/}
              {/*    <InputLabel>Sekce</InputLabel>*/}
              {/*    <Select*/}
              {/*      value={form.source}*/}
              {/*      name={"source"}*/}
              {/*      label="Sekce"*/}
              {/*      onChange={(event) => {*/}
              {/*        handleChangeForm("source", event.target.value);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {[getSources()[0], getSources()[1]].map((s) => {*/}
              {/*        return <MenuItem value={s.code}>{s.label}</MenuItem>;*/}
              {/*      })}*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}
              {/*</Box>*/}
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Kdo</InputLabel>
                  <Select
                    value={form.jobPosition}
                    name={"jobPosition"}
                    label="Kdo"
                    onChange={(event) => {
                      handleChangeForm("jobPosition", event.target.value);
                    }}
                  >
                    {availableJobPositionsForPauza.map((s) => {
                      return <MenuItem value={s}>{s}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Čas od</InputLabel>
                  <Select
                    value={form.startAt}
                    name={"startAt"}
                    label="Čas od"
                    onChange={(event) => {
                      handleChangeForm("startAt", event.target.value);
                    }}
                  >
                    {getTimes(config).map((time) => {
                      const d = disabled(time.code);
                      return (
                        <MenuItem
                          key={time.code}
                          disabled={d}
                          value={time.code}
                        >
                          {time.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Trvání</InputLabel>
                  <Select
                    value={form.duration}
                    name={"duration"}
                    label="Trvání"
                    onChange={(event) => {
                      handleChangeForm("duration", event.target.value);
                    }}
                  >
                    {[5, 10, 15].map((d) => {
                      return <MenuItem value={d}>{d} min</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          {type === "pult" && (
            <>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Čas od</InputLabel>
                  <Select
                    value={form.startAt}
                    name={"startAt"}
                    label="Čas od"
                    onChange={(event) => {
                      handleChangeForm("startAt", event.target.value);
                    }}
                  >
                    {getTimes(config).map((time) => {
                      const d = disabled(time.code);
                      return (
                        <MenuItem
                          key={time.code}
                          disabled={d}
                          value={time.code}
                        >
                          {time.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Počet pizz padesátek</InputLabel>
                  <Select
                    value={pizza50s}
                    name={"pizza50s"}
                    label="Počet pizz padesátek"
                    onChange={(event) => {
                      handleChangeFormPizza50s(event.target.value);
                    }}
                  >
                    {[1, 2].map((d) => {
                      return <MenuItem value={d}>{d}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color={"secondary"} onClick={handleClose}>
            Zavřít
          </Button>
          <Button
            disabled={disabled(form.startAt)}
            variant={"contained"}
            color={"secondary"}
            onClick={handleSubmit}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InternalCommission;

import React, { useEffect, useState } from "react";
import { fetchRangeOfGoods } from "../../../actions/rangeOfGoodsActions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "axios";
import { baseUrl } from "../../../constants/constants";
import { contextAdmin } from "../../../constants/contexts";
import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { formatSurchargePrice } from "../../../utils/price";
import { colors } from "../../../theme/theme";
import {
  getAdditionGroups,
  getAdditionItemsOfAdditionGroup,
} from "../../../utils/data";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";

const useStyles2 = makeStyles((theme) => ({
  dialogTitle: {
    "& .title": {
      fontSize: "110%",
      fontWeight: 800,
    },
    "& .subtitle": {
      fontSize: "90%",
      fontWeight: 300,
    },
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "&:hover": {
      backgroundColor: "inherit !important",
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  table: {
    "& th": {
      fontWeight: 600,
    },
    "& tr": {
      "& td:nth-child(2), & th:nth-child(2)": {
        background: "rgba(255, 255, 0, 0.5)",
      },
      "& td:nth-child(n+3), & th:nth-child(n+3)": {
        background: "rgba(0, 255, 0, 0.5)",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  dialogSection: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    textAlign: "right",
  },
  categorySectionLabel: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  product: {
    backgroundColor: colors.WHITE.main,
    margin: theme.spacing(1),
    borderRadius: 0,
  },
  productContent: {
    padding: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(1),
    "& > div": {
      padding: theme.spacing(1),
    },
  },
  chip: {
    display: "inline-block",
    backgroundColor: colors.ORANGE.main,
    color: colors.ORANGE.text,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    textTransform: "uppercase",
    letterSpacing: 0.5,
    fontWeight: 600,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontSize: "140%",
    fontWeight: 800,
  },
  subtitle: {
    fontSize: "100%",
    fontWeight: 300,
  },
  price: {
    fontSize: "120%",
    fontWeight: 300,
    "& strong": {
      //fontSize: "120%",
    },
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: "90%",
    fontWeight: 300,
  },
  allergens: {
    marginTop: theme.spacing(1),
    fontSize: "80%",
    fontWeight: 300,
  },
  adminItem: {
    boxSizing: "content-box",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: theme.spacing(0.5),
  },
  adminItemInner: {
    padding: theme.spacing(1),
    height: 120,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ProductAdditionItemDotykackaDialog = (props) => {
  const { open, additionItem, handleDialogClose } = props;

  const dialogClasses = useStyles2();

  const fullScreen = false;

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={fullScreen}
    >
      <DialogTitle className={dialogClasses.dialogTitle}>
        <Box style={{ display: "flex" }}>
          <Box style={{ flexGrow: 1 }}>
            <Box className={"title"}>{additionItem.title}</Box>
          </Box>
          <Box>
            <IconButton
              className={dialogClasses.closeButton}
              onClick={handleDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={dialogClasses.dialogContent}>
        {additionItem.dotykacka && additionItem.dotykacka.length > 0 && (
          <>
            <Table size="small" className={dialogClasses.table}>
              <TableHead>
                <TableRow>
                  <TableCell component={"th"} />
                  <TableCell>Sortimentová tabulka</TableCell>
                  <TableCell colSpan={additionItem.dotykacka.length}>
                    Dotykačka
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component={"th"}>Cloud ID</TableCell>
                  <TableCell>-</TableCell>
                  {additionItem.dotykacka.map((d) => {
                    return <TableCell>{d["_cloudId"]}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Product ID</TableCell>
                  <TableCell>-</TableCell>
                  {additionItem.dotykacka.map((d) => {
                    return <TableCell>{d.id}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Název</TableCell>
                  <TableCell>{additionItem.title}</TableCell>
                  {additionItem.dotykacka.map((d) => {
                    return <TableCell>{d.name}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component={"th"}>Cena</TableCell>
                  <TableCell>{additionItem.surcharge}</TableCell>
                  {additionItem.dotykacka.map((d) => {
                    return <TableCell>{d.priceWithVat}</TableCell>;
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ProductAdditionItem = (props) => {
  const { classes, additionItem, toggleVisibility, visible } = props;

  const [dotykackaDialogOpen, setDotykackaDialogOpen] = useState(false);

  const openDotykackaDialog = (e) => {
    e.preventDefault();
    setDotykackaDialogOpen(true);
  };

  const closeDotykackaDialog = () => {
    setDotykackaDialogOpen(false);
  };

  const getColor = () => {
    if (!visible) {
      return "rgba(0, 0, 0, 0.25)";
    }
    if (!additionItem.dotykacka[0]) {
      return "#000";
    }
    return parseInt(additionItem.dotykacka[0].hexColor.replace("#", ""), 16) >
      0xffffff / 2
      ? "#000"
      : "#fff";
  };

  const isWarning = () => {
    let dotykackaPrice = 0;
    for (let d of additionItem.dotykacka) {
      dotykackaPrice = dotykackaPrice + d.priceWithVat.trim() * 1;
    }
    if (
      dotykackaPrice !== 0 &&
      additionItem.surcharge.trim() * 1 !== dotykackaPrice
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ProductAdditionItemDotykackaDialog
        open={dotykackaDialogOpen}
        additionItem={additionItem}
        handleDialogClose={closeDotykackaDialog}
      />

      <Box className={classes.adminItem}>
        <Box
          style={{ margin: 8 }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Box>
            <IconButton size={"small"} onClick={toggleVisibility}>
              {visible && <VisibilityIcon />}
              {!visible && <VisibilityOffIcon />}
            </IconButton>
          </Box>
          <Box>
            {additionItem.dotykacka.length > 0 && (
              <Box display={"flex"} alignItems={"center"}>
                <Box>
                  <IconButton onClick={openDotykackaDialog} size={"small"}>
                    <DesktopWindowsIcon />
                  </IconButton>
                </Box>
                <Box>
                  {isWarning() && (
                    <WarningIcon color={"primary"} size={"small"} />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <ButtonBase style={{ width: "100%", display: "block" }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            className={classes.adminItemInner}
            style={{
              color: getColor(),
              background: !!additionItem.dotykacka[0]
                ? additionItem.dotykacka[0].hexColor
                : "#ffffff",
            }}
          >
            <Box>
              <Typography variant={"h5"} style={{ textAlign: "left" }}>
                {additionItem.title}
                {additionItem.isDefaultSelected && (
                  <Box style={{ fontSize: 12, marginTop: 8 }}>
                    výchozí možnost
                  </Box>
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant={"subtitle1"} style={{ textAlign: "right" }}>
                {formatSurchargePrice(additionItem.surcharge)}
              </Typography>
            </Box>
          </Box>
        </ButtonBase>
      </Box>
    </>
  );
};

const ProductAdditionList = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const adminLocalBusiness = useSelector(
    (state) => state.app.adminLocalBusiness
  );
  const settings = useSelector((state) => state.settings);
  const pointOfSale = useSelector((state) =>
    state.settings.pointOfSales.find(
      (pos) => pos.id === state.cart.pointOfSaleId
    )
  );
  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);

  const [invisible, setInvisible] = React.useState([]);

  useEffect(() => {
    const apiCode = pointOfSale
      ? pointOfSale.apiCode
      : settings.pointOfSales[0].apiCode;
    dispatch(fetchRangeOfGoods(apiCode, contextAdmin));
  }, [pointOfSale, settings]);

  React.useEffect(() => {
    // console.log(rangeOfGoods);
    if (rangeOfGoods.hasOwnProperty("additionsItems")) {
      setInvisible(
        rangeOfGoods.additionsItems
          .filter((ai) => !!ai.invisible)
          .map((ai) => {
            return ai.groupTitle + ai.title;
          })
      );
    }
  }, [rangeOfGoods]);

  const toggleVisibility = (ai) => {
    if (!adminLocalBusiness) {
      return;
    }
    let copyInvisible;
    if (invisible.includes(ai.groupTitle + ai.title)) {
      copyInvisible = [...invisible].filter(
        (i) => i !== ai.groupTitle + ai.title
      );
    } else {
      copyInvisible = [...invisible].concat(ai.groupTitle + ai.title);
    }

    const data = {
      localBusinessId: adminLocalBusiness.title,
      data: copyInvisible,
    };
    axios
      .post(baseUrl + "/settings/invisible-addition-items", data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setInvisible(copyInvisible);
          enqueueSnackbar("Zobrazení přísad bylo změněno.", {
            variant: "success",
          });
        }
      });
  };

  // React.useEffect(() => {
  //   console.log(invisible);
  // }, [invisible]);

  if (!rangeOfGoods.hasOwnProperty("additionsItems")) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Grid
        container
        style={{ padding: 8, backgroundColor: colors.BACKGROUND.main }}
      >
        {getAdditionGroups(rangeOfGoods).map((ag) => {
          return (
            <React.Fragment key={ag.fullTitle}>
              <Grid item xs={12} className={classes.categorySectionLabel}>
                <Typography variant={"h4"}>{ag.fullTitle}</Typography>
              </Grid>
              {getAdditionItemsOfAdditionGroup(rangeOfGoods, ag).map((ai) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    key={ai.groupTitle + ai.title}
                  >
                    <ProductAdditionItem
                      classes={classes}
                      additionItem={ai}
                      visible={!invisible.includes(ai.groupTitle + ai.title)}
                      toggleVisibility={() => {
                        toggleVisibility(ai);
                      }}
                    />
                  </Grid>
                );
              })}
            </React.Fragment>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(ProductAdditionList);

import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { formatDate, getDayNameRelative } from "../../../utils/dateTime";
import { colors } from "../../../theme/theme";

const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: colors.WHITE.main,
    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.14)",
  },
  item: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    boxSizing: "border-box",
    textAlign: "center",
    cursor: "pointer",
    borderBottomStyle: "solid",
    borderBottomWidth: 4,
    borderBottomColor: colors.WHITE.main,
    "&:hover": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "&.active": {
      color: colors.RED.main,
      borderBottomColor: colors.RED.main,
    },
    "& > div": {
      display: "block",
      "& > img": {
        height: 48,
      },
    },
  },
}));

const DateContainer = (props) => {
  const { config, selectedDateIndex, handleClick } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.list}>
      {config.dates.map((d, index) => {
        return (
          <Grid
            key={index}
            item
            xs={6}
            className={
              classes.item + (index === selectedDateIndex ? " active" : "")
            }
            onClick={() => {
              handleClick(index);
            }}
          >
            <Box>
              <Box>{getDayNameRelative(index)}</Box>
              <Box>{formatDate(config.dates[index])}</Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DateContainer;

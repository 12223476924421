import {Box, Button, makeStyles, Typography} from "@material-ui/core";
import {
    changeCartItemQuantityAdd,
    changeCartItemQuantitySub,
} from "../../actions/cartActions";
import {formatPrice, getCartItemPriceTotal} from "../../utils/price";

import React from "react";
import {colors} from "../../theme/theme";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {isProductInRangeOfGoods} from "../../utils/data";
import {
    contextAdmin,
    contextCheckout,
    contextHome,
} from "../../constants/contexts";
import {
    additionGroupPizzaHalf,
    commissionOriginCounter,
    product2Plus1Gratis,
    product2Plus1GratisAdditionGroupTitle,
} from "../../constants/constants";
import {
    getCountOfPizza30InCart,
    is2Plus1Gratis,
} from "../Sale2Plus1/sale2plus1";
import {sortByPositionFn} from "../../utils/utils";
import {Restaurant} from "@material-ui/icons";
import {SOURCE_PIZZA} from "../Admin/Capacity/utils";
import {getCartItemAdditionItemQuantity, getCartItemAdditionItemQuantityKey} from "../../utils/cart";

const useStyles = makeStyles((theme) => ({
    itemProduct: {
        marginBottom: theme.spacing(1),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    item: {
        display: "flex",
        "& > div": {
            //padding: theme.spacing(1),
        },
        "& .disabled": {
            color: "rgba(0, 0, 0, 0.25)",
            "& a": {
                color: "rgba(0, 0, 0, 0.25) !important",
            },
        },
    },
    quantity: {
        fontSize: "120%",
        fontWeight: 300,
        marginRight: theme.spacing(1),
        "& a": {
            color: colors.RED.main,
            textDecoration: "none",
            cursor: "pointer",
            "&.disabled": {
                color: colors.BACKGROUND.main,
                cursor: "default",
            },
        },
    },
    title: {
        fontSize: "120%",
        fontWeight: 800,
        "& input": {
            display: "inline-block",
            textAlign: "center",
            width: 30,
        },
    },
    subtitle: {
        fontSize: "80%",
        fontWeight: 800,
    },
    additions: {
        fontSize: "80%",
        fontWeight: 200,
    },
    price: {
        marginLeft: theme.spacing(2),
        fontSize: "120%",
        fontWeight: 300,
    },
    adminQuantity: {
        fontWeight: 700,
        color: "#ff0000",
    },
    adminQuantityTimes: {
        color: "#000000",
    },
}));

const CartItem = (props) => {
    const {
        cartItem,
        editable,
        handleEditCartItem,
        handleToggleTakeAway,
        context,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const rangeOfGoods = useSelector((state) => state.rangeOfGoods);
    const cart = useSelector((state) => state.cart);

    const getProductTitle = () => {
        return is2Plus1Gratis(cartItem)
            ? cartItem.additionItems.find(
                (o) => o.groupTitle === product2Plus1GratisAdditionGroupTitle
            ).title
            : cartItem.product.title;
    };

    const getProductSubTitle = () => {
        return is2Plus1Gratis(cartItem)
            ? product2Plus1Gratis
            : cartItem.product.subtitle;
    };

    // const getCartItemAdditions = () => {
    //   return is2Plus1Gratis(cartItem)
    //     ? cartItem.additionItems.filter(
    //         (o) => o.groupTitle !== product2Plus1GratisAdditionGroupTitle
    //       )
    //     : cartItem.additionItems;
    // };

    const getCartItemAdditions2 = () => {
        const result = [];
        if (!cartItem.additionItems) {
            return result;
        }
        const additionItems = cartItem.additionItems
            .filter((o) => o.groupTitle !== product2Plus1GratisAdditionGroupTitle)
            .sort(sortByPositionFn);
        for (let additionItem of additionItems) {
            let o = result.find((o) => o.groupTitle === additionItem.groupTitle);
            if (!o) {
                o = {
                    groupTitle: additionItem.groupTitle,
                    groupTitle2: additionItem.groupTitle2,
                    showGroupTitleInCart: additionItem.showGroupTitleInCart,
                    additionItems: [],
                    additionItemsTitles: [],
                };
                result.push(o);
            }
            const qty = getCartItemAdditionItemQuantity(cartItem, additionItem, 0);
            const title = qty === 0 ? additionItem.title : qty +'x ' + additionItem.title;
            o.additionItems.push(additionItem);
            o.additionItemsTitles.push(title);
        }
        return result;
    };

    const isEditable = () => {
        return editable;
    };

    return (
        <Box>
            <Box
                display="flex"
                className={[
                    classes.itemProduct,
                    classes.item,
                    [contextHome, contextCheckout].includes(context) &&
                    !isProductInRangeOfGoods(rangeOfGoods, cartItem.product)
                        ? "disabled"
                        : "",
                ].join(" ")}
            >
                <Box className={classes.quantity}>
                    {isEditable() && (
                        <>
                            <a
                                onClick={() => {
                                    dispatch(changeCartItemQuantitySub({cartItem}));
                                    enqueueSnackbar("Množství bylo změněno.", {
                                        variant: "success",
                                    });
                                }}
                            >
                                -
                            </a>
                            &nbsp;
                        </>
                    )}
                    {context === contextAdmin ?
                        <>
              <span
                  className={classes.adminQuantity}
                  style={{
                      color: cartItem.quantity !== 1 ? "#ff0000" : "#000000",
                  }}
              >
                {cartItem.quantity}
              </span>
                            <span className={classes.adminQuantityTimes}>&times;</span>
                        </>
                    :
                        <>
                            {cartItem.quantity}
                            {!isEditable() && <>&times;</>}
                        </>
                    }

                    {isEditable() && (
                        <>
                            &nbsp;
                            <a
                                className={is2Plus1Gratis(cartItem) ? "disabled" : ""}
                                onClick={() => {
                                    if (
                                        isProductInRangeOfGoods(rangeOfGoods, cartItem.product) &&
                                        !is2Plus1Gratis(cartItem)
                                    ) {
                                        dispatch(changeCartItemQuantityAdd({cartItem}));
                                        enqueueSnackbar("Množství bylo změněno.", {
                                            variant: "success",
                                        });
                                    }
                                }}
                            >
                                +
                            </a>
                        </>
                    )}
                </Box>
                <Box flexGrow={1}>
                    <Typography
                        className={[
                            classes.title,
                            [contextHome, contextCheckout].includes(context) &&
                            !isProductInRangeOfGoods(rangeOfGoods, cartItem.product)
                                ? "disabled"
                                : "",
                        ].join(" ")}
                    >
                        {getProductTitle()}
                        {[contextAdmin].includes(context) &&
                            cartItem.hasOwnProperty("takeAway") &&
                            !cartItem.takeAway && (
                                <Box
                                    style={{
                                        fontSize: 12,
                                        display: "inline-block",
                                        marginLeft: 8,
                                        color: "green",
                                    }}
                                >
                                    <Restaurant fontSize={"small"}/> TADY
                                </Box>
                            )}
                        {!!handleEditCartItem && (
                            <Button
                                style={{marginLeft: 8}}
                                color={"primary"}
                                size={"small"}
                                onClick={handleEditCartItem}
                            >
                                Upravit
                            </Button>
                        )}
                    </Typography>
                    <Typography
                        className={[
                            classes.item,
                            classes.subtitle,
                            [contextHome, contextCheckout].includes(context) &&
                            !isProductInRangeOfGoods(rangeOfGoods, cartItem.product)
                                ? "disabled"
                                : "",
                        ].join(" ")}
                        style={{
                            paddingLeft: context === contextAdmin ? 0 : 0,
                            color:
                                context === contextAdmin &&
                                getCountOfPizza30InCart(props.cart) > 0 &&
                                getProductSubTitle() === "Padesátka"
                                    ? "red"
                                    : "black",
                        }}
                    >
                        {getProductSubTitle()}
                    </Typography>
                    <Typography
                        className={[
                            classes.additions,
                            [contextHome, contextCheckout].includes(context) &&
                            !isProductInRangeOfGoods(rangeOfGoods, cartItem.product)
                                ? "disabled"
                                : "",
                        ].join(" ")}
                        style={{paddingLeft: context === contextAdmin ? 0 : 0}}
                    >
                        {getCartItemAdditions2() &&
                            getCartItemAdditions2().length > 0 &&
                            getCartItemAdditions2().map((additionGroup, index) => {
                                return (
                                    <Box display={"flex"} key={index}>
                                        <Box style={{paddingRight: 4}}>+</Box>
                                        <Box flexGrow={1}>
                                            <Box>
                                                {additionGroup.showGroupTitleInCart && (
                                                    <>{additionGroup.groupTitle2}: </>
                                                )}
                                                <Box component={"strong"}
                                                     style={{color: context === contextAdmin && additionGroup.groupTitle2 === additionGroupPizzaHalf ? 'red' : 'black'}}>
                                                    {additionGroup.additionItemsTitles.join(", ")}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                    </Typography>
                    {!!handleToggleTakeAway &&
                        [contextHome].includes(context) &&
                        cart.commissionOrigin === commissionOriginCounter && (
                            <Box style={{marginTop: 8, marginBottom: 8}}>
                                <Button
                                    onClick={handleToggleTakeAway}
                                    startIcon={<Restaurant/>}
                                    color={"secondary"}
                                    size={"small"}
                                    variant={
                                        cartItem.hasOwnProperty("takeAway") && !cartItem.takeAway
                                            ? "contained"
                                            : "outlined"
                                    }
                                >
                                    Tady
                                </Button>
                            </Box>
                        )}
                </Box>
                <Box
                    className={[
                        classes.price,
                        [contextHome, contextCheckout].includes(context) &&
                        !isProductInRangeOfGoods(rangeOfGoods, cartItem.product)
                            ? "disabled"
                            : "",
                    ].join(" ")}
                >
                    {formatPrice(getCartItemPriceTotal(cartItem))}
                </Box>
            </Box>
        </Box>
    );
};

export default CartItem;

import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import CommissionStatusBar from "./CommissionStatusBar";
import CommissionInfo from "./CommissionInfo";
import Cart from "../../Cart/Cart";
import { useDispatch, useSelector } from "react-redux";
import { vosSections } from "../../../constants/constants";
import { hasItemsInSection } from "../../../utils/cart";
import { finishedStatusCode } from "../adminEnum";
import Notification from "../Notification/Notification";
import { contextAdmin } from "../../../constants/contexts";
import axios from "axios";
import { apiPutCommission } from "../../../api/endpoints";

export const commissionVariantHorizontal = window.screen.width < 576;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: commissionVariantHorizontal ? "column" : "row",
    borderTop: "solid 5px #000000",
    borderBottom: "solid 5px #000000",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}));

const getPointOfSale = (settings, commission) => {
  return settings.pointOfSales.find(
    (pos) => pos.id === commission.pointOfSaleId
  );
};

const CommissionDetail = (props) => {
  const { commission, handleChangeCommission } = props;

  const classes = useStyles();

  const settings = useSelector((state) => state.settings);

  const markSectionDone = (section) => {
    const copySections = { ...commission.sections };
    copySections[section.code] = true;
    const payload = {
      sections: copySections,
    };
    let allSectionsMarked = true;
    for (let vs of vosSections) {
      //pokud všechny VOS sekce jsou v rámci objednávky hotové
      if (hasItemsInSection(commission, vs) && !copySections[vs.code]) {
        allSectionsMarked = false;
      }
    }
    if (allSectionsMarked) {
      payload.activeStatusCode = finishedStatusCode; //změní se status na "finished"
    }

    handleChangeCommission(payload);
  };

  if (!commission) {
    return <></>;
  }

  return (
    <Box className={classes.root}>
      <Notification commission={commission} />
      <Box>
        <CommissionStatusBar
          commission={commission}
          pointOfSale={getPointOfSale(settings, commission)}
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Box flexGrow={1}>
          <CommissionInfo
            commission={commission}
            handleChangeCommission={handleChangeCommission}
            pointOfSale={getPointOfSale(settings, commission)}
          />
        </Box>
        <Box
          flexGrow={1}
          style={{
            backgroundColor: "#ffffe0",
            borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Cart
            cart={commission}
            editable={false}
            context={contextAdmin}
            markSectionDone={markSectionDone}
            handleChangeCommission={handleChangeCommission}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CommissionDetail);

import React from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { apiPostRating } from "../api/endpoints";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  pageTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& a": {
      color: theme.palette.primary.contrastText,
      // fontSize: "250%",
      textDecoration: "none",
    },
  },
  button: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: "150%",
    textDecoration: "none",
  },
  formControl: {
    "& .MuiSelect-selectMenu": {
      whiteSpace: "normal !important",
    },
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& > div:first-child": {
      textAlign: "center",
      marginBottom: theme.spacing(0.5),
    },
    // [theme.breakpoints.up("sm")]: {
    //   flexDirection: "row",
    //   "& > div:first-child": {
    //     textAlign: "left",
    //     marginBottom: theme.spacing(0.5),
    //   },
    // },
  },
}));

const answersLb = [{ title: "Vyškov" }, { title: "Uherský Brod" }];
const answers = [
  { title: "1 = výborné" },
  { title: "2 = chvalitebné" },
  { title: "3 = dobré" },
  { title: "4 = dostatečné" },
  { title: "5 = nedostatečné" },
];
const questions = [
  {
    code: "q1",
    title: "Která provozovna objednávku připravovala?",
    type: "select",
    answers: answersLb,
  },
  {
    code: "q2",
    title: "V kolik hodin (přibližně) jste si objednávku převzali?",
    type: "text",
  },
  {
    code: "q3",
    title: "Jak jste byli s objednávkou spokojeni?",
    type: "select",
    answers: answers,
  },
  {
    code: "q4",
    title: "Bylo jídlo dostatečně teplé?",
    type: "select",
    answers: answers,
  },
  {
    code: "q5",
    title: "Bylo jídlo připraveno/dovezeno včas?",
    type: "select",
    answers: answers,
  },
  {
    code: "q6",
    title: "Byla obsluha podle vašich představ sdílná a slušná?",
    type: "select",
    answers: answers,
  },
  // {
  //   code: "q7",
  //   title: "Jak jste byli s objednávkou spokojeni?",
  //   type: "select",
  //   answers: answers,
  // },
  { code: "q8", title: "Chcete nám ještě něco sdělit?", type: "textarea" },
  {
    code: "q9",
    title:
      "Nechte nám na Vás kontakt (e-mail, telefon) a my se Vám ozveme zpět.",
    type: "text",
  },
];

const RatingView = () => {
  const classes = useStyles();

  const [form, setForm] = React.useState(() => {
    const f = {};
    for (let q of questions) {
      f[q.code] = {
        question: q.title,
        answer: q.type === "select" ? q.answers[0].title : "",
      };
    }
    return f;
  });

  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const copy = { ...form };
    copy[name].answer = value;
    setForm(copy);
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(apiPostRating(), form)
      .then((response) => {
        if (response.status === 200) {
          setSubmitted(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container className={classes.root}>
      <Typography variant={"h3"} className={classes.pageTitle}>
        Jsme dostatečně Špizza?
      </Typography>
      {submitted && (
        <>
          <Typography style={{ marginBottom: 5 * 8 }}>
            Děkujeme za hodnocení!
          </Typography>
        </>
      )}
      {!submitted && (
        <>
          <Typography style={{ marginBottom: 5 * 8 }}>
            Ohodnoťte nás podobně jako ve škole!
          </Typography>
          {questions.map((q) => {
            return (
              <Box className={classes.formRow} mt={2}>
                <Box
                  flexGrow={1}
                  style={{ paddingLeft: 12, color: "rgba(0, 0, 0, 0.75)" }}
                >
                  <Typography variant={"h6"}>{q.title}</Typography>
                </Box>
                <Box>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size={"small"}
                  >
                    {q.type === "select" && (
                      <Select
                        name={q.code}
                        value={form[q.code].answer}
                        onChange={handleChange}
                      >
                        {q.answers.map((a) => {
                          return (
                            <MenuItem
                              style={{ whiteSpace: "normal !important" }}
                              value={a.title}
                            >
                              {a.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    {q.type === "text" && (
                      <TextField
                        name={q.code}
                        variant="outlined"
                        value={form[q.code].answer}
                        onChange={handleChange}
                      />
                    )}
                    {q.type === "textarea" && (
                      <TextField
                        name={q.code}
                        multiline={true}
                        rows={3}
                        variant="outlined"
                        value={form[q.code].answer}
                        onChange={handleChange}
                      />
                    )}
                  </FormControl>
                </Box>
              </Box>
            );
          })}
          <Box className={classes.buttonContainer}>
            <Button
              disabled={loading}
              className={classes.button}
              color={"primary"}
              variant={"contained"}
              size={"large"}
              onClick={handleSubmit}
            >
              Odeslat hodnocení
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default RatingView;

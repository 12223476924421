import React from "react";
import { useLocation } from "react-router-dom";
import useSound from "use-sound";
import { initialCommissionStatus } from "../adminEnum";
import { getAuth } from "../../../auth/auth";
import { notificationTimeout } from "../../../constants/constants";
import { canCommissionBeShownInAdmin } from "../../../utils/payment";

const Notification = (props) => {
  const { commission } = props;

  const location = useLocation();

  const soundUrl = "/assets/sounds/notification1.mp3";
  const [play, { stop }] = useSound(soundUrl);

  const notify = (c) => {
    if (
      canCommissionBeShownInAdmin(commission) && //objednávka se zobrazuje v administraci
      !!getAuth()["useSound"] && //uživatel má zapnuté zvukové upozornění
      location.pathname === "/admin" && //uživatel je na stránce s objednávkami
      c.activeStatusCode === initialCommissionStatus
    ) {
      play();
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      notify(commission);
    }, notificationTimeout);
    return () => clearInterval(interval);
  }, [commission]);

  return <></>;
};

export default Notification;

import {
  posVyskovKiosek,
  posVyskovRozvoz1,
  posVyskovRozvoz2,
  posVyskovRozvoz3,
  posVyskovStanek,
} from "../../constants/pointOfSales";

const hour = 11;

const meatSectionProductionInfo_isVisibleNow = () => {
  const startedAt = new Date(2022, 7, 1, 0, 0, 0, 0); //1.8.2022 -> 2022 7 1 = měsíce se počítají do 0
  const now = new Date();

  return now > startedAt && now.getHours() < hour;
};

export const meatSectionProductionInfo_isVisibleForPosId = (posId) => {
  return (
    meatSectionProductionInfo_isVisibleNow() &&
    [
      posVyskovKiosek,
      posVyskovStanek,
      posVyskovRozvoz1,
      posVyskovRozvoz1,
      posVyskovRozvoz2,
      posVyskovRozvoz3,
    ].includes(posId)
  );
};

export const meatSectionProductionInfo_isVisibleForProduct = (product) => {
  return (
    meatSectionProductionInfo_isVisibleNow() &&
    product.sections.includes("Maso")
  );
};

export const meatSectionProductionInfo_isVisibleForCart = (cart) => {
  let result = false;
  cart.items.forEach((cartItem) => {
    const product = cartItem.product;
    if (meatSectionProductionInfo_isVisibleForProduct(product)) {
      result = true;
    }
  });

  return (
    meatSectionProductionInfo_isVisibleNow() &&
    meatSectionProductionInfo_isVisibleForPosId(cart.pointOfSaleId) &&
    result
  );
};

export const meatSectionProductionInfo_text = () => {
  return (
    "Burgery, přílohy, nugetky a stripsy nyní připravujeme od " + hour + ":00."
  );
};

export const meatSectionProductionInfo_text2 = () => {
  return (
    <>
      Burgery, přílohy, nugetky a stripsy nyní připravujeme od{" "}
      <strong>{hour}:00</strong>.
    </>
  );
};

import React from "react";
import axios from "axios";
import {
  apiDeleteInternalCommissionPrescription,
  apiGetInternalCommissionGenerate,
  apiGetInternalCommissionPrescription,
  apiPostInternalCommission,
  apiPostInternalCommissionPrescription,
} from "../../../../api/endpoints";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getSources, SOURCE_PIZZA } from "../utils";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import {
  availableJobPositionsForPauza,
  jobPosition2Source,
  jobPositionObsluha,
} from "../../adminEnum";

const dayNames = [
  "Neděle",
  "Pondělí",
  "Úterý",
  "Středa",
  "Čtvrtek",
  "Pátek",
  "Sobota",
];

const InternalCommissionPrescription = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [prescriptions, setPrescriptions] = React.useState([]);

  const load = async () => {
    axios.get(apiGetInternalCommissionPrescription()).then((response) => {
      if (response.status === 200) {
        setPrescriptions(response.data);
      }
    });
  };

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [form, setForm] = React.useState({});
  const [pizza50s, setPizza50s] = React.useState(1);

  const handleOpen = (type) => {
    setType(type);
    setForm({
      jobPosition: jobPositionObsluha,
      source:
        type === "pult" ? SOURCE_PIZZA : jobPosition2Source[jobPositionObsluha],
      day: 1,
      hour: 0,
      minute: 0,
      duration: 5,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value });
  };
  React.useEffect(() => {
    setForm({ ...form, source: jobPosition2Source[form.jobPosition] });
  }, [form.jobPosition]);

  const handleChangeFormPizza50s = (value) => {
    setPizza50s(value);
  };

  const handleSubmit = () => {
    const source = type === "pult" ? SOURCE_PIZZA : form.source;
    const value = type === "pult" ? pizza50s : form.duration;

    const data = {
      ...form,
      type: type,
      title: type === "pauza" ? "Přestávka" : "Výroba na pult",
      value: value,
      source: source,
      jobPosition: type === "pult" ? "" : form.jobPosition,
    };

    axios
      .post(apiPostInternalCommissionPrescription(), data)
      .then((response) => {
        if (response.status === 201) {
          handleClose();
          load();
          enqueueSnackbar(
            type === "pauza"
              ? "Pravidelná přestávka byla uložena."
              : "Pravidelná výroba na pult byla uložena.",
            {
              variant: "success",
            }
          );
        }
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(apiDeleteInternalCommissionPrescription(id))
      .then((response) => {
        if (response.status === 200) {
          load();
          enqueueSnackbar(
            type === "pauza"
              ? "Pravidelná přestávka byla odstraněna."
              : "Pravidelná výroba na pult byla odstraněna.",
            {
              variant: "success",
            }
          );
        }
      });
  };

  const handleGenerate = () => {
    axios.get(apiGetInternalCommissionGenerate()).then((response) => {
      if (response.status === 200) {
        load();
        enqueueSnackbar("Přestávky a výroby na pult byly vygenerovány.", {
          variant: "success",
        });
      }
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          handleOpen("pauza");
        }}
        variant={"contained"}
        color={"secondary"}
        style={{
          marginRight: 16,
        }}
      >
        Pravidelná přestávka
      </Button>
      <Button
        onClick={() => {
          handleOpen("pult");
        }}
        variant={"contained"}
        color={"secondary"}
      >
        Pravidelná výroba na pult
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {type === "pauza" && "Pravidelná přestávka"}
          {type === "pult" && "Pravidelná výroba na pult"}
        </DialogTitle>
        <DialogContent style={{ width: 300 }}>
          {type === "pauza" && (
            <>
              {/*<Box mb={2}>*/}
              {/*  <FormControl fullWidth variant={"outlined"} size={"small"}>*/}
              {/*    <InputLabel>Sekce</InputLabel>*/}
              {/*    <Select*/}
              {/*      value={form.source}*/}
              {/*      name={"source"}*/}
              {/*      label="Sekce"*/}
              {/*      onChange={(event) => {*/}
              {/*        handleChangeForm("source", event.target.value);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {[getSources()[0], getSources()[1]].map((s) => {*/}
              {/*        return <MenuItem value={s.code}>{s.label}</MenuItem>;*/}
              {/*      })}*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}
              {/*</Box>*/}
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Kdo</InputLabel>
                  <Select
                    value={form.jobPosition}
                    name={"jobPosition"}
                    label="Kdo"
                    onChange={(event) => {
                      handleChangeForm("jobPosition", event.target.value);
                    }}
                  >
                    {availableJobPositionsForPauza.map((s) => {
                      return <MenuItem value={s}>{s}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Den</InputLabel>
                  <Select
                    value={form.day}
                    name={"day"}
                    label="Den"
                    onChange={(event) => {
                      handleChangeForm("day", event.target.value);
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 0].map((day) => {
                      return (
                        <MenuItem key={day} value={day}>
                          {dayNames[day]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Hodina</InputLabel>
                  <Select
                    value={form.hour}
                    name={"hour"}
                    label="Hodina"
                    onChange={(event) => {
                      handleChangeForm("hour", event.target.value);
                    }}
                  >
                    {[
                      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                      17, 18, 19, 20, 21, 22, 23,
                    ].map((hour) => {
                      return (
                        <MenuItem key={hour} value={hour}>
                          {hour < 10 ? "0" + hour : hour}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Minuta</InputLabel>
                  <Select
                    value={form.minute}
                    name={"minute"}
                    label="Minuta"
                    onChange={(event) => {
                      handleChangeForm("minute", event.target.value);
                    }}
                  >
                    {[0, 15, 30, 45].map((minute) => {
                      return (
                        <MenuItem key={minute} value={minute}>
                          {minute < 10 ? "0" + minute : minute}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Trvání</InputLabel>
                  <Select
                    value={form.duration}
                    name={"duration"}
                    label="Trvání"
                    onChange={(event) => {
                      handleChangeForm("duration", event.target.value);
                    }}
                  >
                    {[5, 10, 15].map((d) => {
                      return <MenuItem value={d}>{d} min</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          {type === "pult" && (
            <>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Den</InputLabel>
                  <Select
                    value={form.day}
                    name={"day"}
                    label="Den"
                    onChange={(event) => {
                      handleChangeForm("day", event.target.value);
                    }}
                  >
                    {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                      return (
                        <MenuItem key={day} value={day}>
                          {dayNames[day]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Hodina</InputLabel>
                  <Select
                    value={form.hour}
                    name={"hour"}
                    label="Hodina"
                    onChange={(event) => {
                      handleChangeForm("hour", event.target.value);
                    }}
                  >
                    {[
                      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                      17, 18, 19, 20, 21, 22, 23,
                    ].map((hour) => {
                      return (
                        <MenuItem key={hour} value={hour}>
                          {hour < 10 ? "0" + hour : hour}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Minuta</InputLabel>
                  <Select
                    value={form.minute}
                    name={"minute"}
                    label="Minuta"
                    onChange={(event) => {
                      handleChangeForm("minute", event.target.value);
                    }}
                  >
                    {[0, 15, 30, 45].map((minute) => {
                      return (
                        <MenuItem key={minute} value={minute}>
                          {minute < 10 ? "0" + minute : minute}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant={"outlined"} size={"small"}>
                  <InputLabel>Počet pizz padesátek</InputLabel>
                  <Select
                    value={pizza50s}
                    name={"pizza50s"}
                    label="Počet pizz padesátek"
                    onChange={(event) => {
                      handleChangeFormPizza50s(event.target.value);
                    }}
                  >
                    {[1, 2].map((d) => {
                      return <MenuItem value={d}>{d}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color={"secondary"} onClick={handleClose}>
            Zavřít
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleSubmit}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
      <Box style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Kdo</TableCell>
              <TableCell>Sekce</TableCell>
              <TableCell>Den</TableCell>
              <TableCell>Hodina</TableCell>
              <TableCell>Minuta</TableCell>
              <TableCell>Velikost</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {prescriptions.map((p) => {
              return (
                <TableRow key={p.id}>
                  <TableCell>
                    {p.type === "pauza" ? "Přestávka" : "Výroba na pult"}
                  </TableCell>
                  <TableCell>{p.jobPosition || ""}</TableCell>
                  <TableCell>{p.source}</TableCell>
                  <TableCell>{dayNames[p.day]}</TableCell>
                  <TableCell>{p.hour}</TableCell>
                  <TableCell>{p.minute}</TableCell>
                  <TableCell>
                    {p.value}
                    {p.type === "pauza" ? " minut" : "x padesátka"}
                  </TableCell>
                  <TableCell>
                    <Button
                      size={"small"}
                      variant={"outlined"}
                      color={"primary"}
                      onClick={() => {
                        handleDelete(p.id);
                      }}
                    >
                      Odstranit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={handleGenerate}
        >
          Vygenerovat pro aktuální den
        </Button>
      </Box>
    </>
  );
};

export default InternalCommissionPrescription;

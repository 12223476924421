import {
  ADD_ITEM_TO_CART,
  CHANGE_CART_ITEM_QUANTITY_ADD,
  CHANGE_CART_ITEM_QUANTITY_SUB,
  SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE,
  SET_POINT_OF_SALE,
} from "../constants/actionTypes";
import {
  getCountOfPizza30FreeInCart,
  getCountOfPizza30InCart,
} from "../components/Sale2Plus1/sale2plus1";
import {
  commissionOriginInternet,
  KIOSK_ENABLED,
  product2Plus1Gratis,
} from "../constants/constants";
import { posVyskovKiosek } from "../constants/pointOfSales";

export const cartMiddleware = (storeAPI) => (next) => (action) => {
  let result = next(action); //vykonej akci (modifikuj state)
  if (
    [
      CHANGE_CART_ITEM_QUANTITY_ADD,
      CHANGE_CART_ITEM_QUANTITY_SUB,
      SET_POINT_OF_SALE,
      ADD_ITEM_TO_CART,
    ].includes(action.type) //byl-li state modifikován alespoň jednou z těchto akcí
  ) {
    if (
      getCountOfPizza30InCart(storeAPI.getState().cart) -
        getCountOfPizza30FreeInCart(storeAPI.getState().cart) * 2 >=
        2 &&
      (storeAPI.getState().cart.commissionOrigin === commissionOriginInternet || //pouze pro internetové objednávky
        KIOSK_ENABLED) //nebo pro kioskové objednávky
    ) {
      storeAPI.dispatch({
        type: SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE,
        payload: true,
      });
    }
    if (
      action.type === CHANGE_CART_ITEM_QUANTITY_SUB &&
      action.payload.cartItem.product.fullTitle !== product2Plus1Gratis &&
      getCountOfPizza30InCart(storeAPI.getState().cart) === 1 && //pokud je v košíku 1 placená pizza
      getCountOfPizza30FreeInCart(storeAPI.getState().cart) === 1 //a zároveň 1 pizza zdarma
    ) {
      //--> pizzu zdarma nemůžeme prodat
      storeAPI.dispatch({
        //--> odstraníme pizzu zdarma z košíku
        type: CHANGE_CART_ITEM_QUANTITY_SUB,
        payload: {
          cartItem: storeAPI
            .getState()
            .cart.items.find(
              (ci) => ci.product.fullTitle === product2Plus1Gratis
            ),
        },
      });
    }
  }
  return result;
};

import { Box, Divider, Grid, makeStyles } from "@material-ui/core";

import Cart from "../components/Cart/Cart";
import DeliveryDialog from "../components/DeliveryDialog/DeliveryDialog";
import HomeSwiper from "../components/HomeSwiper/HomeSwiper";
import ProductList from "../components/ProductList/ProductList";
import React from "react";
import Sticky from "react-stickynode";
import { useDispatch, useSelector } from "react-redux";
import {
  addressXNA,
  KIOSK_ENABLED,
  KIOSK_POINT_OF_SALE,
} from "../constants/constants";
import { deliveryDialogOpen } from "../actions/appActions";
import { getTotalQuantity } from "../utils/cart";
import { contextHome } from "../constants/contexts";
import { setPointOfSale } from "../actions/cartActions";
import { servicePrice } from "../utils/delivery";

const useStyles = makeStyles((theme) => ({
  cart: {
    backgroundColor: "#ffffe0",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  cartContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    fontSize: "150%",
    fontWeight: 900,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Home = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const cart = useSelector((state) => state.cart);

  React.useEffect(() => {
    if (!KIOSK_ENABLED && getTotalQuantity(cart) === 0) {
      dispatch(deliveryDialogOpen());
    }

    if (KIOSK_ENABLED) {
      const pos = settings.pointOfSales.find(
        (pos) => pos.id === KIOSK_POINT_OF_SALE
      );
      dispatch(setPointOfSale(pos, addressXNA, servicePrice(pos)));
    }
  }, []);

  if (KIOSK_ENABLED) {
    return (
      <>
        <Box id="content">
          <HomeSwiper />
          <ProductList />
          {getTotalQuantity(cart) > 0 && (
            <Box
              className={classes.cart}
              style={{
                position: "sticky",
                bottom: 0,
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Box className={classes.cartContainer}>
                <Cart cart={cart} editable={true} context={contextHome} />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }

  return (
    <React.Fragment>
      <DeliveryDialog />
      <Grid container id="content">
        <Grid item md={9} xs={12}>
          <HomeSwiper />
          <ProductList />
        </Grid>
        <Grid item md={3} className={classes.cart}>
          <Box display={{ xs: "none", sm: "none", md: "block" }}>
            <Sticky
              enabled={true}
              top={"#appBar"}
              bottomBoundary={"#content"}
              innerZ={1}
            >
              <Box className={classes.title}>Vaše objednávka</Box>
              <Divider />
              <Box className={classes.cartContainer}>
                <Cart cart={cart} editable={true} context={contextHome} />
              </Box>
            </Sticky>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;

import React from "react";
import { useSelector } from "react-redux";
import CommissionReport from "../../components/Admin/CommissionReport/CommissionReport";

const CommissionReportAdminView = () => {
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);

  return <CommissionReport localBusiness={localBusiness} />;
};

export default CommissionReportAdminView;

import { Element, scroller } from "react-scroll";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import Categories from "./Categories";
import ProductItem from "./ProductItem";
import React, { useEffect } from "react";
import Sticky from "react-stickynode";
import { addItemToCart } from "../../actions/cartActions";
import { fetchRangeOfGoods } from "../../actions/rangeOfGoodsActions";
import { colors } from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getCategories, getProductsOfCategory } from "../../utils/data";
import Sale2Plus1 from "../Sale2Plus1/Sale2Plus1";
import { contextAdmin, contextHome } from "../../constants/contexts";
import axios from "axios";
import { baseUrl } from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  dialogSection: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    textAlign: "right",
  },
  categorySectionLabel: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  product: {
    backgroundColor: colors.WHITE.main,
    margin: theme.spacing(1),
    borderRadius: 0,
  },
  productContent: {
    padding: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(1),
    "& > div": {
      padding: theme.spacing(1),
    },
  },
  chip: {
    display: "inline-block",
    backgroundColor: colors.ORANGE.main,
    color: colors.ORANGE.text,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    textTransform: "uppercase",
    letterSpacing: 0.5,
    fontWeight: 600,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontSize: "140%",
    fontWeight: 800,
  },
  subtitle: {
    fontSize: "100%",
    fontWeight: 300,
  },
  price: {
    fontSize: "120%",
    fontWeight: 300,
    "& strong": {
      //fontSize: "120%",
    },
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: "90%",
    fontWeight: 300,
  },
  allergens: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: "80%",
    fontWeight: 300,
  },
  adminItem: {
    boxSizing: "content-box",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: theme.spacing(0.5),
  },
  adminItemInner: {
    padding: theme.spacing(1),
    height: 120,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ProductList = (props) => {
  const context = props.context || contextHome;
  const disabled = props.disabled || false;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const pointOfSale = useSelector((state) =>
    state.settings.pointOfSales.find(
      (pos) => pos.id === state.cart.pointOfSaleId
    )
  );
  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);
  const adminLocalBusiness = useSelector(
    (state) => state.app.adminLocalBusiness
  );

  useEffect(() => {
    const apiCode = pointOfSale
      ? pointOfSale.apiCode
      : settings.pointOfSales[0].apiCode;
    dispatch(fetchRangeOfGoods(apiCode, context));
  }, [pointOfSale, settings]);

  const handleAddCartItemToCart = (cartItem) => {
    dispatch(addItemToCart(cartItem));
    enqueueSnackbar("Položka byla přidána do objednávky.", {
      variant: "success",
    });
  };

  const changeCategory = (category) => {
    handleCategoryClick(category);
  };

  const handleCategoryClick = (category) => {
    scroller.scrollTo(`category_${category.id}`, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -157, //category height + navbar height //TODO - change
    });
  };

  const sortFn = (a, b) => {
    if (!a.position || !b.position) {
      return 0;
    }
    if (a.position * 1 === b.position * 1) {
      return 0;
    }
    if (a.position * 1 < b.position * 1) {
      return -1;
    }
    if (a.position * 1 > b.position * 1) {
      return 1;
    }
    return 0;
  };

  const [invisibleProducts, setInvisibleProducts] = React.useState([]);

  React.useEffect(() => {
    setInvisibleProducts(
      rangeOfGoods.products
        .filter((p) => !!p.invisible)
        .map((p) => {
          return p.fullTitle;
        })
    );
  }, [rangeOfGoods]);

  const toggleVisibility = (product) => {
    if (!adminLocalBusiness) {
      return;
    }
    let copyInvisibleProducts;
    if (invisibleProducts.includes(product.fullTitle)) {
      copyInvisibleProducts = [...invisibleProducts].filter(
        (i) => i !== product.fullTitle
      );
    } else {
      copyInvisibleProducts = [...invisibleProducts].concat(product.fullTitle);
    }

    const data = {
      localBusinessId: adminLocalBusiness.title,
      data: copyInvisibleProducts,
    };
    axios
      .post(baseUrl + "/settings/invisible-products", data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setInvisibleProducts(copyInvisibleProducts);
          enqueueSnackbar("Zobrazení produktu bylo změněno.", {
            variant: "success",
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Sticky
        enabled={true}
        top={"#appBar"}
        bottomBoundary={"#content"}
        innerZ={1}
      >
        <Categories
          categories={getCategories(rangeOfGoods).sort(sortFn)}
          changeCategory={changeCategory}
        />
      </Sticky>

      {getCategories(rangeOfGoods)
        .sort(sortFn)
        .map((category) => {
          return (
            <Element
              key={`category_${category.id}`}
              name={`category_${category.id}`}
            >
              <Grid
                container
                style={{ padding: 8, backgroundColor: colors.BACKGROUND.main }}
              >
                <Grid item xs={12} className={classes.categorySectionLabel}>
                  <Typography variant={"h4"}>{category.title}</Typography>
                </Grid>
                {getProductsOfCategory(rangeOfGoods, category)
                  .sort(sortFn)
                  .map((product, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={context === contextAdmin ? 6 : 12}
                        sm={context === contextAdmin ? 4 : 12}
                        md={context === contextAdmin ? 3 : 12}
                        lg={context === contextAdmin ? 3 : 6}
                        xl={context === contextAdmin ? 3 : 6}
                      >
                        <ProductItem
                          product={product}
                          classes={classes}
                          addToCart={handleAddCartItemToCart}
                          context={context}
                          toggleVisibility={() => {
                            toggleVisibility(product);
                          }}
                          visible={
                            !invisibleProducts.includes(product.fullTitle)
                          }
                          disabled={disabled}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Element>
          );
        })}
      <Sale2Plus1 />
    </React.Fragment>
  );
};

export default React.memo(ProductList);

export const sortByPositionFn = (a, b) => {
  if (!a.position || !b.position) {
    return 0;
  }
  if (a.position === b.position) {
    return 0;
  }
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
};

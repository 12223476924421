import React from 'react';
import {Box} from "@material-ui/core";
import CommissionListAdminView from "./CommissionListAdminView";
import CommissionNewView from "./CommissionNewView";
import {useSelector} from "react-redux";

const CommissionListOrNewView = () => {
    const commissionListOrNewView = useSelector((state) => state.admin.adminView);

    return (
        <>
            <Box hidden={!(commissionListOrNewView === 'LIST')}>
                <CommissionListAdminView/>
            </Box>
            <Box hidden={!(commissionListOrNewView === 'CREATE' || commissionListOrNewView === 'CREATE_FROM_TEMPLATE')}>
                <CommissionNewView/>
            </Box>
        </>
    );
}

export default CommissionListOrNewView;
import { Box, Chip, makeStyles } from "@material-ui/core";
import React from "react";
import {
  cancelledStatusCode,
  finishedStatusCode,
  getCommissionStatusesForPointOfSale,
} from "../adminEnum";
import ThemedButton from "./ThemedButton";
import ThemedButtonMenu from "./ThemedButtonMenu";
import {
  addressXNA,
  commissionOriginCounter,
  commissionOriginInternet, commissionOriginKiosk,
  commissionOriginTelephone,
} from "../../../constants/constants";
import { formatDatetimeAdmin } from "../../../utils/dateTime";
import { csobPaymentStatuses, csobPaymentTitle } from "../../../utils/csob";
import {
  getPaymentMethodPaymentOnlineStatus,
  isPaymentMethodPaymentOnline,
} from "../../../utils/payment";
import axios from "axios";
import { apiGetCommission, apiPutCommission } from "../../../api/endpoints";
import { useSnackbar } from "notistack";
import WebIcon from "@material-ui/icons/Web";
import PhoneIcon from "@material-ui/icons/Phone";
import CommissionButtonsDotykacka from "./CommissionButtonsDotykacka";
import { getAllPointOfSalesOnlyDotykacka } from "../../../constants/pointOfSales";
import { commissionVariantHorizontal } from "./CommissionDetail";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import CommissionInfoCommissionCommentInternal from "./CommissionInfoCommissionCommentInternal";
import {getCommissionOrigin} from "./commissionOrigin";
import SmartphoneIcon from '@material-ui/icons/Smartphone';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#ffffff",
  },
  content: {
    padding: theme.spacing(0.5),
  },
  info: {
    marginLeft: theme.spacing(1),
  },
  deliveryImg: {
    width: 110,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  infoTakeOverDateTime: {
    fontSize: "180%",
  },
  infoAddress: {
    marginTop: theme.spacing(1),
    fontSize: "100%",
    "& a": {
      color: "#0000ff",
      textDecoration: "none",
    },
  },
  infoCustomer: {
    marginTop: theme.spacing(1),
    fontSize: "100%",
    "& a": {
      color: "#0000ff",
      textDecoration: "none",
    },
  },
  comments: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    "& > div": {
      marginBottom: theme.spacing(1.5),
      "& .text": {
        fontWeight: 500,
        fontSize: "100%",
      },
      "& > div": {
        fontWeight: 300,
        fontSize: "90%",
        "& .paid": {
          fontWeight: 700,
          color: "#008800",
        },
      },
    },
  },
  buttons: {
    display: "flex",
    padding: theme.spacing(1),
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
  button: {
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: theme.spacing(0),
    },
  },
}));

const CommissionInfo = (props) => {
  const { commission, handleChangeCommission, pointOfSale } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const getMapyCzLink = (deliveryAddress) => {
    let stringAddress = deliveryAddress.trim();
    if (
      !(
        deliveryAddress.toLowerCase().includes("vyškov") ||
        deliveryAddress.toLowerCase().includes("vyskov")
      )
    ) {
      stringAddress = stringAddress + " Vyškov";
    }
    return `https://mapy.cz/zakladni?q=${encodeURI(stringAddress)}`;
  };

  // const getGoogleMapDirectionsLink = (startAddress, deliveryAddress) => {
  //   let saddr = encodeURI(startAddress);
  //   let daddr = encodeURI(deliveryAddress);
  //   return `https://www.google.com/maps/dir/?api=1&origin=${saddr}&destination=${daddr}&travelmode=driving`;
  // };

  const handleRefresh = () => {
    axios.get(apiGetCommission(commission.uid)).then((response) => {
      if (response.status === 200) {
        enqueueSnackbar("Obnovuji objednávku z DB", {
          variant: "success",
        });
      }
    });
  };

  const handleClickBtn = (status) => {
    handleChangeCommission({ activeStatusCode: status.code });
  };

  const nextStatus = () => {
    const statuses = getCommissionStatusesForPointOfSale(pointOfSale);
    const currentStatusIndex = statuses.findIndex(
      (cs) => cs.code === commission.activeStatusCode
    );
    const nextStatusIndex = currentStatusIndex + 1;
    const status = statuses[nextStatusIndex];

    if (
      !status ||
      status.code === finishedStatusCode ||
      status.code === cancelledStatusCode
    ) {
      return false;
    }
    return status;
  };

  const ctod = formatDatetimeAdmin(commission.commissionTakeOverDateTime);
  const createdAt = formatDatetimeAdmin(commission.createdAtTimeStamp * 1000);

  const getPaid = () => {
    if (!isPaymentMethodPaymentOnline(commission)) {
      return <></>;
    }

    const paymentStatus = getPaymentMethodPaymentOnlineStatus(commission);

    return (
      <>
        <span
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
            color: paymentStatus && paymentStatus.paid ? "#008800" : "#ff0000",
          }}
        >
          {paymentStatus && paymentStatus.paid ? "Zaplaceno" : "Nezaplaceno"}
        </span>{" "}
        <span>({commission.csobPayId})</span>
      </>
    );
  };

  const capacityCheck = commission.hasOwnProperty('capacityCheck') ? commission.capacityCheck : true;

  return (
    <Box
      display={"flex"}
      flexDirection={commissionVariantHorizontal ? "column" : "row"}
      justifyContent={"space-between"}
      className={classes.root}
      pt={commissionVariantHorizontal ? 1 : 0}
      pb={commissionVariantHorizontal ? 1 : 0}
    >
      <Box className={classes.content}>
        <Box className={classes.info}>
          {commission.commissionOrigin && (
            <Box>
              {getCommissionOrigin(commission) === commissionOriginTelephone && (
                <Chip
                  size="small"
                  icon={<PhoneIcon />}
                  label={"Telefonní objednávka"}
                />
              )}
              {getCommissionOrigin(commission) === commissionOriginCounter && (
                <Chip
                  size="small"
                  icon={<ChildCareIcon />}
                  label={"Pultová objednávka"}
                />
              )}
              {getCommissionOrigin(commission) === commissionOriginInternet && (
                <Chip
                  size="small"
                  icon={<WebIcon />}
                  label={"Internetová objednávka"}
                />
              )}
              {getCommissionOrigin(commission) === commissionOriginKiosk && (
                  <Chip
                      size="small"
                      icon={<SmartphoneIcon />}
                      label={"Kiosková objednávka"}
                  />
              )}
            </Box>
          )}
          <Box className={classes.infoTakeOverDateTime}>
            <strong>{ctod.hhmm}</strong>,{" "}
            <span style={{ color: ctod.day === "zítra" ? "red" : "black" }}>
              {ctod.day}
            </span>
          </Box>
          {commission.customerAddress !== addressXNA && (
            <Box className={classes.infoAddress}>
              <a
                href={getMapyCzLink(commission.customerAddress)}
                target={"_blank"}
              >
                {commission.customerAddress}
              </a>
              {/*{commission.customerAddress}*/}
            </Box>
          )}
          <Box className={classes.infoCustomer}>
            <Box style={{ marginBottom: 4 }}>
              <strong>{commission.customerFullName}</strong>
            </Box>
            <Box>
              <a href={`tel:${commission.customerTelephone}`}>
                {commission.customerTelephone}
              </a>
              {commission.customerTelephone !== "" &&
                commission.customerEmail !== "" && <>{" / "}</>}
              <a href={`mailto:${commission.customerEmail}`}>
                {commission.customerEmail}
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.comments}>
        <Box>
          <div>Vytvořeno</div>{" "}
          <div className={"text"}>
            {createdAt.day}, {createdAt.hhmm}{" "}
            {commission.createdByJobPosition && (
              <>({commission.createdByJobPosition})</>
            )}
          </div>
        </Box>
        <Box>
          <div>Způsob platby {getPaid()}</div>{" "}
          <div className={"text"}>{commission.commissionPaymentMethod}</div>
        </Box>
        {commission.commissionComment && (
          <Box>
            <div>Poznámka</div>{" "}
            <div className={"text"} style={{ color: "red" }}>
              {commission.commissionComment}
            </div>
          </Box>
        )}
        {commission.commissionTextOnTheBox && (
          <Box>
            <div>Text na krabici</div>{" "}
            <div className={"text"} style={{ color: "red" }}>
              {commission.commissionTextOnTheBox}
            </div>
          </Box>
        )}
        <CommissionInfoCommissionCommentInternal
          commission={commission}
          handleChangeCommission={handleChangeCommission}
        />
      </Box>
      <Box>
        <Box className={classes.buttons}>
          <Box flexGrow={1}>
            {nextStatus() && (
              <ThemedButton
                nextStatus={nextStatus()}
                onClick={handleClickBtn}
                pointOfSale={pointOfSale}
              />
            )}
          </Box>
          <Box style={{ textAlign: "right" }}>
            <ThemedButtonMenu
              onClick={handleClickBtn}
              pointOfSale={pointOfSale}
              onRefresh={handleRefresh}
              commission={commission}
              handleChangeCommission={handleChangeCommission}
            />
          </Box>
        </Box>
          {!capacityCheck && <Box ml={1} mr={1} mt={1} mb={2} style={{textAlign: 'right'}}><Chip color={'primary'} size={'small'} label={'Nepoužívá kapacity'} /></Box>}
        {getAllPointOfSalesOnlyDotykacka().includes(
          commission.pointOfSaleId
        ) && (
          <Box ml={1} mr={1} mt={2} mb={2} style={{ textAlign: "right" }}>
            <CommissionButtonsDotykacka
              commission={commission}
              handleChangeCommission={handleChangeCommission}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CommissionInfo;

import { Box, Chip, Grid, makeStyles } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import React from "react";
import {
  filterCommissionsForCapacity,
  getCapacity,
  getCapacityDefinition,
} from "../../../functions/functions";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { getDateForDayOfWeek } from "./functions";
import { getAllPointOfSalesOnlyDelivery } from "../../../constants/pointOfSales";
import { useSelector } from "react-redux";
import { getClosedDayForPointOfSaleAndDateTime } from "../../../utils/delivery";
import CapacityGridItemCommissionChip from "./CapacityGridItemCommissionChip";

const useStyles = makeStyles((theme) => ({
  capacityChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    borderRadius: 16,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(0.5, 1),
    height: "max-content",
    color: "rgba(0, 0, 0, 0.65)",
    marginRight: theme.spacing(0.5),
    "&:last-child": {
      marginRight: 0,
    },
  },
  label: {
    fontSize: 11,
    textTransform: "uppercase",
  },
}));

const CapacityGridItemCapacityDefinitionSemaphore = (props) => {
  const { capacity, capacityDefinition, disabled } = props;

  const semaphoreColors = {
    green: ["hsl(120, 100%, 65%)", "hsl(120, 100%, 35%)"],
    orange: ["hsl(39, 100%, 65%)", "hsl(39, 100%, 35%)"],
    red: ["hsl(0, 100%, 65%)", "hsl(0, 100%, 35%)"],
  };

  const getColor = () => {
    if (disabled) {
      return "red";
    }
    const diff = capacityDefinition.value - capacity.value;

    if (diff < 50) {
      return "red";
    }
    if (capacity.value > diff / 2) {
      return "orange";
    }
    return "green";
  };

  return (
    <Box
      mt={1}
      mb={1}
      style={{
        display: "inline-block",
        background: semaphoreColors[getColor()][1],
        padding: 4,
        borderRadius: 16,
      }}
    >
      {["red", "orange", "green"].map((color) => {
        return (
          <Box
            key={color}
            style={{
              backgroundColor:
                getColor() === color
                  ? semaphoreColors[getColor()][0]
                  : "rgba(0, 0, 0, 0.12)",
              width: 10,
              height: 10,
              marginBottom: 2,
              borderRadius: "50%",
            }}
          />
        );
      })}
    </Box>
  );
};

export const CapacityChip = (props) => {
  const {
    icon,
    icon2,
    iconsDisplay = "block",
    definitionValue,
    remainingValue,
    currentValue,
    background = "rgba(0, 0, 0, 0.12)",
  } = props;

  const classes = useStyles();

  return (
    <Box
      className={classes.capacityChip}
      style={{ backgroundColor: background }}
    >
      <Box mr={0.5} style={{ textAlign: "center", display: iconsDisplay }}>
        <Box>{icon}</Box>
        {icon2 && <Box>{icon2}</Box>}
      </Box>
      <Box>
        {definitionValue && (
          <Box
            style={{
              textAlign: "right",
              whiteSpace: "nowrap",
            }}
          >
            {definitionValue}
          </Box>
        )}
        {currentValue && (
          <Box
            style={{
              textAlign: "right",
              whiteSpace: "nowrap",
            }}
          >
            {currentValue}
          </Box>
        )}
        {(remainingValue || remainingValue === 0) && (
          <Box
            style={{
              textAlign: "right",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
          >
            {remainingValue}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const semaphoreColors = {
  green: ["hsl(120, 100%, 85%)", "hsl(120, 100%, 35%)"],
  orange: ["hsl(39, 100%, 85%)", "hsl(39, 100%, 35%)"],
  red: ["hsl(0, 100%, 85%)", "hsl(0, 100%, 35%)"],
};

const CapacityGridItemCapacityDefinition = (props) => {
  const { source, hours, minutes } = props;

  const localBusinessTitle = "Vyškov";

  const capacityState = useSelector((state) => state.capacity);
  const internalCommissions = useSelector(
    (state) => state.admin.internalCommissions
  );
  const dayOfWeek = capacityState.dayOfWeek;

  const date = getDateForDayOfWeek(dayOfWeek.index);
  const capacity = getCapacity(
    localBusinessTitle,
    source,
    date,
    hours,
    minutes,
    {
      capacities: capacityState.capacities,
      commissions: filterCommissionsForCapacity(
        capacityState.commissions,
        localBusinessTitle,
        date
      ),
      internalCommissions,
    }
  );
  const capacityDefinition = getCapacityDefinition(
    localBusinessTitle,
    source,
    dayOfWeek.index,
    hours,
    minutes,
    {
      capacities: capacityState.capacities,
    }
  );

  const getColor = (capacityDefinition, capacity, disabled = false) => {
    if (disabled) {
      return "red";
    }
    const diff = capacityDefinition.value - capacity.value;

    if (diff < 50 && source !== "ROZVOZ") {
      return "red";
    }
    if (diff <= 0 && source === "ROZVOZ") {
      return "red";
    }
    if (capacity.value > diff / 2) {
      return "orange";
    }
    return "green";
  };

  const isDisabled = () => {
    const now = new Date();
    const dt = new Date(date);
    dt.setHours(hours, minutes, 0, 0);

    return now > dt;
  };

  return (
    <Box
      style={{
        height: "100%",
        paddingLeft: 32,
        paddingTop: 8,
        paddingRight: 32,
        paddingBottom: 8,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor:
          semaphoreColors[
            getColor(capacityDefinition, capacity, isDisabled())
          ][0],
      }}
    >
      <Box
      // style={{
      //   maxWidth: 200,
      // }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} />
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {capacityDefinition.value}
          </Grid>
        </Grid>
        {capacity.commissions.map((commission) => {
          if (commission.pointsDifficultyAddition === 0) {
            return <></>;
          }
          return (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CapacityGridItemCommissionChip commission={commission} />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <small>-&nbsp;{commission.pointsDifficultyAddition}</small>
              </Grid>
            </Grid>
          );
        })}
        <Grid container spacing={1}>
          <Grid item xs={6} />
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <strong>
              {isDisabled() && capacity.value === 0
                ? 0
                : capacityDefinition.value - capacity.value}
            </strong>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CapacityGridItemCapacityDefinition;

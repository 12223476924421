import { createMuiTheme } from "@material-ui/core/styles";

export const colors = {
  RED: {
    //major-important CTA (cart, checkout, ...)
    main: "#ff0000",
    text: "#ffffff",
  },
  GREEN: {
    //minor-important CTA (choose, signup, ...)
    main: "#197A30",
    text: "#ffffff",
    selected: "#E3EFE6",
    selectedText: "#084A13",
  },
  ORANGE: {
    //warning, tips, ...
    main: "#FFBA00",
    text: "#000000",
  },
  WHITE: {
    main: "#ffffff",
    text: "#000000",
  },
  BLACK: {
    main: "#000000",
    text: "#ffffff",
    light: "#4b4643",
  },
  BACKGROUND: {
    main: "#eeeeee",
    dark: "#dddddd",
  },
};

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.RED.main, contrastText: colors.RED.text },
    secondary: { main: colors.GREEN.main, contrastText: colors.GREEN.text },
    text: { secondary: colors.BLACK.main },
    background: {
      default: colors.BACKGROUND.default,
      white: colors.WHITE.main,
      black: colors.BLACK.main,
    },
  },
  shape: {
    borderRadius: "10px",
  },
});

export default theme;
